import styles from './BankLinkingCodeInput.module.css';
import cx from 'classnames';
import { BoxedCharacterInput } from 'mica-ui-kit/src/components/inputs/text/BoxedCharacterInput/BoxedCharacterInput';
import { LoadingIndicator } from 'mica-ui-kit/src/components/loading/LoadingIndicator/LoadingIndicator';

type BankLinkingCodeInputProps = {
  className?: string;
  isLoading: boolean;
  onSubmit: (code: string) => void;
};

/** Enter the Bank Linking Code for linking a bank/signing up */
const BankLinkingCodeInput = ({
  className,
  isLoading,
  onSubmit,
}: BankLinkingCodeInputProps) => {
  return (
    <div className={cx(styles.bankLinkingCodeInput, className)}>
      <div className={styles.title}>
        Please enter the one-time verification code provided by your Bank
      </div>
      <div className={styles.subtext}>
        This code can typically be found in participating Banks' mobile or web
        apps
      </div>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <BoxedCharacterInput
          isDisabled={isLoading}
          textLength={8}
          onTextComplete={onSubmit}
        />
      )}
    </div>
  );
};

export default BankLinkingCodeInput;
