import React, { useState } from 'react';

import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { useNavigate } from 'react-router-dom';

import styles from './HeaderSearch.module.scss';

/**
 A Component
 */
const HeaderSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const handleKeyPress = ({ key }: { key: string }) => {
    key === 'Enter' && navigate('/search/' + searchQuery);
  };

  return (
    <div className={styles.container}>
      <FaSearch />
      <input
        className={styles.input}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyPress={handleKeyPress}
      />
    </div>
  );
};

export default HeaderSearch;
