// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: mica/discount/productgroup/v1/product_group.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { Error } from "../../../../micashared/common/v1/error";

export const protobufPackage = "mica.discount.productgroup.v1";

export interface ProductGroup {
  productGroupKey: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  name: string;
  productGroupRef: string;
  productMatchFilters: string[];
  productCodes: string[];
}

export interface CreateProductGroupRequest {
  productGroupRef: string;
  name: string;
  productMatchFilters: string[];
  productCodes: string[];
}

export interface CreateProductGroupResponse {
  status: CreateProductGroupResponse_Status;
  error: Error | undefined;
  productGroupKey: string;
  version: number;
}

export enum CreateProductGroupResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_DUPLICATE = 3,
}

export interface GetProductGroupRequest {
  productGroupRef?: string | undefined;
  productGroupKey?: string | undefined;
}

export interface GetProductGroupResponse {
  status: GetProductGroupResponse_Status;
  error: Error | undefined;
  productGroup: ProductGroup | undefined;
}

export enum GetProductGroupResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateProductGroupRequest {
  productGroupRef?: string | undefined;
  productGroupKey?: string | undefined;
  version: number;
  name: string;
  productMatchFilters: string[];
  productCodes: string[];
}

export interface UpdateProductGroupResponse {
  status: UpdateProductGroupResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateProductGroupResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface RemoveProductGroupRequest {
  productGroupRef?: string | undefined;
  productGroupKey?: string | undefined;
}

export interface RemoveProductGroupResponse {
  status: RemoveProductGroupResponse_Status;
  error: Error | undefined;
}

export enum RemoveProductGroupResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface SearchProductGroupRequest {
  name: string;
  productMatchFilter: string;
  productCode: string;
}

export interface SearchProductGroupResponse {
  status: SearchProductGroupResponse_Status;
  error: Error | undefined;
  productGroups: ProductGroup[];
}

export enum SearchProductGroupResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

function createBaseProductGroup(): ProductGroup {
  return {
    productGroupKey: "",
    version: 0,
    created: undefined,
    updated: undefined,
    name: "",
    productGroupRef: "",
    productMatchFilters: [],
    productCodes: [],
  };
}

export const ProductGroup = {
  encode(message: ProductGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productGroupKey !== "") {
      writer.uint32(10).string(message.productGroupKey);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(34).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.productGroupRef !== "") {
      writer.uint32(50).string(message.productGroupRef);
    }
    for (const v of message.productMatchFilters) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.productCodes) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProductGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProductGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.productGroupKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.productGroupRef = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.productMatchFilters.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.productCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProductGroup>, I>>(base?: I): ProductGroup {
    return ProductGroup.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProductGroup>, I>>(object: I): ProductGroup {
    const message = createBaseProductGroup();
    message.productGroupKey = object.productGroupKey ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.name = object.name ?? "";
    message.productGroupRef = object.productGroupRef ?? "";
    message.productMatchFilters = object.productMatchFilters?.map((e) => e) || [];
    message.productCodes = object.productCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateProductGroupRequest(): CreateProductGroupRequest {
  return { productGroupRef: "", name: "", productMatchFilters: [], productCodes: [] };
}

export const CreateProductGroupRequest = {
  encode(message: CreateProductGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productGroupRef !== "") {
      writer.uint32(10).string(message.productGroupRef);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    for (const v of message.productMatchFilters) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.productCodes) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateProductGroupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateProductGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.productGroupRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productMatchFilters.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.productCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateProductGroupRequest>, I>>(base?: I): CreateProductGroupRequest {
    return CreateProductGroupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateProductGroupRequest>, I>>(object: I): CreateProductGroupRequest {
    const message = createBaseCreateProductGroupRequest();
    message.productGroupRef = object.productGroupRef ?? "";
    message.name = object.name ?? "";
    message.productMatchFilters = object.productMatchFilters?.map((e) => e) || [];
    message.productCodes = object.productCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateProductGroupResponse(): CreateProductGroupResponse {
  return { status: 0, error: undefined, productGroupKey: "", version: 0 };
}

export const CreateProductGroupResponse = {
  encode(message: CreateProductGroupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.productGroupKey !== "") {
      writer.uint32(26).string(message.productGroupKey);
    }
    if (message.version !== 0) {
      writer.uint32(32).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateProductGroupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateProductGroupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productGroupKey = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateProductGroupResponse>, I>>(base?: I): CreateProductGroupResponse {
    return CreateProductGroupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateProductGroupResponse>, I>>(object: I): CreateProductGroupResponse {
    const message = createBaseCreateProductGroupResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.productGroupKey = object.productGroupKey ?? "";
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseGetProductGroupRequest(): GetProductGroupRequest {
  return { productGroupRef: undefined, productGroupKey: undefined };
}

export const GetProductGroupRequest = {
  encode(message: GetProductGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productGroupRef !== undefined) {
      writer.uint32(10).string(message.productGroupRef);
    }
    if (message.productGroupKey !== undefined) {
      writer.uint32(18).string(message.productGroupKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProductGroupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProductGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.productGroupRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.productGroupKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetProductGroupRequest>, I>>(base?: I): GetProductGroupRequest {
    return GetProductGroupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProductGroupRequest>, I>>(object: I): GetProductGroupRequest {
    const message = createBaseGetProductGroupRequest();
    message.productGroupRef = object.productGroupRef ?? undefined;
    message.productGroupKey = object.productGroupKey ?? undefined;
    return message;
  },
};

function createBaseGetProductGroupResponse(): GetProductGroupResponse {
  return { status: 0, error: undefined, productGroup: undefined };
}

export const GetProductGroupResponse = {
  encode(message: GetProductGroupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.productGroup !== undefined) {
      ProductGroup.encode(message.productGroup, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProductGroupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProductGroupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productGroup = ProductGroup.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetProductGroupResponse>, I>>(base?: I): GetProductGroupResponse {
    return GetProductGroupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProductGroupResponse>, I>>(object: I): GetProductGroupResponse {
    const message = createBaseGetProductGroupResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.productGroup = (object.productGroup !== undefined && object.productGroup !== null)
      ? ProductGroup.fromPartial(object.productGroup)
      : undefined;
    return message;
  },
};

function createBaseUpdateProductGroupRequest(): UpdateProductGroupRequest {
  return {
    productGroupRef: undefined,
    productGroupKey: undefined,
    version: 0,
    name: "",
    productMatchFilters: [],
    productCodes: [],
  };
}

export const UpdateProductGroupRequest = {
  encode(message: UpdateProductGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productGroupRef !== undefined) {
      writer.uint32(10).string(message.productGroupRef);
    }
    if (message.productGroupKey !== undefined) {
      writer.uint32(18).string(message.productGroupKey);
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    for (const v of message.productMatchFilters) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.productCodes) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProductGroupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProductGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.productGroupRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.productGroupKey = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.productMatchFilters.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.productCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateProductGroupRequest>, I>>(base?: I): UpdateProductGroupRequest {
    return UpdateProductGroupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateProductGroupRequest>, I>>(object: I): UpdateProductGroupRequest {
    const message = createBaseUpdateProductGroupRequest();
    message.productGroupRef = object.productGroupRef ?? undefined;
    message.productGroupKey = object.productGroupKey ?? undefined;
    message.version = object.version ?? 0;
    message.name = object.name ?? "";
    message.productMatchFilters = object.productMatchFilters?.map((e) => e) || [];
    message.productCodes = object.productCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateProductGroupResponse(): UpdateProductGroupResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateProductGroupResponse = {
  encode(message: UpdateProductGroupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateProductGroupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateProductGroupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateProductGroupResponse>, I>>(base?: I): UpdateProductGroupResponse {
    return UpdateProductGroupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateProductGroupResponse>, I>>(object: I): UpdateProductGroupResponse {
    const message = createBaseUpdateProductGroupResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseRemoveProductGroupRequest(): RemoveProductGroupRequest {
  return { productGroupRef: undefined, productGroupKey: undefined };
}

export const RemoveProductGroupRequest = {
  encode(message: RemoveProductGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.productGroupRef !== undefined) {
      writer.uint32(10).string(message.productGroupRef);
    }
    if (message.productGroupKey !== undefined) {
      writer.uint32(18).string(message.productGroupKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveProductGroupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveProductGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.productGroupRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.productGroupKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveProductGroupRequest>, I>>(base?: I): RemoveProductGroupRequest {
    return RemoveProductGroupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveProductGroupRequest>, I>>(object: I): RemoveProductGroupRequest {
    const message = createBaseRemoveProductGroupRequest();
    message.productGroupRef = object.productGroupRef ?? undefined;
    message.productGroupKey = object.productGroupKey ?? undefined;
    return message;
  },
};

function createBaseRemoveProductGroupResponse(): RemoveProductGroupResponse {
  return { status: 0, error: undefined };
}

export const RemoveProductGroupResponse = {
  encode(message: RemoveProductGroupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveProductGroupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveProductGroupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveProductGroupResponse>, I>>(base?: I): RemoveProductGroupResponse {
    return RemoveProductGroupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveProductGroupResponse>, I>>(object: I): RemoveProductGroupResponse {
    const message = createBaseRemoveProductGroupResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseSearchProductGroupRequest(): SearchProductGroupRequest {
  return { name: "", productMatchFilter: "", productCode: "" };
}

export const SearchProductGroupRequest = {
  encode(message: SearchProductGroupRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.productMatchFilter !== "") {
      writer.uint32(18).string(message.productMatchFilter);
    }
    if (message.productCode !== "") {
      writer.uint32(26).string(message.productCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchProductGroupRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchProductGroupRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.productMatchFilter = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchProductGroupRequest>, I>>(base?: I): SearchProductGroupRequest {
    return SearchProductGroupRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchProductGroupRequest>, I>>(object: I): SearchProductGroupRequest {
    const message = createBaseSearchProductGroupRequest();
    message.name = object.name ?? "";
    message.productMatchFilter = object.productMatchFilter ?? "";
    message.productCode = object.productCode ?? "";
    return message;
  },
};

function createBaseSearchProductGroupResponse(): SearchProductGroupResponse {
  return { status: 0, error: undefined, productGroups: [] };
}

export const SearchProductGroupResponse = {
  encode(message: SearchProductGroupResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.productGroups) {
      ProductGroup.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchProductGroupResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchProductGroupResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productGroups.push(ProductGroup.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchProductGroupResponse>, I>>(base?: I): SearchProductGroupResponse {
    return SearchProductGroupResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchProductGroupResponse>, I>>(object: I): SearchProductGroupResponse {
    const message = createBaseSearchProductGroupResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.productGroups = object.productGroups?.map((e) => ProductGroup.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
