import { map, pipe, prop, sum } from 'ramda';
import { atom, selector } from 'recoil';
import { Product } from '../hooks/product/useProducts';

export type ShoppingCartItem = {
  product: Product;
  qty: number;
};

export const shoppingCartLocalStorageKey = 'shoppingCartState';

export const shoppingCartState = atom<ShoppingCartItem[]>({
  key: 'shoppingCartState',
  default: [],
});

export const sumQuantities = pipe<[{ qty: number }[]], number[], number>(
  map<{ qty: number }, number>(prop('qty')),
  sum
);

/**
 * Get the total count for all products in the shopping cart
 */
export const shoppingCartQuantityState = selector<number>({
  key: 'shoppingCartQuantityState',
  get: ({ get }) => sumQuantities(get(shoppingCartState)),
});
