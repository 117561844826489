import { useMemo } from 'react';

import { useCategories } from './useCategories';

export const useTopLevelCategories = () => {
  const { categories } = useCategories();

  const topLevelCategories = useMemo(() => {
    return categories.filter((category) => !category.parentCategory);
  }, [categories]);

  return topLevelCategories;
};
