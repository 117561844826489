import React, { PropsWithChildren, useState } from 'react';
import cx from 'classnames';
import logo from 'corner-store-shared/src/assets/CornerStoreLogo.png';
import styles from './SignInOrUp.module.css';
import { ForgotPassword } from './Signin/ForgotPassword';
import { ForgotUsername } from './Signin/ForgotUsername';
import { Signin } from './Signin/Signin';
import Signup from './Signup/Signup';
import SignupOptions from './SignupOptions/SignupOptions';
import SignupBankCode from './SignupBankCode/SignupBankCode';

type SignInOrUpProps = {
  subtitle?: string;
};

enum ViewMode {
  Signin,
  Signup,
  SignupBank,
  SignupClassic,
  ForgotPassword,
  ForgotUsername,
}

const ViewModeComponent = {
  [ViewMode.Signin]: Signin,
  [ViewMode.Signup]: SignupOptions,
  [ViewMode.SignupBank]: SignupBankCode,
  [ViewMode.SignupClassic]: Signup,
  [ViewMode.ForgotPassword]: ForgotPassword,
  [ViewMode.ForgotUsername]: ForgotUsername,
};

const contentHeight = {
  [ViewMode.Signin]: 370,
  [ViewMode.Signup]: 286,
  [ViewMode.SignupBank]: 'auto',
  [ViewMode.SignupClassic]: 802,
  [ViewMode.ForgotPassword]: 200,
  [ViewMode.ForgotUsername]: 200,
};

export const SignInOrUp = ({
  children,
}: PropsWithChildren<SignInOrUpProps>) => {
  const [viewmode, setViewmode] = useState(ViewMode.Signin);

  const Component = ViewModeComponent[viewmode];

  return (
    <>
      <div
        className={cx(styles.container, {
          [styles.centeredVertically]: viewmode === ViewMode.Signin,
        })}>
        <div
          className={styles.inputContainer}
          style={{ height: contentHeight[viewmode] }}>
          <Component
            onSelectBankSignup={() => setViewmode(ViewMode.SignupBank)}
            onSelectClassicSignup={() => setViewmode(ViewMode.SignupClassic)}
            onToggleSignin={() => setViewmode(ViewMode.Signin)}
            onToggleSignup={() => setViewmode(ViewMode.Signup)}
            onToggleForgotUsername={() => setViewmode(ViewMode.ForgotUsername)}
            onToggleForgotPassword={() => setViewmode(ViewMode.ForgotPassword)}
          />
        </div>
        {children}
      </div>
    </>
  );
};
