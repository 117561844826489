import { T, cond, identity, pipe, includes } from 'ramda';
import { humanizeErrorMapping } from './humanizeErrorMapping';

export const errorAsString = (error: any) =>
  typeof error === 'string'
    ? error
    : error.message
    ? error.message
    : error.toString
    ? error.toString()
    : JSON.stringify(error);

const humanizeError = (error: string) =>
  cond([
    ...Object.entries(humanizeErrorMapping).map(
      ([partial, handler]) =>
        [includes(partial), handler] as [
          (err: string) => boolean,
          (err: string) => string
        ]
    ),
    [T, identity],
  ])(error);

export const parsedError = pipe(errorAsString, humanizeError);
