import React from 'react';
import { sentryCreateBrowserRouter } from './utils/init';
import { RouterProvider } from 'react-router-dom';
import 'the-new-css-reset/css/reset.css';
import { ErrorPage } from './ErrorPage';
import LaunchScreen from './components/LaunchScreen/LaunchScreen';
import { Container } from './pages/Container';
import { Authentication } from './pages/User/Authentication';

const Home = React.lazy(() => import('./pages/Home/Home'));
const Promotions = React.lazy(() => import('./pages/Promotions/Promotions'));
const Promotion = React.lazy(() => import('./pages/Promotion/Promotion'));
const Discount = React.lazy(() => import('./pages/Discount/Discount'));
const Category = React.lazy(() => import('./pages/Categories/Category'));
const Cart = React.lazy(() => import('./pages/Cart/Cart'));
const Product = React.lazy(() => import('./pages/Product/Product'));
const Search = React.lazy(() => import('./pages/Search/Search'));
const Profile = React.lazy(() => import('./pages/Profile/Profile'));
const Orders = React.lazy(() => import('./pages/Orders/Orders'));
const Checkout = React.lazy(() => import('./pages/Checkout/Checkout'));
const ProductList = React.lazy(() => import('./pages/ProductList/ProductList'));
const PageNotFound = React.lazy(() => import('./pages/Errors/PageNotFound'));

window.global ||= window;

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <Container />,
    errorElement: <PageNotFound />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <Home /> },
          { path: '/promotions', element: <Promotions /> },
          { path: '/promotions/:promotionId', element: <Promotion /> },
          { path: '/discounts/:discountId', element: <Discount /> },
          { path: '/categories/:category', element: <Category /> },
          { path: '/cart', element: <Cart /> },
          { path: '/products/:productId', element: <Product /> },
          { path: '/search/:search', element: <Search /> },
          { path: '/profile', element: <Profile /> },
          { path: '/orders', element: <Orders /> },
          { path: '/checkout', element: <Checkout /> },
          { path: '/productList', element: <ProductList /> },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <Authentication />
      <LaunchScreen />
    </>
  );
}

export default App;
