import React, { useRef } from 'react';

import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { useBoolean } from 'mica-shared-react/src/hooks/useBoolean';
import { useNavigate } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { useAuth } from 'corner-store-shared/src/hooks/useAuth';
import styles from './User.module.scss';
import { useDeliveryDetails } from 'corner-store-shared/src/hooks/delivery/useDeliveryDetails/useDeliveryDetails';

/**
 A Component
 */
const User = () => {
  const showUserMenu = useBoolean();
  const { showAuth, user, logout } = useAuth();
  const { resetDeliveryDetails } = useDeliveryDetails();
  const navigate = useNavigate();

  const menu = useRef(null);

  const handleOrdersSelected = () => {
    showUserMenu.setFalse();
    navigate('/orders');
  };

  const handleProfileSelected = () => {
    showUserMenu.setFalse();
    navigate('/profile');
  };

  const handleLogout = () => {
    showUserMenu.setFalse();
    resetDeliveryDetails();
    logout();
  };

  useOnClickOutside(menu, showUserMenu.setFalse);

  return user ? (
    <span className={styles.userMenuContainer} ref={menu}>
      <button className={styles.avatarButton} onClick={showUserMenu.toggle}>
        <FaUser className="ml-1" />
      </button>
      {showUserMenu.value && (
        <div className={styles.userMenu}>
          <div className={styles.userMenuItem} onClick={handleProfileSelected}>
            Profile
          </div>
          <div className={styles.userMenuItem} onClick={handleOrdersSelected}>
            Orders
          </div>
          <div className={styles.userMenuItem} onClick={handleLogout}>
            Logout
          </div>
        </div>
      )}
    </span>
  ) : (
    <button className={styles.avatarButton} onClick={showAuth}>
      Sign In <FaUser className="ml-1" />
    </button>
  );
};

export default User;
