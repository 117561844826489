import * as React from 'react';
import { MouseEventHandler, ReactNode } from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';
import { LoadingIndicator } from 'mica-ui-kit/src/components/loading/LoadingIndicator/LoadingIndicator';

interface Props {
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler;
  loading?: boolean;
  name?: string;
  children: ReactNode;
  style?: React.CSSProperties;
}

export const Button = ({
  className,
  children,
  disabled,
  loading,
  name,
  onClick,
  style,
}: Props) => {
  return (
    <button
      className={cx(styles.btn, className)}
      disabled={disabled}
      name={name}
      style={style}
      onClick={onClick}>
      {children} {loading && <LoadingIndicator />}
    </button>
  );
};
