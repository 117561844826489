import React from 'react';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';
import styles from './Signin.module.css';
import SigninForm from './SigninForm';

type SigninProps = {
  onToggleSignup: () => void;
  onToggleForgotPassword: () => void;
  onToggleForgotUsername: () => void;
};

export const Signin = ({
  onToggleSignup,
  onToggleForgotPassword,
  onToggleForgotUsername,
}: SigninProps) => {
  return (
    <>
      <div style={{ marginBottom: 10 }}>
        Please sign in to access your account
      </div>
      <SigninForm />
      <Button
        type={'button'}
        className={styles.flatButton}
        onClick={onToggleForgotUsername}
        label={'Forgot Username'}
      />
      <Button
        type={'button'}
        className={styles.flatButton}
        onClick={onToggleForgotPassword}
        label={'Forgot Password'}
      />
      <div className="mt-20 mb-10">Not a user?</div>
      <Button className="mb-20" onClick={onToggleSignup} type={'button'}>
        Sign Up
      </Button>
    </>
  );
};

export default Signin;
