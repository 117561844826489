import { toast } from 'react-toastify';
import { parsedError } from 'mica-utilities/src/formatters/error/error';
import { logError } from 'mica-ui-kit/src/utils/logging/logging';

const defaultHandleError: (err: unknown) => boolean = () => false;

export const makeQueryConfig = (handleError = defaultHandleError) => {
  const onError = (err: unknown) => {
    if (!handleError(err)) {
      const parsed = parsedError(err);

      toast.error(parsed, {
        autoClose: Math.max(3000, parsed.length * 50), // keep longer messages around for longer so they can be read
      });
      logError(err);
    }
  };

  return {
    defaultOptions: {
      queries: {
        onError,
        retry: false,
        staleTime: 30000,
      },
      mutations: {
        onError,
      },
    },
  };
};

export const basicQueryConfig = makeQueryConfig();
