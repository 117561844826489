import { signupErrorMapping } from './mappings/signup';
import { signinErrorMapping } from './mappings/signin';

/**
 * A mapping of partial error strings to functions that return a humanized error string.  If a partial error string is not found, the error is returned as-is.
 */
export const humanizeErrorMapping: Record<string, (error: string) => string> = {
  ...signupErrorMapping,
  ...signinErrorMapping,
};
