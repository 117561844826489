import styles from './BoxedCharacterInput.module.css';
import cx from 'classnames';
import { range } from 'ramda';
import React, { createRef, useState } from 'react';

type BoxedCharacterInputProps = {
  id?: string;
  isDisabled?: boolean;
  onTextComplete: (pin: string) => void;
  textLength?: number;
};

/**
 text entry, with a hidden input and each character appearing in a box
 */
export const BoxedCharacterInput = ({
  id = 'CodeInput',
  isDisabled,
  onTextComplete,
  textLength = 5,
}: BoxedCharacterInputProps) => {
  const [pin, setPin] = useState<string>('');
  const [inputIsSelected, setInputIsSelected] = useState(false);

  const hiddenPinTextField = createRef<HTMLInputElement>();

  const handleSetPin = ({ target }: any) => {
    target.value.length <= textLength && setPin(target.value);
    if (target.value.length === textLength) {
      setTimeout(() => {
        onTextComplete(target.value);
      }, 100);
    }
  };

  return (
    <div
      className={styles.container}
      onClick={() => hiddenPinTextField.current!.focus()}>
      <input
        id={id}
        disabled={isDisabled}
        ref={hiddenPinTextField}
        className={styles.hidden}
        autoFocus
        onBlur={() => setInputIsSelected(false)}
        onFocus={() => setInputIsSelected(true)}
        value={pin}
        onChange={handleSetPin}
      />
      {range(0, textLength).map((i) => (
        <div
          key={'pin' + i}
          className={cx(styles.character, {
            [styles.isFocused]: inputIsSelected,
            [styles.isDisabled]: isDisabled,
          })}>
          {pin.charAt(i) || ''}
        </div>
      ))}
    </div>
  );
};

export default BoxedCharacterInput;
