import BankLinkingCodeInput from '../../../domains/linkedAccounts/BankLinkingCodeInput/BankLinkingCodeInput';
import { useFetchUserDataFromBankLinkingCode } from 'corner-store-shared/src/hooks/user/useFetchUserDataFromBankLinkingCode';
import { toast } from 'react-toastify';
import { useState } from 'react';
import BankSignupUserForm from '../BankSignupUserForm/BankSignupUserForm';
import { UserDemographic } from 'micareference-proto/src/demo/common/v1/user';
import BankUsernamePassword from '../BankUsernamePassword/BankUsernamePassword';
import { FetchUserDataFromBankLinkingCodeResponse } from 'micareference-proto/src/demo/micashop/service/v1/micashop_service';

/** The Bank Signup UI */
const SignupBankCode = () => {
  const [fetchUserDataResponse, setFetchUserDataResponse] = useState<
    FetchUserDataFromBankLinkingCodeResponse | undefined
  >(undefined);

  const [showUsernamePassword, setShowUsernamePassword] = useState(false);

  const { mutate: fetchDemographicsFromLinkingCode, isLoading } =
    useFetchUserDataFromBankLinkingCode();

  const handleLinkingCodeError = () => {
    toast.error(
      <>
        Signup Failed
        <br />
        Please try a different code
      </>
    );
  };

  const handleSubmitCode = (code: string) => {
    fetchDemographicsFromLinkingCode(
      { linkingCode: code },
      {
        onSuccess: (data) => {
          data.status === 1
            ? setFetchUserDataResponse(data)
            : handleLinkingCodeError();
        },
        onError: handleLinkingCodeError,
      }
    );
  };

  const handleShowUserNamePassword = (demographics: UserDemographic) => {
    setFetchUserDataResponse((d) => ({
      ...d!,
      demographics,
    }));
    setShowUsernamePassword(true);
  };

  return showUsernamePassword ? (
    <BankUsernamePassword fetchUserDataResponse={fetchUserDataResponse!} />
  ) : fetchUserDataResponse ? (
    <BankSignupUserForm
      userDemographic={fetchUserDataResponse.demographics!}
      onContinue={handleShowUserNamePassword}
    />
  ) : (
    <div style={{ paddingBottom: 30 }}>
      <BankLinkingCodeInput isLoading={isLoading} onSubmit={handleSubmitCode} />
    </div>
  );
};

export default SignupBankCode;
