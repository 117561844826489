// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: demo/micashop/service/v1/micashop_service.proto

/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { AuthenticateRequest, AuthenticateResponse } from "../../../authenticate/v1/authenticate";
import { Error } from "../../../common/v1/error";
import { Pagination } from "../../../common/v1/pagination";
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ForgotUsernameRequest,
  ForgotUsernameResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
} from "../../../common/v1/password";
import { PingRequest, PingResponse } from "../../../common/v1/ping";
import { UserDemographic } from "../../../common/v1/user";
import {
  CreateUserByDemographicRequest,
  CreateUserResponse,
  GetUserRequest,
  GetUserResponse,
  RemoveUserRequest,
  RemoveUserResponse,
  UpdateUserRequest,
  UpdateUserResponse,
} from "../../user/v1/user";
import {
  RemoveUUEKRequest,
  RemoveUUEKResponse,
  SearchUUEKRequest,
  SearchUUEKResponse,
  UpdateUUEKRequest,
  UpdateUUEKResponse,
} from "../../uuek/v1/uuek";
import { ObtainValueRequest, ObtainValueResponse, ReturnValueRequest, ReturnValueResponse } from "../../value/v1/value";

export const protobufPackage = "demo.micashop.service.v1";

export interface InitializeWidgetRequest {
}

export interface InitializeWidgetResponse {
  status: InitializeWidgetResponse_Status;
  error: Error | undefined;
  sessionKey: string;
}

export enum InitializeWidgetResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  /** STATUS_ERROR - generic error that's not one of the following */
  STATUS_ERROR = 2,
}

export interface ExchangeSessionKeyRequest {
  sessionKey: string;
}

export interface ExchangeSessionKeyResponse {
  status: ExchangeSessionKeyResponse_Status;
  error:
    | Error
    | undefined;
  /** The UUEK that the Partner can use to obtain or return value for this user. */
  partnerUuek: string;
}

export enum ExchangeSessionKeyResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface Transaction {
  transactionId: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  accountId: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  totalAmount: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  approvedAmount: string;
  transactionRef: string;
  transactionType: string;
  description: string;
  organizationName: string;
  storeNumber: string;
}

export interface SearchTransactionRequest {
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  pagination: Pagination | undefined;
}

export interface SearchTransactionResponse {
  status: SearchTransactionResponse_Status;
  error: Error | undefined;
  transactions: Transaction[];
  pagination: Pagination | undefined;
}

export enum SearchTransactionResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface LinkInstrumentWithCodeRequest {
  /** string code supplied by the end user */
  linkingCode: string;
}

export interface LinkInstrumentWithCodeResponse {
  status: LinkInstrumentWithCodeResponse_Status;
  error: Error | undefined;
  linkedAccount: LinkedAccount | undefined;
  partnerUuek: string;
}

export enum LinkInstrumentWithCodeResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface ProvisionUUEKWithLinkingCodeRequest {
  /** string code supplied by the end user */
  linkingCode: string;
}

export interface Instrument {
  name: string;
}

export interface InstrumentLinkClaims {
  assertions: { [key: string]: string };
  userDemographic: UserDemographic | undefined;
  instrument: Instrument | undefined;
}

export interface InstrumentLinkClaims_AssertionsEntry {
  key: string;
  value: string;
}

export interface ProvisionUUEKWithLinkingCodeResponse {
  status: ProvisionUUEKWithLinkingCodeResponse_Status;
  error: Error | undefined;
  partnerUuek: string;
  claims: InstrumentLinkClaims | undefined;
}

export enum ProvisionUUEKWithLinkingCodeResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface SearchLinkedAccountsRequest {
}

export interface LinkedAccount {
  linkedAccountId: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  accountName: string;
  stepUpRequired: boolean;
}

export interface SearchLinkedAccountsResponse {
  status: SearchLinkedAccountsResponse_Status;
  error: Error | undefined;
  linkedAccounts: LinkedAccount[];
}

export enum SearchLinkedAccountsResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface StepUpData {
  wipStepUpData: string;
}

export interface ProvisionLinkedAccountUUEKRequest {
  linkedAccountId: string;
  /** Holds any step up data that is required to provision the UUEK */
  stepUpData: StepUpData | undefined;
}

export interface ProvisionLinkedAccountUUEKResponse {
  status: ProvisionLinkedAccountUUEKResponse_Status;
  error:
    | Error
    | undefined;
  /** The UUEK that can be used to run a transaction */
  partnerUuek: string;
}

export enum ProvisionLinkedAccountUUEKResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_STEP_UP_REQUIRED = 4,
  STATUS_STEP_UP_FAILED = 5,
  STATUS_STEP_UP_NOT_FOUND = 6,
  STATUS_STEP_UP_MISMATCH = 7,
  STATUS_STEP_UP_LOCKED = 8,
}

export interface RemoveLinkedAccountRequest {
  linkedAccountId: string;
}

export interface RemoveLinkedAccountResponse {
  status: RemoveLinkedAccountResponse_Status;
  error: Error | undefined;
}

export enum RemoveLinkedAccountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateLinkedAccountRequest {
  linkedAccountId: string;
  version: number;
  accountName: string;
}

export interface UpdateLinkedAccountResponse {
  status: UpdateLinkedAccountResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateLinkedAccountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface SearchClosedLoopAccountsRequest {
}

export interface ClosedLoopAccount {
  closedLoopAccountId: string;
  /** version of the user record, used for optimistic locking */
  version: number;
  /** Date that the Store was created at Mica. */
  created:
    | Date
    | undefined;
  /** Date that the Store was last updated at Mica. */
  updated: Date | undefined;
  accountType: string;
  currency: string;
  availableBalance: string;
  pendingBalance: string;
  postedBalance: string;
  pinSet: boolean;
}

export interface SearchClosedLoopAccountsResponse {
  status: SearchClosedLoopAccountsResponse_Status;
  error: Error | undefined;
  closedLoopAccounts: ClosedLoopAccount[];
}

export enum SearchClosedLoopAccountsResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface ClosedLoopTransaction {
  transactionId: string;
  created: Date | undefined;
  programId: string;
  userId: string;
  accountId: string;
  currency: string;
  creditDebit: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  requestedAmount: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  approvedAmount: string;
  transactionType: string;
  transactionStatus: string;
  valueStatus: string;
  organizationName: string;
  storeNumber: string;
  notes: string;
}

export interface SearchClosedLoopAccountTransactionRequest {
  closedLoopAccountId: string;
  dateFrom: Date | undefined;
  dateTo: Date | undefined;
  pagination: Pagination | undefined;
}

export interface SearchClosedLoopAccountTransactionResponse {
  status: SearchClosedLoopAccountTransactionResponse_Status;
  error: Error | undefined;
  closedLoopTransactions: ClosedLoopTransaction[];
}

export enum SearchClosedLoopAccountTransactionResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface P2PTransaction {
  p2pTransactionId: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  fromUserId: string;
  fromAccountId: string;
  fromFirstName: string;
  fromLastName: string;
  toPhone: string;
  toMessage: string;
  toUserId: string;
  toAccountId: string;
  toFirstName: string;
  toLastName: string;
  accountType: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  amount: string;
  status: string;
  expiration: Date | undefined;
}

export interface InitiateP2PRequest {
  fromAccountId: string;
  toPhone: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  amount: string;
  message: string;
}

export interface InitiateP2PResponse {
  status: InitiateP2PResponse_Status;
  error: Error | undefined;
  p2pTransactionId: string;
}

export enum InitiateP2PResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_INSUFFICIENT_FUNDS = 3,
}

export interface SearchP2PTransactionsRequest {
  pagination: Pagination | undefined;
}

export interface SearchP2PTransactionsResponse {
  status: SearchP2PTransactionsResponse_Status;
  error: Error | undefined;
  p2pTransactions: P2PTransaction[];
}

export enum SearchP2PTransactionsResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface AcceptP2PRequest {
  p2pTransactionId: string;
}

export interface AcceptP2PResponse {
  status: AcceptP2PResponse_Status;
  error: Error | undefined;
  p2pTransaction: P2PTransaction | undefined;
}

export enum AcceptP2PResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface CancelP2PRequest {
  p2pTransactionId: string;
}

export interface CancelP2PResponse {
  status: CancelP2PResponse_Status;
  error: Error | undefined;
}

export enum CancelP2PResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface SetPINRequest {
  pin: string;
}

export interface SetPINResponse {
  status: SetPINResponse_Status;
  error: Error | undefined;
}

export enum SetPINResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_PIN_ALREADY_SET = 4,
}

export interface ResetPINRequest {
  oldPin: string;
  newPin: string;
}

export interface ResetPINResponse {
  status: ResetPINResponse_Status;
  error: Error | undefined;
}

export enum ResetPINResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_PIN_MISMATCH = 4,
}

export interface ValidatePINRequest {
  pin: string;
}

export interface ValidatePINResponse {
  status: ValidatePINResponse_Status;
  error: Error | undefined;
}

export enum ValidatePINResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_PIN_MISMATCH = 4,
  STATUS_PIN_LOCKED = 5,
}

export interface RemovePINRequest {
}

export interface RemovePINResponse {
  status: RemovePINResponse_Status;
  error: Error | undefined;
}

export enum RemovePINResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface ProvisionClosedLoopAccountUUEKRequest {
  closedLoopAccountId: string;
}

export interface ProvisionClosedLoopAccountUUEKResponse {
  status: ProvisionClosedLoopAccountUUEKResponse_Status;
  error: Error | undefined;
  closedLoopAccountUuek: string;
}

export enum ProvisionClosedLoopAccountUUEKResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface GetReceiptRequest {
  accountId: string;
  loopTransactionId: string;
}

export interface GetReceiptResponse {
  status: GetReceiptResponse_Status;
  error:
    | Error
    | undefined;
  /** The data used to generate the image. */
  sourceData: Uint8Array;
}

export enum GetReceiptResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface LoadResponse {
  accountId: string;
  version: number;
  availableBalance: string;
  pendingBalance: string;
  postedBalance: string;
  loadAmount: string;
}

export interface LoadFromGiftCardRequest {
}

export interface LoadFromGiftCardResponse {
  status: LoadFromGiftCardResponse_Status;
  error: Error | undefined;
  loadResponse: LoadResponse | undefined;
}

export enum LoadFromGiftCardResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  /**
   * STATUS_NOT_FOUND - If we were checking the card details somewhere, we'd return this status.
   *    STATUS_INSUFFICIENT_FUNDS = 4;
   */
  STATUS_NOT_FOUND = 3,
}

export interface LoadFromLinkedAccountRequest {
  linkedAccountId: string;
  closedLoopAccountId: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  amount: string;
  stepUpData: StepUpData | undefined;
}

export interface LoadFromLinkedAccountResponse {
  status: LoadFromLinkedAccountResponse_Status;
  error: Error | undefined;
  loadResponse: LoadResponse | undefined;
}

export enum LoadFromLinkedAccountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_INSUFFICIENT_FUNDS = 4,
  STATUS_STEP_UP_REQUIRED = 5,
  STATUS_STEP_UP_FAILED = 6,
  STATUS_STEP_UP_NOT_FOUND = 7,
  STATUS_STEP_UP_MISMATCH = 8,
  STATUS_STEP_UP_LOCKED = 9,
}

export interface LoadFromLinkingCodeRequest {
  /** string code supplied by the end user */
  linkingCode: string;
  closedLoopAccountId: string;
}

export interface LoadFromLinkingCodeResponse {
  status: LoadFromLinkingCodeResponse_Status;
  error: Error | undefined;
  loadResponse: LoadResponse | undefined;
}

export enum LoadFromLinkingCodeResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_INSUFFICIENT_FUNDS = 4,
}

export interface FetchUserDataFromBankLinkingCodeRequest {
  linkingCode: string;
}

export interface FetchUserDataFromBankLinkingCodeResponse {
  status: FetchUserDataFromBankLinkingCodeResponse_Status;
  demographics:
    | UserDemographic
    | undefined;
  /** the reference to the linked instrument that will be available once the user "activates" their account */
  fromLinkKey: string;
}

export enum FetchUserDataFromBankLinkingCodeResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_INVALID_CODE = 2,
  STATUS_ERROR = 3,
}

function createBaseInitializeWidgetRequest(): InitializeWidgetRequest {
  return {};
}

export const InitializeWidgetRequest = {
  encode(_: InitializeWidgetRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InitializeWidgetRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInitializeWidgetRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<InitializeWidgetRequest>, I>>(base?: I): InitializeWidgetRequest {
    return InitializeWidgetRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InitializeWidgetRequest>, I>>(_: I): InitializeWidgetRequest {
    const message = createBaseInitializeWidgetRequest();
    return message;
  },
};

function createBaseInitializeWidgetResponse(): InitializeWidgetResponse {
  return { status: 0, error: undefined, sessionKey: "" };
}

export const InitializeWidgetResponse = {
  encode(message: InitializeWidgetResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.sessionKey !== "") {
      writer.uint32(26).string(message.sessionKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InitializeWidgetResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInitializeWidgetResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sessionKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<InitializeWidgetResponse>, I>>(base?: I): InitializeWidgetResponse {
    return InitializeWidgetResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InitializeWidgetResponse>, I>>(object: I): InitializeWidgetResponse {
    const message = createBaseInitializeWidgetResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.sessionKey = object.sessionKey ?? "";
    return message;
  },
};

function createBaseExchangeSessionKeyRequest(): ExchangeSessionKeyRequest {
  return { sessionKey: "" };
}

export const ExchangeSessionKeyRequest = {
  encode(message: ExchangeSessionKeyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sessionKey !== "") {
      writer.uint32(10).string(message.sessionKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeSessionKeyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeSessionKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessionKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ExchangeSessionKeyRequest>, I>>(base?: I): ExchangeSessionKeyRequest {
    return ExchangeSessionKeyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExchangeSessionKeyRequest>, I>>(object: I): ExchangeSessionKeyRequest {
    const message = createBaseExchangeSessionKeyRequest();
    message.sessionKey = object.sessionKey ?? "";
    return message;
  },
};

function createBaseExchangeSessionKeyResponse(): ExchangeSessionKeyResponse {
  return { status: 0, error: undefined, partnerUuek: "" };
}

export const ExchangeSessionKeyResponse = {
  encode(message: ExchangeSessionKeyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.partnerUuek !== "") {
      writer.uint32(26).string(message.partnerUuek);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExchangeSessionKeyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExchangeSessionKeyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partnerUuek = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ExchangeSessionKeyResponse>, I>>(base?: I): ExchangeSessionKeyResponse {
    return ExchangeSessionKeyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ExchangeSessionKeyResponse>, I>>(object: I): ExchangeSessionKeyResponse {
    const message = createBaseExchangeSessionKeyResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.partnerUuek = object.partnerUuek ?? "";
    return message;
  },
};

function createBaseTransaction(): Transaction {
  return {
    transactionId: "",
    version: 0,
    created: undefined,
    updated: undefined,
    accountId: "",
    totalAmount: "",
    approvedAmount: "",
    transactionRef: "",
    transactionType: "",
    description: "",
    organizationName: "",
    storeNumber: "",
  };
}

export const Transaction = {
  encode(message: Transaction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(34).fork()).ldelim();
    }
    if (message.accountId !== "") {
      writer.uint32(42).string(message.accountId);
    }
    if (message.totalAmount !== "") {
      writer.uint32(50).string(message.totalAmount);
    }
    if (message.approvedAmount !== "") {
      writer.uint32(98).string(message.approvedAmount);
    }
    if (message.transactionRef !== "") {
      writer.uint32(58).string(message.transactionRef);
    }
    if (message.transactionType !== "") {
      writer.uint32(66).string(message.transactionType);
    }
    if (message.description !== "") {
      writer.uint32(74).string(message.description);
    }
    if (message.organizationName !== "") {
      writer.uint32(82).string(message.organizationName);
    }
    if (message.storeNumber !== "") {
      writer.uint32(90).string(message.storeNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Transaction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.totalAmount = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.approvedAmount = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.transactionRef = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.transactionType = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.description = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.organizationName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.storeNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Transaction>, I>>(base?: I): Transaction {
    return Transaction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Transaction>, I>>(object: I): Transaction {
    const message = createBaseTransaction();
    message.transactionId = object.transactionId ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.accountId = object.accountId ?? "";
    message.totalAmount = object.totalAmount ?? "";
    message.approvedAmount = object.approvedAmount ?? "";
    message.transactionRef = object.transactionRef ?? "";
    message.transactionType = object.transactionType ?? "";
    message.description = object.description ?? "";
    message.organizationName = object.organizationName ?? "";
    message.storeNumber = object.storeNumber ?? "";
    return message;
  },
};

function createBaseSearchTransactionRequest(): SearchTransactionRequest {
  return { dateFrom: undefined, dateTo: undefined, pagination: undefined };
}

export const SearchTransactionRequest = {
  encode(message: SearchTransactionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.dateFrom), writer.uint32(18).fork()).ldelim();
    }
    if (message.dateTo !== undefined) {
      Timestamp.encode(toTimestamp(message.dateTo), writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dateFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dateTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionRequest>, I>>(base?: I): SearchTransactionRequest {
    return SearchTransactionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchTransactionRequest>, I>>(object: I): SearchTransactionRequest {
    const message = createBaseSearchTransactionRequest();
    message.dateFrom = object.dateFrom ?? undefined;
    message.dateTo = object.dateTo ?? undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseSearchTransactionResponse(): SearchTransactionResponse {
  return { status: 0, error: undefined, transactions: [], pagination: undefined };
}

export const SearchTransactionResponse = {
  encode(message: SearchTransactionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.transactions) {
      Transaction.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transactions.push(Transaction.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionResponse>, I>>(base?: I): SearchTransactionResponse {
    return SearchTransactionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchTransactionResponse>, I>>(object: I): SearchTransactionResponse {
    const message = createBaseSearchTransactionResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.transactions = object.transactions?.map((e) => Transaction.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseLinkInstrumentWithCodeRequest(): LinkInstrumentWithCodeRequest {
  return { linkingCode: "" };
}

export const LinkInstrumentWithCodeRequest = {
  encode(message: LinkInstrumentWithCodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkingCode !== "") {
      writer.uint32(10).string(message.linkingCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LinkInstrumentWithCodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkInstrumentWithCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkingCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LinkInstrumentWithCodeRequest>, I>>(base?: I): LinkInstrumentWithCodeRequest {
    return LinkInstrumentWithCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkInstrumentWithCodeRequest>, I>>(
    object: I,
  ): LinkInstrumentWithCodeRequest {
    const message = createBaseLinkInstrumentWithCodeRequest();
    message.linkingCode = object.linkingCode ?? "";
    return message;
  },
};

function createBaseLinkInstrumentWithCodeResponse(): LinkInstrumentWithCodeResponse {
  return { status: 0, error: undefined, linkedAccount: undefined, partnerUuek: "" };
}

export const LinkInstrumentWithCodeResponse = {
  encode(message: LinkInstrumentWithCodeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.linkedAccount !== undefined) {
      LinkedAccount.encode(message.linkedAccount, writer.uint32(26).fork()).ldelim();
    }
    if (message.partnerUuek !== "") {
      writer.uint32(34).string(message.partnerUuek);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LinkInstrumentWithCodeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkInstrumentWithCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.linkedAccount = LinkedAccount.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partnerUuek = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LinkInstrumentWithCodeResponse>, I>>(base?: I): LinkInstrumentWithCodeResponse {
    return LinkInstrumentWithCodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkInstrumentWithCodeResponse>, I>>(
    object: I,
  ): LinkInstrumentWithCodeResponse {
    const message = createBaseLinkInstrumentWithCodeResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.linkedAccount = (object.linkedAccount !== undefined && object.linkedAccount !== null)
      ? LinkedAccount.fromPartial(object.linkedAccount)
      : undefined;
    message.partnerUuek = object.partnerUuek ?? "";
    return message;
  },
};

function createBaseProvisionUUEKWithLinkingCodeRequest(): ProvisionUUEKWithLinkingCodeRequest {
  return { linkingCode: "" };
}

export const ProvisionUUEKWithLinkingCodeRequest = {
  encode(message: ProvisionUUEKWithLinkingCodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkingCode !== "") {
      writer.uint32(10).string(message.linkingCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionUUEKWithLinkingCodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionUUEKWithLinkingCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkingCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProvisionUUEKWithLinkingCodeRequest>, I>>(
    base?: I,
  ): ProvisionUUEKWithLinkingCodeRequest {
    return ProvisionUUEKWithLinkingCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionUUEKWithLinkingCodeRequest>, I>>(
    object: I,
  ): ProvisionUUEKWithLinkingCodeRequest {
    const message = createBaseProvisionUUEKWithLinkingCodeRequest();
    message.linkingCode = object.linkingCode ?? "";
    return message;
  },
};

function createBaseInstrument(): Instrument {
  return { name: "" };
}

export const Instrument = {
  encode(message: Instrument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Instrument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Instrument>, I>>(base?: I): Instrument {
    return Instrument.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Instrument>, I>>(object: I): Instrument {
    const message = createBaseInstrument();
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseInstrumentLinkClaims(): InstrumentLinkClaims {
  return { assertions: {}, userDemographic: undefined, instrument: undefined };
}

export const InstrumentLinkClaims = {
  encode(message: InstrumentLinkClaims, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.assertions).forEach(([key, value]) => {
      InstrumentLinkClaims_AssertionsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.userDemographic !== undefined) {
      UserDemographic.encode(message.userDemographic, writer.uint32(18).fork()).ldelim();
    }
    if (message.instrument !== undefined) {
      Instrument.encode(message.instrument, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentLinkClaims {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentLinkClaims();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = InstrumentLinkClaims_AssertionsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.assertions[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.userDemographic = UserDemographic.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.instrument = Instrument.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<InstrumentLinkClaims>, I>>(base?: I): InstrumentLinkClaims {
    return InstrumentLinkClaims.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentLinkClaims>, I>>(object: I): InstrumentLinkClaims {
    const message = createBaseInstrumentLinkClaims();
    message.assertions = Object.entries(object.assertions ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.userDemographic = (object.userDemographic !== undefined && object.userDemographic !== null)
      ? UserDemographic.fromPartial(object.userDemographic)
      : undefined;
    message.instrument = (object.instrument !== undefined && object.instrument !== null)
      ? Instrument.fromPartial(object.instrument)
      : undefined;
    return message;
  },
};

function createBaseInstrumentLinkClaims_AssertionsEntry(): InstrumentLinkClaims_AssertionsEntry {
  return { key: "", value: "" };
}

export const InstrumentLinkClaims_AssertionsEntry = {
  encode(message: InstrumentLinkClaims_AssertionsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentLinkClaims_AssertionsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentLinkClaims_AssertionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<InstrumentLinkClaims_AssertionsEntry>, I>>(
    base?: I,
  ): InstrumentLinkClaims_AssertionsEntry {
    return InstrumentLinkClaims_AssertionsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentLinkClaims_AssertionsEntry>, I>>(
    object: I,
  ): InstrumentLinkClaims_AssertionsEntry {
    const message = createBaseInstrumentLinkClaims_AssertionsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseProvisionUUEKWithLinkingCodeResponse(): ProvisionUUEKWithLinkingCodeResponse {
  return { status: 0, error: undefined, partnerUuek: "", claims: undefined };
}

export const ProvisionUUEKWithLinkingCodeResponse = {
  encode(message: ProvisionUUEKWithLinkingCodeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.partnerUuek !== "") {
      writer.uint32(26).string(message.partnerUuek);
    }
    if (message.claims !== undefined) {
      InstrumentLinkClaims.encode(message.claims, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionUUEKWithLinkingCodeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionUUEKWithLinkingCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partnerUuek = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.claims = InstrumentLinkClaims.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProvisionUUEKWithLinkingCodeResponse>, I>>(
    base?: I,
  ): ProvisionUUEKWithLinkingCodeResponse {
    return ProvisionUUEKWithLinkingCodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionUUEKWithLinkingCodeResponse>, I>>(
    object: I,
  ): ProvisionUUEKWithLinkingCodeResponse {
    const message = createBaseProvisionUUEKWithLinkingCodeResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.partnerUuek = object.partnerUuek ?? "";
    message.claims = (object.claims !== undefined && object.claims !== null)
      ? InstrumentLinkClaims.fromPartial(object.claims)
      : undefined;
    return message;
  },
};

function createBaseSearchLinkedAccountsRequest(): SearchLinkedAccountsRequest {
  return {};
}

export const SearchLinkedAccountsRequest = {
  encode(_: SearchLinkedAccountsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchLinkedAccountsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchLinkedAccountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchLinkedAccountsRequest>, I>>(base?: I): SearchLinkedAccountsRequest {
    return SearchLinkedAccountsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchLinkedAccountsRequest>, I>>(_: I): SearchLinkedAccountsRequest {
    const message = createBaseSearchLinkedAccountsRequest();
    return message;
  },
};

function createBaseLinkedAccount(): LinkedAccount {
  return {
    linkedAccountId: "",
    version: 0,
    created: undefined,
    updated: undefined,
    accountName: "",
    stepUpRequired: false,
  };
}

export const LinkedAccount = {
  encode(message: LinkedAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkedAccountId !== "") {
      writer.uint32(10).string(message.linkedAccountId);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(34).fork()).ldelim();
    }
    if (message.accountName !== "") {
      writer.uint32(42).string(message.accountName);
    }
    if (message.stepUpRequired !== false) {
      writer.uint32(48).bool(message.stepUpRequired);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LinkedAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLinkedAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accountName = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.stepUpRequired = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LinkedAccount>, I>>(base?: I): LinkedAccount {
    return LinkedAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LinkedAccount>, I>>(object: I): LinkedAccount {
    const message = createBaseLinkedAccount();
    message.linkedAccountId = object.linkedAccountId ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.accountName = object.accountName ?? "";
    message.stepUpRequired = object.stepUpRequired ?? false;
    return message;
  },
};

function createBaseSearchLinkedAccountsResponse(): SearchLinkedAccountsResponse {
  return { status: 0, error: undefined, linkedAccounts: [] };
}

export const SearchLinkedAccountsResponse = {
  encode(message: SearchLinkedAccountsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.linkedAccounts) {
      LinkedAccount.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchLinkedAccountsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchLinkedAccountsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.linkedAccounts.push(LinkedAccount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchLinkedAccountsResponse>, I>>(base?: I): SearchLinkedAccountsResponse {
    return SearchLinkedAccountsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchLinkedAccountsResponse>, I>>(object: I): SearchLinkedAccountsResponse {
    const message = createBaseSearchLinkedAccountsResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.linkedAccounts = object.linkedAccounts?.map((e) => LinkedAccount.fromPartial(e)) || [];
    return message;
  },
};

function createBaseStepUpData(): StepUpData {
  return { wipStepUpData: "" };
}

export const StepUpData = {
  encode(message: StepUpData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.wipStepUpData !== "") {
      writer.uint32(10).string(message.wipStepUpData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StepUpData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStepUpData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.wipStepUpData = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<StepUpData>, I>>(base?: I): StepUpData {
    return StepUpData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StepUpData>, I>>(object: I): StepUpData {
    const message = createBaseStepUpData();
    message.wipStepUpData = object.wipStepUpData ?? "";
    return message;
  },
};

function createBaseProvisionLinkedAccountUUEKRequest(): ProvisionLinkedAccountUUEKRequest {
  return { linkedAccountId: "", stepUpData: undefined };
}

export const ProvisionLinkedAccountUUEKRequest = {
  encode(message: ProvisionLinkedAccountUUEKRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkedAccountId !== "") {
      writer.uint32(10).string(message.linkedAccountId);
    }
    if (message.stepUpData !== undefined) {
      StepUpData.encode(message.stepUpData, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionLinkedAccountUUEKRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionLinkedAccountUUEKRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.stepUpData = StepUpData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProvisionLinkedAccountUUEKRequest>, I>>(
    base?: I,
  ): ProvisionLinkedAccountUUEKRequest {
    return ProvisionLinkedAccountUUEKRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionLinkedAccountUUEKRequest>, I>>(
    object: I,
  ): ProvisionLinkedAccountUUEKRequest {
    const message = createBaseProvisionLinkedAccountUUEKRequest();
    message.linkedAccountId = object.linkedAccountId ?? "";
    message.stepUpData = (object.stepUpData !== undefined && object.stepUpData !== null)
      ? StepUpData.fromPartial(object.stepUpData)
      : undefined;
    return message;
  },
};

function createBaseProvisionLinkedAccountUUEKResponse(): ProvisionLinkedAccountUUEKResponse {
  return { status: 0, error: undefined, partnerUuek: "" };
}

export const ProvisionLinkedAccountUUEKResponse = {
  encode(message: ProvisionLinkedAccountUUEKResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.partnerUuek !== "") {
      writer.uint32(26).string(message.partnerUuek);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionLinkedAccountUUEKResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionLinkedAccountUUEKResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partnerUuek = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProvisionLinkedAccountUUEKResponse>, I>>(
    base?: I,
  ): ProvisionLinkedAccountUUEKResponse {
    return ProvisionLinkedAccountUUEKResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionLinkedAccountUUEKResponse>, I>>(
    object: I,
  ): ProvisionLinkedAccountUUEKResponse {
    const message = createBaseProvisionLinkedAccountUUEKResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.partnerUuek = object.partnerUuek ?? "";
    return message;
  },
};

function createBaseRemoveLinkedAccountRequest(): RemoveLinkedAccountRequest {
  return { linkedAccountId: "" };
}

export const RemoveLinkedAccountRequest = {
  encode(message: RemoveLinkedAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkedAccountId !== "") {
      writer.uint32(10).string(message.linkedAccountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveLinkedAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveLinkedAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedAccountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveLinkedAccountRequest>, I>>(base?: I): RemoveLinkedAccountRequest {
    return RemoveLinkedAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveLinkedAccountRequest>, I>>(object: I): RemoveLinkedAccountRequest {
    const message = createBaseRemoveLinkedAccountRequest();
    message.linkedAccountId = object.linkedAccountId ?? "";
    return message;
  },
};

function createBaseRemoveLinkedAccountResponse(): RemoveLinkedAccountResponse {
  return { status: 0, error: undefined };
}

export const RemoveLinkedAccountResponse = {
  encode(message: RemoveLinkedAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveLinkedAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveLinkedAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveLinkedAccountResponse>, I>>(base?: I): RemoveLinkedAccountResponse {
    return RemoveLinkedAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveLinkedAccountResponse>, I>>(object: I): RemoveLinkedAccountResponse {
    const message = createBaseRemoveLinkedAccountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseUpdateLinkedAccountRequest(): UpdateLinkedAccountRequest {
  return { linkedAccountId: "", version: 0, accountName: "" };
}

export const UpdateLinkedAccountRequest = {
  encode(message: UpdateLinkedAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkedAccountId !== "") {
      writer.uint32(10).string(message.linkedAccountId);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.accountName !== "") {
      writer.uint32(26).string(message.accountName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLinkedAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLinkedAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateLinkedAccountRequest>, I>>(base?: I): UpdateLinkedAccountRequest {
    return UpdateLinkedAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLinkedAccountRequest>, I>>(object: I): UpdateLinkedAccountRequest {
    const message = createBaseUpdateLinkedAccountRequest();
    message.linkedAccountId = object.linkedAccountId ?? "";
    message.version = object.version ?? 0;
    message.accountName = object.accountName ?? "";
    return message;
  },
};

function createBaseUpdateLinkedAccountResponse(): UpdateLinkedAccountResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateLinkedAccountResponse = {
  encode(message: UpdateLinkedAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateLinkedAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateLinkedAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateLinkedAccountResponse>, I>>(base?: I): UpdateLinkedAccountResponse {
    return UpdateLinkedAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateLinkedAccountResponse>, I>>(object: I): UpdateLinkedAccountResponse {
    const message = createBaseUpdateLinkedAccountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseSearchClosedLoopAccountsRequest(): SearchClosedLoopAccountsRequest {
  return {};
}

export const SearchClosedLoopAccountsRequest = {
  encode(_: SearchClosedLoopAccountsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchClosedLoopAccountsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchClosedLoopAccountsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchClosedLoopAccountsRequest>, I>>(base?: I): SearchClosedLoopAccountsRequest {
    return SearchClosedLoopAccountsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchClosedLoopAccountsRequest>, I>>(_: I): SearchClosedLoopAccountsRequest {
    const message = createBaseSearchClosedLoopAccountsRequest();
    return message;
  },
};

function createBaseClosedLoopAccount(): ClosedLoopAccount {
  return {
    closedLoopAccountId: "",
    version: 0,
    created: undefined,
    updated: undefined,
    accountType: "",
    currency: "",
    availableBalance: "",
    pendingBalance: "",
    postedBalance: "",
    pinSet: false,
  };
}

export const ClosedLoopAccount = {
  encode(message: ClosedLoopAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.closedLoopAccountId !== "") {
      writer.uint32(10).string(message.closedLoopAccountId);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(34).fork()).ldelim();
    }
    if (message.accountType !== "") {
      writer.uint32(42).string(message.accountType);
    }
    if (message.currency !== "") {
      writer.uint32(50).string(message.currency);
    }
    if (message.availableBalance !== "") {
      writer.uint32(58).string(message.availableBalance);
    }
    if (message.pendingBalance !== "") {
      writer.uint32(66).string(message.pendingBalance);
    }
    if (message.postedBalance !== "") {
      writer.uint32(74).string(message.postedBalance);
    }
    if (message.pinSet !== false) {
      writer.uint32(80).bool(message.pinSet);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClosedLoopAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClosedLoopAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.closedLoopAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.accountType = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.availableBalance = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pendingBalance = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.postedBalance = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.pinSet = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ClosedLoopAccount>, I>>(base?: I): ClosedLoopAccount {
    return ClosedLoopAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClosedLoopAccount>, I>>(object: I): ClosedLoopAccount {
    const message = createBaseClosedLoopAccount();
    message.closedLoopAccountId = object.closedLoopAccountId ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.accountType = object.accountType ?? "";
    message.currency = object.currency ?? "";
    message.availableBalance = object.availableBalance ?? "";
    message.pendingBalance = object.pendingBalance ?? "";
    message.postedBalance = object.postedBalance ?? "";
    message.pinSet = object.pinSet ?? false;
    return message;
  },
};

function createBaseSearchClosedLoopAccountsResponse(): SearchClosedLoopAccountsResponse {
  return { status: 0, error: undefined, closedLoopAccounts: [] };
}

export const SearchClosedLoopAccountsResponse = {
  encode(message: SearchClosedLoopAccountsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.closedLoopAccounts) {
      ClosedLoopAccount.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchClosedLoopAccountsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchClosedLoopAccountsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.closedLoopAccounts.push(ClosedLoopAccount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchClosedLoopAccountsResponse>, I>>(
    base?: I,
  ): SearchClosedLoopAccountsResponse {
    return SearchClosedLoopAccountsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchClosedLoopAccountsResponse>, I>>(
    object: I,
  ): SearchClosedLoopAccountsResponse {
    const message = createBaseSearchClosedLoopAccountsResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.closedLoopAccounts = object.closedLoopAccounts?.map((e) => ClosedLoopAccount.fromPartial(e)) || [];
    return message;
  },
};

function createBaseClosedLoopTransaction(): ClosedLoopTransaction {
  return {
    transactionId: "",
    created: undefined,
    programId: "",
    userId: "",
    accountId: "",
    currency: "",
    creditDebit: "",
    requestedAmount: "",
    approvedAmount: "",
    transactionType: "",
    transactionStatus: "",
    valueStatus: "",
    organizationName: "",
    storeNumber: "",
    notes: "",
  };
}

export const ClosedLoopTransaction = {
  encode(message: ClosedLoopTransaction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transactionId !== "") {
      writer.uint32(10).string(message.transactionId);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.programId !== "") {
      writer.uint32(34).string(message.programId);
    }
    if (message.userId !== "") {
      writer.uint32(42).string(message.userId);
    }
    if (message.accountId !== "") {
      writer.uint32(50).string(message.accountId);
    }
    if (message.currency !== "") {
      writer.uint32(82).string(message.currency);
    }
    if (message.creditDebit !== "") {
      writer.uint32(178).string(message.creditDebit);
    }
    if (message.requestedAmount !== "") {
      writer.uint32(90).string(message.requestedAmount);
    }
    if (message.approvedAmount !== "") {
      writer.uint32(98).string(message.approvedAmount);
    }
    if (message.transactionType !== "") {
      writer.uint32(106).string(message.transactionType);
    }
    if (message.transactionStatus !== "") {
      writer.uint32(114).string(message.transactionStatus);
    }
    if (message.valueStatus !== "") {
      writer.uint32(122).string(message.valueStatus);
    }
    if (message.organizationName !== "") {
      writer.uint32(138).string(message.organizationName);
    }
    if (message.storeNumber !== "") {
      writer.uint32(154).string(message.storeNumber);
    }
    if (message.notes !== "") {
      writer.uint32(170).string(message.notes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClosedLoopTransaction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClosedLoopTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transactionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.programId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.creditDebit = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.requestedAmount = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.approvedAmount = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.transactionType = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.transactionStatus = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.valueStatus = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.organizationName = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.storeNumber = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.notes = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ClosedLoopTransaction>, I>>(base?: I): ClosedLoopTransaction {
    return ClosedLoopTransaction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClosedLoopTransaction>, I>>(object: I): ClosedLoopTransaction {
    const message = createBaseClosedLoopTransaction();
    message.transactionId = object.transactionId ?? "";
    message.created = object.created ?? undefined;
    message.programId = object.programId ?? "";
    message.userId = object.userId ?? "";
    message.accountId = object.accountId ?? "";
    message.currency = object.currency ?? "";
    message.creditDebit = object.creditDebit ?? "";
    message.requestedAmount = object.requestedAmount ?? "";
    message.approvedAmount = object.approvedAmount ?? "";
    message.transactionType = object.transactionType ?? "";
    message.transactionStatus = object.transactionStatus ?? "";
    message.valueStatus = object.valueStatus ?? "";
    message.organizationName = object.organizationName ?? "";
    message.storeNumber = object.storeNumber ?? "";
    message.notes = object.notes ?? "";
    return message;
  },
};

function createBaseSearchClosedLoopAccountTransactionRequest(): SearchClosedLoopAccountTransactionRequest {
  return { closedLoopAccountId: "", dateFrom: undefined, dateTo: undefined, pagination: undefined };
}

export const SearchClosedLoopAccountTransactionRequest = {
  encode(message: SearchClosedLoopAccountTransactionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.closedLoopAccountId !== "") {
      writer.uint32(34).string(message.closedLoopAccountId);
    }
    if (message.dateFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.dateFrom), writer.uint32(18).fork()).ldelim();
    }
    if (message.dateTo !== undefined) {
      Timestamp.encode(toTimestamp(message.dateTo), writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchClosedLoopAccountTransactionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchClosedLoopAccountTransactionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.closedLoopAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dateFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.dateTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchClosedLoopAccountTransactionRequest>, I>>(
    base?: I,
  ): SearchClosedLoopAccountTransactionRequest {
    return SearchClosedLoopAccountTransactionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchClosedLoopAccountTransactionRequest>, I>>(
    object: I,
  ): SearchClosedLoopAccountTransactionRequest {
    const message = createBaseSearchClosedLoopAccountTransactionRequest();
    message.closedLoopAccountId = object.closedLoopAccountId ?? "";
    message.dateFrom = object.dateFrom ?? undefined;
    message.dateTo = object.dateTo ?? undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseSearchClosedLoopAccountTransactionResponse(): SearchClosedLoopAccountTransactionResponse {
  return { status: 0, error: undefined, closedLoopTransactions: [] };
}

export const SearchClosedLoopAccountTransactionResponse = {
  encode(message: SearchClosedLoopAccountTransactionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.closedLoopTransactions) {
      ClosedLoopTransaction.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchClosedLoopAccountTransactionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchClosedLoopAccountTransactionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.closedLoopTransactions.push(ClosedLoopTransaction.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchClosedLoopAccountTransactionResponse>, I>>(
    base?: I,
  ): SearchClosedLoopAccountTransactionResponse {
    return SearchClosedLoopAccountTransactionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchClosedLoopAccountTransactionResponse>, I>>(
    object: I,
  ): SearchClosedLoopAccountTransactionResponse {
    const message = createBaseSearchClosedLoopAccountTransactionResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.closedLoopTransactions = object.closedLoopTransactions?.map((e) => ClosedLoopTransaction.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseP2PTransaction(): P2PTransaction {
  return {
    p2pTransactionId: "",
    version: 0,
    created: undefined,
    updated: undefined,
    fromUserId: "",
    fromAccountId: "",
    fromFirstName: "",
    fromLastName: "",
    toPhone: "",
    toMessage: "",
    toUserId: "",
    toAccountId: "",
    toFirstName: "",
    toLastName: "",
    accountType: "",
    amount: "",
    status: "",
    expiration: undefined,
  };
}

export const P2PTransaction = {
  encode(message: P2PTransaction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.p2pTransactionId !== "") {
      writer.uint32(10).string(message.p2pTransactionId);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(34).fork()).ldelim();
    }
    if (message.fromUserId !== "") {
      writer.uint32(42).string(message.fromUserId);
    }
    if (message.fromAccountId !== "") {
      writer.uint32(50).string(message.fromAccountId);
    }
    if (message.fromFirstName !== "") {
      writer.uint32(58).string(message.fromFirstName);
    }
    if (message.fromLastName !== "") {
      writer.uint32(66).string(message.fromLastName);
    }
    if (message.toPhone !== "") {
      writer.uint32(74).string(message.toPhone);
    }
    if (message.toMessage !== "") {
      writer.uint32(146).string(message.toMessage);
    }
    if (message.toUserId !== "") {
      writer.uint32(82).string(message.toUserId);
    }
    if (message.toAccountId !== "") {
      writer.uint32(90).string(message.toAccountId);
    }
    if (message.toFirstName !== "") {
      writer.uint32(98).string(message.toFirstName);
    }
    if (message.toLastName !== "") {
      writer.uint32(106).string(message.toLastName);
    }
    if (message.accountType !== "") {
      writer.uint32(114).string(message.accountType);
    }
    if (message.amount !== "") {
      writer.uint32(122).string(message.amount);
    }
    if (message.status !== "") {
      writer.uint32(130).string(message.status);
    }
    if (message.expiration !== undefined) {
      Timestamp.encode(toTimestamp(message.expiration), writer.uint32(138).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): P2PTransaction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseP2PTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.p2pTransactionId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fromUserId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fromAccountId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fromFirstName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fromLastName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.toPhone = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.toMessage = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.toUserId = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.toAccountId = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.toFirstName = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.toLastName = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.accountType = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.status = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.expiration = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<P2PTransaction>, I>>(base?: I): P2PTransaction {
    return P2PTransaction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<P2PTransaction>, I>>(object: I): P2PTransaction {
    const message = createBaseP2PTransaction();
    message.p2pTransactionId = object.p2pTransactionId ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.fromUserId = object.fromUserId ?? "";
    message.fromAccountId = object.fromAccountId ?? "";
    message.fromFirstName = object.fromFirstName ?? "";
    message.fromLastName = object.fromLastName ?? "";
    message.toPhone = object.toPhone ?? "";
    message.toMessage = object.toMessage ?? "";
    message.toUserId = object.toUserId ?? "";
    message.toAccountId = object.toAccountId ?? "";
    message.toFirstName = object.toFirstName ?? "";
    message.toLastName = object.toLastName ?? "";
    message.accountType = object.accountType ?? "";
    message.amount = object.amount ?? "";
    message.status = object.status ?? "";
    message.expiration = object.expiration ?? undefined;
    return message;
  },
};

function createBaseInitiateP2PRequest(): InitiateP2PRequest {
  return { fromAccountId: "", toPhone: "", amount: "", message: "" };
}

export const InitiateP2PRequest = {
  encode(message: InitiateP2PRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fromAccountId !== "") {
      writer.uint32(10).string(message.fromAccountId);
    }
    if (message.toPhone !== "") {
      writer.uint32(18).string(message.toPhone);
    }
    if (message.amount !== "") {
      writer.uint32(26).string(message.amount);
    }
    if (message.message !== "") {
      writer.uint32(34).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InitiateP2PRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInitiateP2PRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fromAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.toPhone = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<InitiateP2PRequest>, I>>(base?: I): InitiateP2PRequest {
    return InitiateP2PRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InitiateP2PRequest>, I>>(object: I): InitiateP2PRequest {
    const message = createBaseInitiateP2PRequest();
    message.fromAccountId = object.fromAccountId ?? "";
    message.toPhone = object.toPhone ?? "";
    message.amount = object.amount ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseInitiateP2PResponse(): InitiateP2PResponse {
  return { status: 0, error: undefined, p2pTransactionId: "" };
}

export const InitiateP2PResponse = {
  encode(message: InitiateP2PResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.p2pTransactionId !== "") {
      writer.uint32(26).string(message.p2pTransactionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InitiateP2PResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInitiateP2PResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.p2pTransactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<InitiateP2PResponse>, I>>(base?: I): InitiateP2PResponse {
    return InitiateP2PResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InitiateP2PResponse>, I>>(object: I): InitiateP2PResponse {
    const message = createBaseInitiateP2PResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.p2pTransactionId = object.p2pTransactionId ?? "";
    return message;
  },
};

function createBaseSearchP2PTransactionsRequest(): SearchP2PTransactionsRequest {
  return { pagination: undefined };
}

export const SearchP2PTransactionsRequest = {
  encode(message: SearchP2PTransactionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchP2PTransactionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchP2PTransactionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchP2PTransactionsRequest>, I>>(base?: I): SearchP2PTransactionsRequest {
    return SearchP2PTransactionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchP2PTransactionsRequest>, I>>(object: I): SearchP2PTransactionsRequest {
    const message = createBaseSearchP2PTransactionsRequest();
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseSearchP2PTransactionsResponse(): SearchP2PTransactionsResponse {
  return { status: 0, error: undefined, p2pTransactions: [] };
}

export const SearchP2PTransactionsResponse = {
  encode(message: SearchP2PTransactionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.p2pTransactions) {
      P2PTransaction.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchP2PTransactionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchP2PTransactionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.p2pTransactions.push(P2PTransaction.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchP2PTransactionsResponse>, I>>(base?: I): SearchP2PTransactionsResponse {
    return SearchP2PTransactionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchP2PTransactionsResponse>, I>>(
    object: I,
  ): SearchP2PTransactionsResponse {
    const message = createBaseSearchP2PTransactionsResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.p2pTransactions = object.p2pTransactions?.map((e) => P2PTransaction.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAcceptP2PRequest(): AcceptP2PRequest {
  return { p2pTransactionId: "" };
}

export const AcceptP2PRequest = {
  encode(message: AcceptP2PRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.p2pTransactionId !== "") {
      writer.uint32(10).string(message.p2pTransactionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptP2PRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptP2PRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.p2pTransactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AcceptP2PRequest>, I>>(base?: I): AcceptP2PRequest {
    return AcceptP2PRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcceptP2PRequest>, I>>(object: I): AcceptP2PRequest {
    const message = createBaseAcceptP2PRequest();
    message.p2pTransactionId = object.p2pTransactionId ?? "";
    return message;
  },
};

function createBaseAcceptP2PResponse(): AcceptP2PResponse {
  return { status: 0, error: undefined, p2pTransaction: undefined };
}

export const AcceptP2PResponse = {
  encode(message: AcceptP2PResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.p2pTransaction !== undefined) {
      P2PTransaction.encode(message.p2pTransaction, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcceptP2PResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAcceptP2PResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.p2pTransaction = P2PTransaction.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AcceptP2PResponse>, I>>(base?: I): AcceptP2PResponse {
    return AcceptP2PResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AcceptP2PResponse>, I>>(object: I): AcceptP2PResponse {
    const message = createBaseAcceptP2PResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.p2pTransaction = (object.p2pTransaction !== undefined && object.p2pTransaction !== null)
      ? P2PTransaction.fromPartial(object.p2pTransaction)
      : undefined;
    return message;
  },
};

function createBaseCancelP2PRequest(): CancelP2PRequest {
  return { p2pTransactionId: "" };
}

export const CancelP2PRequest = {
  encode(message: CancelP2PRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.p2pTransactionId !== "") {
      writer.uint32(10).string(message.p2pTransactionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelP2PRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelP2PRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.p2pTransactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CancelP2PRequest>, I>>(base?: I): CancelP2PRequest {
    return CancelP2PRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelP2PRequest>, I>>(object: I): CancelP2PRequest {
    const message = createBaseCancelP2PRequest();
    message.p2pTransactionId = object.p2pTransactionId ?? "";
    return message;
  },
};

function createBaseCancelP2PResponse(): CancelP2PResponse {
  return { status: 0, error: undefined };
}

export const CancelP2PResponse = {
  encode(message: CancelP2PResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelP2PResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCancelP2PResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CancelP2PResponse>, I>>(base?: I): CancelP2PResponse {
    return CancelP2PResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CancelP2PResponse>, I>>(object: I): CancelP2PResponse {
    const message = createBaseCancelP2PResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseSetPINRequest(): SetPINRequest {
  return { pin: "" };
}

export const SetPINRequest = {
  encode(message: SetPINRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pin !== "") {
      writer.uint32(10).string(message.pin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPINRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPINRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pin = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SetPINRequest>, I>>(base?: I): SetPINRequest {
    return SetPINRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetPINRequest>, I>>(object: I): SetPINRequest {
    const message = createBaseSetPINRequest();
    message.pin = object.pin ?? "";
    return message;
  },
};

function createBaseSetPINResponse(): SetPINResponse {
  return { status: 0, error: undefined };
}

export const SetPINResponse = {
  encode(message: SetPINResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetPINResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetPINResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SetPINResponse>, I>>(base?: I): SetPINResponse {
    return SetPINResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetPINResponse>, I>>(object: I): SetPINResponse {
    const message = createBaseSetPINResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseResetPINRequest(): ResetPINRequest {
  return { oldPin: "", newPin: "" };
}

export const ResetPINRequest = {
  encode(message: ResetPINRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.oldPin !== "") {
      writer.uint32(10).string(message.oldPin);
    }
    if (message.newPin !== "") {
      writer.uint32(18).string(message.newPin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetPINRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPINRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.oldPin = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.newPin = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ResetPINRequest>, I>>(base?: I): ResetPINRequest {
    return ResetPINRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetPINRequest>, I>>(object: I): ResetPINRequest {
    const message = createBaseResetPINRequest();
    message.oldPin = object.oldPin ?? "";
    message.newPin = object.newPin ?? "";
    return message;
  },
};

function createBaseResetPINResponse(): ResetPINResponse {
  return { status: 0, error: undefined };
}

export const ResetPINResponse = {
  encode(message: ResetPINResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResetPINResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResetPINResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ResetPINResponse>, I>>(base?: I): ResetPINResponse {
    return ResetPINResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResetPINResponse>, I>>(object: I): ResetPINResponse {
    const message = createBaseResetPINResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseValidatePINRequest(): ValidatePINRequest {
  return { pin: "" };
}

export const ValidatePINRequest = {
  encode(message: ValidatePINRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pin !== "") {
      writer.uint32(10).string(message.pin);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidatePINRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidatePINRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pin = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ValidatePINRequest>, I>>(base?: I): ValidatePINRequest {
    return ValidatePINRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidatePINRequest>, I>>(object: I): ValidatePINRequest {
    const message = createBaseValidatePINRequest();
    message.pin = object.pin ?? "";
    return message;
  },
};

function createBaseValidatePINResponse(): ValidatePINResponse {
  return { status: 0, error: undefined };
}

export const ValidatePINResponse = {
  encode(message: ValidatePINResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValidatePINResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValidatePINResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ValidatePINResponse>, I>>(base?: I): ValidatePINResponse {
    return ValidatePINResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValidatePINResponse>, I>>(object: I): ValidatePINResponse {
    const message = createBaseValidatePINResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseRemovePINRequest(): RemovePINRequest {
  return {};
}

export const RemovePINRequest = {
  encode(_: RemovePINRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemovePINRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovePINRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemovePINRequest>, I>>(base?: I): RemovePINRequest {
    return RemovePINRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemovePINRequest>, I>>(_: I): RemovePINRequest {
    const message = createBaseRemovePINRequest();
    return message;
  },
};

function createBaseRemovePINResponse(): RemovePINResponse {
  return { status: 0, error: undefined };
}

export const RemovePINResponse = {
  encode(message: RemovePINResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemovePINResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovePINResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemovePINResponse>, I>>(base?: I): RemovePINResponse {
    return RemovePINResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemovePINResponse>, I>>(object: I): RemovePINResponse {
    const message = createBaseRemovePINResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseProvisionClosedLoopAccountUUEKRequest(): ProvisionClosedLoopAccountUUEKRequest {
  return { closedLoopAccountId: "" };
}

export const ProvisionClosedLoopAccountUUEKRequest = {
  encode(message: ProvisionClosedLoopAccountUUEKRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.closedLoopAccountId !== "") {
      writer.uint32(10).string(message.closedLoopAccountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionClosedLoopAccountUUEKRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionClosedLoopAccountUUEKRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.closedLoopAccountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProvisionClosedLoopAccountUUEKRequest>, I>>(
    base?: I,
  ): ProvisionClosedLoopAccountUUEKRequest {
    return ProvisionClosedLoopAccountUUEKRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionClosedLoopAccountUUEKRequest>, I>>(
    object: I,
  ): ProvisionClosedLoopAccountUUEKRequest {
    const message = createBaseProvisionClosedLoopAccountUUEKRequest();
    message.closedLoopAccountId = object.closedLoopAccountId ?? "";
    return message;
  },
};

function createBaseProvisionClosedLoopAccountUUEKResponse(): ProvisionClosedLoopAccountUUEKResponse {
  return { status: 0, error: undefined, closedLoopAccountUuek: "" };
}

export const ProvisionClosedLoopAccountUUEKResponse = {
  encode(message: ProvisionClosedLoopAccountUUEKResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.closedLoopAccountUuek !== "") {
      writer.uint32(26).string(message.closedLoopAccountUuek);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionClosedLoopAccountUUEKResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionClosedLoopAccountUUEKResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.closedLoopAccountUuek = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProvisionClosedLoopAccountUUEKResponse>, I>>(
    base?: I,
  ): ProvisionClosedLoopAccountUUEKResponse {
    return ProvisionClosedLoopAccountUUEKResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionClosedLoopAccountUUEKResponse>, I>>(
    object: I,
  ): ProvisionClosedLoopAccountUUEKResponse {
    const message = createBaseProvisionClosedLoopAccountUUEKResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.closedLoopAccountUuek = object.closedLoopAccountUuek ?? "";
    return message;
  },
};

function createBaseGetReceiptRequest(): GetReceiptRequest {
  return { accountId: "", loopTransactionId: "" };
}

export const GetReceiptRequest = {
  encode(message: GetReceiptRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== "") {
      writer.uint32(18).string(message.accountId);
    }
    if (message.loopTransactionId !== "") {
      writer.uint32(26).string(message.loopTransactionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReceiptRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReceiptRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loopTransactionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetReceiptRequest>, I>>(base?: I): GetReceiptRequest {
    return GetReceiptRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReceiptRequest>, I>>(object: I): GetReceiptRequest {
    const message = createBaseGetReceiptRequest();
    message.accountId = object.accountId ?? "";
    message.loopTransactionId = object.loopTransactionId ?? "";
    return message;
  },
};

function createBaseGetReceiptResponse(): GetReceiptResponse {
  return { status: 0, error: undefined, sourceData: new Uint8Array(0) };
}

export const GetReceiptResponse = {
  encode(message: GetReceiptResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.sourceData.length !== 0) {
      writer.uint32(26).bytes(message.sourceData);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetReceiptResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetReceiptResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sourceData = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetReceiptResponse>, I>>(base?: I): GetReceiptResponse {
    return GetReceiptResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetReceiptResponse>, I>>(object: I): GetReceiptResponse {
    const message = createBaseGetReceiptResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.sourceData = object.sourceData ?? new Uint8Array(0);
    return message;
  },
};

function createBaseLoadResponse(): LoadResponse {
  return { accountId: "", version: 0, availableBalance: "", pendingBalance: "", postedBalance: "", loadAmount: "" };
}

export const LoadResponse = {
  encode(message: LoadResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accountId !== "") {
      writer.uint32(10).string(message.accountId);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.availableBalance !== "") {
      writer.uint32(26).string(message.availableBalance);
    }
    if (message.pendingBalance !== "") {
      writer.uint32(34).string(message.pendingBalance);
    }
    if (message.postedBalance !== "") {
      writer.uint32(42).string(message.postedBalance);
    }
    if (message.loadAmount !== "") {
      writer.uint32(50).string(message.loadAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoadResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.accountId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.availableBalance = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pendingBalance = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.postedBalance = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.loadAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LoadResponse>, I>>(base?: I): LoadResponse {
    return LoadResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadResponse>, I>>(object: I): LoadResponse {
    const message = createBaseLoadResponse();
    message.accountId = object.accountId ?? "";
    message.version = object.version ?? 0;
    message.availableBalance = object.availableBalance ?? "";
    message.pendingBalance = object.pendingBalance ?? "";
    message.postedBalance = object.postedBalance ?? "";
    message.loadAmount = object.loadAmount ?? "";
    return message;
  },
};

function createBaseLoadFromGiftCardRequest(): LoadFromGiftCardRequest {
  return {};
}

export const LoadFromGiftCardRequest = {
  encode(_: LoadFromGiftCardRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoadFromGiftCardRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadFromGiftCardRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LoadFromGiftCardRequest>, I>>(base?: I): LoadFromGiftCardRequest {
    return LoadFromGiftCardRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadFromGiftCardRequest>, I>>(_: I): LoadFromGiftCardRequest {
    const message = createBaseLoadFromGiftCardRequest();
    return message;
  },
};

function createBaseLoadFromGiftCardResponse(): LoadFromGiftCardResponse {
  return { status: 0, error: undefined, loadResponse: undefined };
}

export const LoadFromGiftCardResponse = {
  encode(message: LoadFromGiftCardResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.loadResponse !== undefined) {
      LoadResponse.encode(message.loadResponse, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoadFromGiftCardResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadFromGiftCardResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loadResponse = LoadResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LoadFromGiftCardResponse>, I>>(base?: I): LoadFromGiftCardResponse {
    return LoadFromGiftCardResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadFromGiftCardResponse>, I>>(object: I): LoadFromGiftCardResponse {
    const message = createBaseLoadFromGiftCardResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.loadResponse = (object.loadResponse !== undefined && object.loadResponse !== null)
      ? LoadResponse.fromPartial(object.loadResponse)
      : undefined;
    return message;
  },
};

function createBaseLoadFromLinkedAccountRequest(): LoadFromLinkedAccountRequest {
  return { linkedAccountId: "", closedLoopAccountId: "", amount: "", stepUpData: undefined };
}

export const LoadFromLinkedAccountRequest = {
  encode(message: LoadFromLinkedAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkedAccountId !== "") {
      writer.uint32(10).string(message.linkedAccountId);
    }
    if (message.closedLoopAccountId !== "") {
      writer.uint32(18).string(message.closedLoopAccountId);
    }
    if (message.amount !== "") {
      writer.uint32(26).string(message.amount);
    }
    if (message.stepUpData !== undefined) {
      StepUpData.encode(message.stepUpData, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoadFromLinkedAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadFromLinkedAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkedAccountId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.closedLoopAccountId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.stepUpData = StepUpData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LoadFromLinkedAccountRequest>, I>>(base?: I): LoadFromLinkedAccountRequest {
    return LoadFromLinkedAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadFromLinkedAccountRequest>, I>>(object: I): LoadFromLinkedAccountRequest {
    const message = createBaseLoadFromLinkedAccountRequest();
    message.linkedAccountId = object.linkedAccountId ?? "";
    message.closedLoopAccountId = object.closedLoopAccountId ?? "";
    message.amount = object.amount ?? "";
    message.stepUpData = (object.stepUpData !== undefined && object.stepUpData !== null)
      ? StepUpData.fromPartial(object.stepUpData)
      : undefined;
    return message;
  },
};

function createBaseLoadFromLinkedAccountResponse(): LoadFromLinkedAccountResponse {
  return { status: 0, error: undefined, loadResponse: undefined };
}

export const LoadFromLinkedAccountResponse = {
  encode(message: LoadFromLinkedAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.loadResponse !== undefined) {
      LoadResponse.encode(message.loadResponse, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoadFromLinkedAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadFromLinkedAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loadResponse = LoadResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LoadFromLinkedAccountResponse>, I>>(base?: I): LoadFromLinkedAccountResponse {
    return LoadFromLinkedAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadFromLinkedAccountResponse>, I>>(
    object: I,
  ): LoadFromLinkedAccountResponse {
    const message = createBaseLoadFromLinkedAccountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.loadResponse = (object.loadResponse !== undefined && object.loadResponse !== null)
      ? LoadResponse.fromPartial(object.loadResponse)
      : undefined;
    return message;
  },
};

function createBaseLoadFromLinkingCodeRequest(): LoadFromLinkingCodeRequest {
  return { linkingCode: "", closedLoopAccountId: "" };
}

export const LoadFromLinkingCodeRequest = {
  encode(message: LoadFromLinkingCodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkingCode !== "") {
      writer.uint32(10).string(message.linkingCode);
    }
    if (message.closedLoopAccountId !== "") {
      writer.uint32(18).string(message.closedLoopAccountId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoadFromLinkingCodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadFromLinkingCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkingCode = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.closedLoopAccountId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LoadFromLinkingCodeRequest>, I>>(base?: I): LoadFromLinkingCodeRequest {
    return LoadFromLinkingCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadFromLinkingCodeRequest>, I>>(object: I): LoadFromLinkingCodeRequest {
    const message = createBaseLoadFromLinkingCodeRequest();
    message.linkingCode = object.linkingCode ?? "";
    message.closedLoopAccountId = object.closedLoopAccountId ?? "";
    return message;
  },
};

function createBaseLoadFromLinkingCodeResponse(): LoadFromLinkingCodeResponse {
  return { status: 0, error: undefined, loadResponse: undefined };
}

export const LoadFromLinkingCodeResponse = {
  encode(message: LoadFromLinkingCodeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.loadResponse !== undefined) {
      LoadResponse.encode(message.loadResponse, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LoadFromLinkingCodeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLoadFromLinkingCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.loadResponse = LoadResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LoadFromLinkingCodeResponse>, I>>(base?: I): LoadFromLinkingCodeResponse {
    return LoadFromLinkingCodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LoadFromLinkingCodeResponse>, I>>(object: I): LoadFromLinkingCodeResponse {
    const message = createBaseLoadFromLinkingCodeResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.loadResponse = (object.loadResponse !== undefined && object.loadResponse !== null)
      ? LoadResponse.fromPartial(object.loadResponse)
      : undefined;
    return message;
  },
};

function createBaseFetchUserDataFromBankLinkingCodeRequest(): FetchUserDataFromBankLinkingCodeRequest {
  return { linkingCode: "" };
}

export const FetchUserDataFromBankLinkingCodeRequest = {
  encode(message: FetchUserDataFromBankLinkingCodeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.linkingCode !== "") {
      writer.uint32(10).string(message.linkingCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchUserDataFromBankLinkingCodeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchUserDataFromBankLinkingCodeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.linkingCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FetchUserDataFromBankLinkingCodeRequest>, I>>(
    base?: I,
  ): FetchUserDataFromBankLinkingCodeRequest {
    return FetchUserDataFromBankLinkingCodeRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchUserDataFromBankLinkingCodeRequest>, I>>(
    object: I,
  ): FetchUserDataFromBankLinkingCodeRequest {
    const message = createBaseFetchUserDataFromBankLinkingCodeRequest();
    message.linkingCode = object.linkingCode ?? "";
    return message;
  },
};

function createBaseFetchUserDataFromBankLinkingCodeResponse(): FetchUserDataFromBankLinkingCodeResponse {
  return { status: 0, demographics: undefined, fromLinkKey: "" };
}

export const FetchUserDataFromBankLinkingCodeResponse = {
  encode(message: FetchUserDataFromBankLinkingCodeResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.demographics !== undefined) {
      UserDemographic.encode(message.demographics, writer.uint32(18).fork()).ldelim();
    }
    if (message.fromLinkKey !== "") {
      writer.uint32(26).string(message.fromLinkKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchUserDataFromBankLinkingCodeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchUserDataFromBankLinkingCodeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.demographics = UserDemographic.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fromLinkKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<FetchUserDataFromBankLinkingCodeResponse>, I>>(
    base?: I,
  ): FetchUserDataFromBankLinkingCodeResponse {
    return FetchUserDataFromBankLinkingCodeResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchUserDataFromBankLinkingCodeResponse>, I>>(
    object: I,
  ): FetchUserDataFromBankLinkingCodeResponse {
    const message = createBaseFetchUserDataFromBankLinkingCodeResponse();
    message.status = object.status ?? 0;
    message.demographics = (object.demographics !== undefined && object.demographics !== null)
      ? UserDemographic.fromPartial(object.demographics)
      : undefined;
    message.fromLinkKey = object.fromLinkKey ?? "";
    return message;
  },
};

export interface MicaShopService {
  /** Most mica services require the caller to authenticate themselves. This operation allows the caller to do so. */
  authenticate(request: DeepPartial<AuthenticateRequest>, metadata?: grpc.Metadata): Promise<AuthenticateResponse>;
  /** Create a user and register the user in mica (either by demographics or by linking code). */
  createUserByDemographic(
    request: DeepPartial<CreateUserByDemographicRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateUserResponse>;
  fetchUserDataFromBankLinkingCode(
    request: DeepPartial<FetchUserDataFromBankLinkingCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchUserDataFromBankLinkingCodeResponse>;
  /** Retrieve a user based on it's key. */
  getUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse>;
  /** Update a user based on it's key. */
  updateUser(request: DeepPartial<UpdateUserRequest>, metadata?: grpc.Metadata): Promise<UpdateUserResponse>;
  /** Remove a user */
  removeUser(request: DeepPartial<RemoveUserRequest>, metadata?: grpc.Metadata): Promise<RemoveUserResponse>;
  forgotUsername(
    request: DeepPartial<ForgotUsernameRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ForgotUsernameResponse>;
  resetPassword(request: DeepPartial<ResetPasswordRequest>, metadata?: grpc.Metadata): Promise<ResetPasswordResponse>;
  changePassword(
    request: DeepPartial<ChangePasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ChangePasswordResponse>;
  updateUuek(request: DeepPartial<UpdateUUEKRequest>, metadata?: grpc.Metadata): Promise<UpdateUUEKResponse>;
  removeUuek(request: DeepPartial<RemoveUUEKRequest>, metadata?: grpc.Metadata): Promise<RemoveUUEKResponse>;
  /** Search for UUEKs that the Partner has created. */
  searchUuek(request: DeepPartial<SearchUUEKRequest>, metadata?: grpc.Metadata): Promise<SearchUUEKResponse>;
  /** Like GetTransaction, can be used to retrieve Transactions based on the criteria in the request. */
  searchTransaction(
    request: DeepPartial<SearchTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchTransactionResponse>;
  obtainValue(request: DeepPartial<ObtainValueRequest>, metadata?: grpc.Metadata): Promise<ObtainValueResponse>;
  returnValue(request: DeepPartial<ReturnValueRequest>, metadata?: grpc.Metadata): Promise<ReturnValueResponse>;
  initializeWidget(
    request: DeepPartial<InitializeWidgetRequest>,
    metadata?: grpc.Metadata,
  ): Promise<InitializeWidgetResponse>;
  exchangeSessionKey(
    request: DeepPartial<ExchangeSessionKeyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ExchangeSessionKeyResponse>;
  /** An operation to ping the server to ensure it's up and running and that the connection is good. */
  ping(request: DeepPartial<PingRequest>, metadata?: grpc.Metadata): Promise<PingResponse>;
  linkInstrumentWithCode(
    request: DeepPartial<LinkInstrumentWithCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LinkInstrumentWithCodeResponse>;
  provisionUuekWithLinkingCode(
    request: DeepPartial<ProvisionUUEKWithLinkingCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProvisionUUEKWithLinkingCodeResponse>;
  searchLinkedAccounts(
    request: DeepPartial<SearchLinkedAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchLinkedAccountsResponse>;
  provisionLinkedAccountUuek(
    request: DeepPartial<ProvisionLinkedAccountUUEKRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProvisionLinkedAccountUUEKResponse>;
  removeLinkedAccount(
    request: DeepPartial<RemoveLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveLinkedAccountResponse>;
  updateLinkedAccount(
    request: DeepPartial<UpdateLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateLinkedAccountResponse>;
  searchClosedLoopAccounts(
    request: DeepPartial<SearchClosedLoopAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchClosedLoopAccountsResponse>;
  searchClosedLoopAccountTransaction(
    request: DeepPartial<SearchClosedLoopAccountTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchClosedLoopAccountTransactionResponse>;
  initiateP2P(request: DeepPartial<InitiateP2PRequest>, metadata?: grpc.Metadata): Promise<InitiateP2PResponse>;
  searchP2PTransactions(
    request: DeepPartial<SearchP2PTransactionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchP2PTransactionsResponse>;
  acceptP2P(request: DeepPartial<AcceptP2PRequest>, metadata?: grpc.Metadata): Promise<AcceptP2PResponse>;
  cancelP2P(request: DeepPartial<CancelP2PRequest>, metadata?: grpc.Metadata): Promise<CancelP2PResponse>;
  getReceipt(request: DeepPartial<GetReceiptRequest>, metadata?: grpc.Metadata): Promise<GetReceiptResponse>;
  setPin(request: DeepPartial<SetPINRequest>, metadata?: grpc.Metadata): Promise<SetPINResponse>;
  resetPin(request: DeepPartial<ResetPINRequest>, metadata?: grpc.Metadata): Promise<ResetPINResponse>;
  validatePin(request: DeepPartial<ValidatePINRequest>, metadata?: grpc.Metadata): Promise<ValidatePINResponse>;
  removePin(request: DeepPartial<RemovePINRequest>, metadata?: grpc.Metadata): Promise<RemovePINResponse>;
  provisionClosedLoopAccountUuek(
    request: DeepPartial<ProvisionClosedLoopAccountUUEKRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProvisionClosedLoopAccountUUEKResponse>;
  loadFromGiftCard(
    request: DeepPartial<LoadFromGiftCardRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LoadFromGiftCardResponse>;
  loadFromLinkedAccount(
    request: DeepPartial<LoadFromLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LoadFromLinkedAccountResponse>;
  loadFromLinkingCode(
    request: DeepPartial<LoadFromLinkingCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LoadFromLinkingCodeResponse>;
}

export class MicaShopServiceClientImpl implements MicaShopService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.authenticate = this.authenticate.bind(this);
    this.createUserByDemographic = this.createUserByDemographic.bind(this);
    this.fetchUserDataFromBankLinkingCode = this.fetchUserDataFromBankLinkingCode.bind(this);
    this.getUser = this.getUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.forgotUsername = this.forgotUsername.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.updateUuek = this.updateUuek.bind(this);
    this.removeUuek = this.removeUuek.bind(this);
    this.searchUuek = this.searchUuek.bind(this);
    this.searchTransaction = this.searchTransaction.bind(this);
    this.obtainValue = this.obtainValue.bind(this);
    this.returnValue = this.returnValue.bind(this);
    this.initializeWidget = this.initializeWidget.bind(this);
    this.exchangeSessionKey = this.exchangeSessionKey.bind(this);
    this.ping = this.ping.bind(this);
    this.linkInstrumentWithCode = this.linkInstrumentWithCode.bind(this);
    this.provisionUuekWithLinkingCode = this.provisionUuekWithLinkingCode.bind(this);
    this.searchLinkedAccounts = this.searchLinkedAccounts.bind(this);
    this.provisionLinkedAccountUuek = this.provisionLinkedAccountUuek.bind(this);
    this.removeLinkedAccount = this.removeLinkedAccount.bind(this);
    this.updateLinkedAccount = this.updateLinkedAccount.bind(this);
    this.searchClosedLoopAccounts = this.searchClosedLoopAccounts.bind(this);
    this.searchClosedLoopAccountTransaction = this.searchClosedLoopAccountTransaction.bind(this);
    this.initiateP2P = this.initiateP2P.bind(this);
    this.searchP2PTransactions = this.searchP2PTransactions.bind(this);
    this.acceptP2P = this.acceptP2P.bind(this);
    this.cancelP2P = this.cancelP2P.bind(this);
    this.getReceipt = this.getReceipt.bind(this);
    this.setPin = this.setPin.bind(this);
    this.resetPin = this.resetPin.bind(this);
    this.validatePin = this.validatePin.bind(this);
    this.removePin = this.removePin.bind(this);
    this.provisionClosedLoopAccountUuek = this.provisionClosedLoopAccountUuek.bind(this);
    this.loadFromGiftCard = this.loadFromGiftCard.bind(this);
    this.loadFromLinkedAccount = this.loadFromLinkedAccount.bind(this);
    this.loadFromLinkingCode = this.loadFromLinkingCode.bind(this);
  }

  authenticate(request: DeepPartial<AuthenticateRequest>, metadata?: grpc.Metadata): Promise<AuthenticateResponse> {
    return this.rpc.unary(MicaShopServiceAuthenticateDesc, AuthenticateRequest.fromPartial(request), metadata);
  }

  createUserByDemographic(
    request: DeepPartial<CreateUserByDemographicRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateUserResponse> {
    return this.rpc.unary(
      MicaShopServiceCreateUserByDemographicDesc,
      CreateUserByDemographicRequest.fromPartial(request),
      metadata,
    );
  }

  fetchUserDataFromBankLinkingCode(
    request: DeepPartial<FetchUserDataFromBankLinkingCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchUserDataFromBankLinkingCodeResponse> {
    return this.rpc.unary(
      MicaShopServiceFetchUserDataFromBankLinkingCodeDesc,
      FetchUserDataFromBankLinkingCodeRequest.fromPartial(request),
      metadata,
    );
  }

  getUser(request: DeepPartial<GetUserRequest>, metadata?: grpc.Metadata): Promise<GetUserResponse> {
    return this.rpc.unary(MicaShopServiceGetUserDesc, GetUserRequest.fromPartial(request), metadata);
  }

  updateUser(request: DeepPartial<UpdateUserRequest>, metadata?: grpc.Metadata): Promise<UpdateUserResponse> {
    return this.rpc.unary(MicaShopServiceUpdateUserDesc, UpdateUserRequest.fromPartial(request), metadata);
  }

  removeUser(request: DeepPartial<RemoveUserRequest>, metadata?: grpc.Metadata): Promise<RemoveUserResponse> {
    return this.rpc.unary(MicaShopServiceRemoveUserDesc, RemoveUserRequest.fromPartial(request), metadata);
  }

  forgotUsername(
    request: DeepPartial<ForgotUsernameRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ForgotUsernameResponse> {
    return this.rpc.unary(MicaShopServiceForgotUsernameDesc, ForgotUsernameRequest.fromPartial(request), metadata);
  }

  resetPassword(request: DeepPartial<ResetPasswordRequest>, metadata?: grpc.Metadata): Promise<ResetPasswordResponse> {
    return this.rpc.unary(MicaShopServiceResetPasswordDesc, ResetPasswordRequest.fromPartial(request), metadata);
  }

  changePassword(
    request: DeepPartial<ChangePasswordRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ChangePasswordResponse> {
    return this.rpc.unary(MicaShopServiceChangePasswordDesc, ChangePasswordRequest.fromPartial(request), metadata);
  }

  updateUuek(request: DeepPartial<UpdateUUEKRequest>, metadata?: grpc.Metadata): Promise<UpdateUUEKResponse> {
    return this.rpc.unary(MicaShopServiceUpdateUUEKDesc, UpdateUUEKRequest.fromPartial(request), metadata);
  }

  removeUuek(request: DeepPartial<RemoveUUEKRequest>, metadata?: grpc.Metadata): Promise<RemoveUUEKResponse> {
    return this.rpc.unary(MicaShopServiceRemoveUUEKDesc, RemoveUUEKRequest.fromPartial(request), metadata);
  }

  searchUuek(request: DeepPartial<SearchUUEKRequest>, metadata?: grpc.Metadata): Promise<SearchUUEKResponse> {
    return this.rpc.unary(MicaShopServiceSearchUUEKDesc, SearchUUEKRequest.fromPartial(request), metadata);
  }

  searchTransaction(
    request: DeepPartial<SearchTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchTransactionResponse> {
    return this.rpc.unary(
      MicaShopServiceSearchTransactionDesc,
      SearchTransactionRequest.fromPartial(request),
      metadata,
    );
  }

  obtainValue(request: DeepPartial<ObtainValueRequest>, metadata?: grpc.Metadata): Promise<ObtainValueResponse> {
    return this.rpc.unary(MicaShopServiceObtainValueDesc, ObtainValueRequest.fromPartial(request), metadata);
  }

  returnValue(request: DeepPartial<ReturnValueRequest>, metadata?: grpc.Metadata): Promise<ReturnValueResponse> {
    return this.rpc.unary(MicaShopServiceReturnValueDesc, ReturnValueRequest.fromPartial(request), metadata);
  }

  initializeWidget(
    request: DeepPartial<InitializeWidgetRequest>,
    metadata?: grpc.Metadata,
  ): Promise<InitializeWidgetResponse> {
    return this.rpc.unary(MicaShopServiceInitializeWidgetDesc, InitializeWidgetRequest.fromPartial(request), metadata);
  }

  exchangeSessionKey(
    request: DeepPartial<ExchangeSessionKeyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ExchangeSessionKeyResponse> {
    return this.rpc.unary(
      MicaShopServiceExchangeSessionKeyDesc,
      ExchangeSessionKeyRequest.fromPartial(request),
      metadata,
    );
  }

  ping(request: DeepPartial<PingRequest>, metadata?: grpc.Metadata): Promise<PingResponse> {
    return this.rpc.unary(MicaShopServicePingDesc, PingRequest.fromPartial(request), metadata);
  }

  linkInstrumentWithCode(
    request: DeepPartial<LinkInstrumentWithCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LinkInstrumentWithCodeResponse> {
    return this.rpc.unary(
      MicaShopServiceLinkInstrumentWithCodeDesc,
      LinkInstrumentWithCodeRequest.fromPartial(request),
      metadata,
    );
  }

  provisionUuekWithLinkingCode(
    request: DeepPartial<ProvisionUUEKWithLinkingCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProvisionUUEKWithLinkingCodeResponse> {
    return this.rpc.unary(
      MicaShopServiceProvisionUUEKWithLinkingCodeDesc,
      ProvisionUUEKWithLinkingCodeRequest.fromPartial(request),
      metadata,
    );
  }

  searchLinkedAccounts(
    request: DeepPartial<SearchLinkedAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchLinkedAccountsResponse> {
    return this.rpc.unary(
      MicaShopServiceSearchLinkedAccountsDesc,
      SearchLinkedAccountsRequest.fromPartial(request),
      metadata,
    );
  }

  provisionLinkedAccountUuek(
    request: DeepPartial<ProvisionLinkedAccountUUEKRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProvisionLinkedAccountUUEKResponse> {
    return this.rpc.unary(
      MicaShopServiceProvisionLinkedAccountUUEKDesc,
      ProvisionLinkedAccountUUEKRequest.fromPartial(request),
      metadata,
    );
  }

  removeLinkedAccount(
    request: DeepPartial<RemoveLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveLinkedAccountResponse> {
    return this.rpc.unary(
      MicaShopServiceRemoveLinkedAccountDesc,
      RemoveLinkedAccountRequest.fromPartial(request),
      metadata,
    );
  }

  updateLinkedAccount(
    request: DeepPartial<UpdateLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateLinkedAccountResponse> {
    return this.rpc.unary(
      MicaShopServiceUpdateLinkedAccountDesc,
      UpdateLinkedAccountRequest.fromPartial(request),
      metadata,
    );
  }

  searchClosedLoopAccounts(
    request: DeepPartial<SearchClosedLoopAccountsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchClosedLoopAccountsResponse> {
    return this.rpc.unary(
      MicaShopServiceSearchClosedLoopAccountsDesc,
      SearchClosedLoopAccountsRequest.fromPartial(request),
      metadata,
    );
  }

  searchClosedLoopAccountTransaction(
    request: DeepPartial<SearchClosedLoopAccountTransactionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchClosedLoopAccountTransactionResponse> {
    return this.rpc.unary(
      MicaShopServiceSearchClosedLoopAccountTransactionDesc,
      SearchClosedLoopAccountTransactionRequest.fromPartial(request),
      metadata,
    );
  }

  initiateP2P(request: DeepPartial<InitiateP2PRequest>, metadata?: grpc.Metadata): Promise<InitiateP2PResponse> {
    return this.rpc.unary(MicaShopServiceInitiateP2PDesc, InitiateP2PRequest.fromPartial(request), metadata);
  }

  searchP2PTransactions(
    request: DeepPartial<SearchP2PTransactionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchP2PTransactionsResponse> {
    return this.rpc.unary(
      MicaShopServiceSearchP2PTransactionsDesc,
      SearchP2PTransactionsRequest.fromPartial(request),
      metadata,
    );
  }

  acceptP2P(request: DeepPartial<AcceptP2PRequest>, metadata?: grpc.Metadata): Promise<AcceptP2PResponse> {
    return this.rpc.unary(MicaShopServiceAcceptP2PDesc, AcceptP2PRequest.fromPartial(request), metadata);
  }

  cancelP2P(request: DeepPartial<CancelP2PRequest>, metadata?: grpc.Metadata): Promise<CancelP2PResponse> {
    return this.rpc.unary(MicaShopServiceCancelP2PDesc, CancelP2PRequest.fromPartial(request), metadata);
  }

  getReceipt(request: DeepPartial<GetReceiptRequest>, metadata?: grpc.Metadata): Promise<GetReceiptResponse> {
    return this.rpc.unary(MicaShopServiceGetReceiptDesc, GetReceiptRequest.fromPartial(request), metadata);
  }

  setPin(request: DeepPartial<SetPINRequest>, metadata?: grpc.Metadata): Promise<SetPINResponse> {
    return this.rpc.unary(MicaShopServiceSetPINDesc, SetPINRequest.fromPartial(request), metadata);
  }

  resetPin(request: DeepPartial<ResetPINRequest>, metadata?: grpc.Metadata): Promise<ResetPINResponse> {
    return this.rpc.unary(MicaShopServiceResetPINDesc, ResetPINRequest.fromPartial(request), metadata);
  }

  validatePin(request: DeepPartial<ValidatePINRequest>, metadata?: grpc.Metadata): Promise<ValidatePINResponse> {
    return this.rpc.unary(MicaShopServiceValidatePINDesc, ValidatePINRequest.fromPartial(request), metadata);
  }

  removePin(request: DeepPartial<RemovePINRequest>, metadata?: grpc.Metadata): Promise<RemovePINResponse> {
    return this.rpc.unary(MicaShopServiceRemovePINDesc, RemovePINRequest.fromPartial(request), metadata);
  }

  provisionClosedLoopAccountUuek(
    request: DeepPartial<ProvisionClosedLoopAccountUUEKRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ProvisionClosedLoopAccountUUEKResponse> {
    return this.rpc.unary(
      MicaShopServiceProvisionClosedLoopAccountUUEKDesc,
      ProvisionClosedLoopAccountUUEKRequest.fromPartial(request),
      metadata,
    );
  }

  loadFromGiftCard(
    request: DeepPartial<LoadFromGiftCardRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LoadFromGiftCardResponse> {
    return this.rpc.unary(MicaShopServiceLoadFromGiftCardDesc, LoadFromGiftCardRequest.fromPartial(request), metadata);
  }

  loadFromLinkedAccount(
    request: DeepPartial<LoadFromLinkedAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LoadFromLinkedAccountResponse> {
    return this.rpc.unary(
      MicaShopServiceLoadFromLinkedAccountDesc,
      LoadFromLinkedAccountRequest.fromPartial(request),
      metadata,
    );
  }

  loadFromLinkingCode(
    request: DeepPartial<LoadFromLinkingCodeRequest>,
    metadata?: grpc.Metadata,
  ): Promise<LoadFromLinkingCodeResponse> {
    return this.rpc.unary(
      MicaShopServiceLoadFromLinkingCodeDesc,
      LoadFromLinkingCodeRequest.fromPartial(request),
      metadata,
    );
  }
}

export const MicaShopServiceDesc = { serviceName: "demo.micashop.service.v1.MicaShopService" };

export const MicaShopServiceAuthenticateDesc: UnaryMethodDefinitionish = {
  methodName: "Authenticate",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AuthenticateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AuthenticateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceCreateUserByDemographicDesc: UnaryMethodDefinitionish = {
  methodName: "CreateUserByDemographic",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateUserByDemographicRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceFetchUserDataFromBankLinkingCodeDesc: UnaryMethodDefinitionish = {
  methodName: "FetchUserDataFromBankLinkingCode",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchUserDataFromBankLinkingCodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchUserDataFromBankLinkingCodeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceGetUserDesc: UnaryMethodDefinitionish = {
  methodName: "GetUser",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceUpdateUserDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateUser",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceRemoveUserDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveUser",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceForgotUsernameDesc: UnaryMethodDefinitionish = {
  methodName: "ForgotUsername",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ForgotUsernameRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ForgotUsernameResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceResetPasswordDesc: UnaryMethodDefinitionish = {
  methodName: "ResetPassword",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetPasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ResetPasswordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceChangePasswordDesc: UnaryMethodDefinitionish = {
  methodName: "ChangePassword",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ChangePasswordRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ChangePasswordResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceUpdateUUEKDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateUUEK",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateUUEKRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateUUEKResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceRemoveUUEKDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveUUEK",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveUUEKRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveUUEKResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceSearchUUEKDesc: UnaryMethodDefinitionish = {
  methodName: "SearchUUEK",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchUUEKRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchUUEKResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceSearchTransactionDesc: UnaryMethodDefinitionish = {
  methodName: "SearchTransaction",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchTransactionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchTransactionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceObtainValueDesc: UnaryMethodDefinitionish = {
  methodName: "ObtainValue",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ObtainValueRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ObtainValueResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceReturnValueDesc: UnaryMethodDefinitionish = {
  methodName: "ReturnValue",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ReturnValueRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ReturnValueResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceInitializeWidgetDesc: UnaryMethodDefinitionish = {
  methodName: "InitializeWidget",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return InitializeWidgetRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = InitializeWidgetResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceExchangeSessionKeyDesc: UnaryMethodDefinitionish = {
  methodName: "ExchangeSessionKey",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ExchangeSessionKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ExchangeSessionKeyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServicePingDesc: UnaryMethodDefinitionish = {
  methodName: "Ping",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceLinkInstrumentWithCodeDesc: UnaryMethodDefinitionish = {
  methodName: "LinkInstrumentWithCode",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LinkInstrumentWithCodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = LinkInstrumentWithCodeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceProvisionUUEKWithLinkingCodeDesc: UnaryMethodDefinitionish = {
  methodName: "ProvisionUUEKWithLinkingCode",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ProvisionUUEKWithLinkingCodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ProvisionUUEKWithLinkingCodeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceSearchLinkedAccountsDesc: UnaryMethodDefinitionish = {
  methodName: "SearchLinkedAccounts",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchLinkedAccountsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchLinkedAccountsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceProvisionLinkedAccountUUEKDesc: UnaryMethodDefinitionish = {
  methodName: "ProvisionLinkedAccountUUEK",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ProvisionLinkedAccountUUEKRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ProvisionLinkedAccountUUEKResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceRemoveLinkedAccountDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveLinkedAccount",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveLinkedAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveLinkedAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceUpdateLinkedAccountDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateLinkedAccount",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateLinkedAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateLinkedAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceSearchClosedLoopAccountsDesc: UnaryMethodDefinitionish = {
  methodName: "SearchClosedLoopAccounts",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchClosedLoopAccountsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchClosedLoopAccountsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceSearchClosedLoopAccountTransactionDesc: UnaryMethodDefinitionish = {
  methodName: "SearchClosedLoopAccountTransaction",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchClosedLoopAccountTransactionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchClosedLoopAccountTransactionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceInitiateP2PDesc: UnaryMethodDefinitionish = {
  methodName: "InitiateP2P",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return InitiateP2PRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = InitiateP2PResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceSearchP2PTransactionsDesc: UnaryMethodDefinitionish = {
  methodName: "SearchP2PTransactions",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchP2PTransactionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchP2PTransactionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceAcceptP2PDesc: UnaryMethodDefinitionish = {
  methodName: "AcceptP2P",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return AcceptP2PRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = AcceptP2PResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceCancelP2PDesc: UnaryMethodDefinitionish = {
  methodName: "CancelP2P",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CancelP2PRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CancelP2PResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceGetReceiptDesc: UnaryMethodDefinitionish = {
  methodName: "GetReceipt",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetReceiptRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetReceiptResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceSetPINDesc: UnaryMethodDefinitionish = {
  methodName: "SetPIN",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SetPINRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SetPINResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceResetPINDesc: UnaryMethodDefinitionish = {
  methodName: "ResetPIN",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ResetPINRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ResetPINResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceValidatePINDesc: UnaryMethodDefinitionish = {
  methodName: "ValidatePIN",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ValidatePINRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ValidatePINResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceRemovePINDesc: UnaryMethodDefinitionish = {
  methodName: "RemovePIN",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemovePINRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemovePINResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceProvisionClosedLoopAccountUUEKDesc: UnaryMethodDefinitionish = {
  methodName: "ProvisionClosedLoopAccountUUEK",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ProvisionClosedLoopAccountUUEKRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ProvisionClosedLoopAccountUUEKResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceLoadFromGiftCardDesc: UnaryMethodDefinitionish = {
  methodName: "LoadFromGiftCard",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LoadFromGiftCardRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = LoadFromGiftCardResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceLoadFromLinkedAccountDesc: UnaryMethodDefinitionish = {
  methodName: "LoadFromLinkedAccount",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LoadFromLinkedAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = LoadFromLinkedAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const MicaShopServiceLoadFromLinkingCodeDesc: UnaryMethodDefinitionish = {
  methodName: "LoadFromLinkingCode",
  service: MicaShopServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return LoadFromLinkingCodeRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = LoadFromLinkingCodeResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
