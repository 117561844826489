type SyncStorage = {
  setItem: (key: string, value: any) => void;
  getItem: (key: string) => any;
  removeItem: (key: string) => void;
};

type AsyncStorage = {
  setItem: (key: string, value: any) => Promise<void>;
  getItem: (key: string) => Promise<any>;
  removeItem: (key: string) => Promise<void>;
};

type Storage = {
  localStorage: SyncStorage | AsyncStorage;
};

export const storage: Storage = {
  localStorage: {
    setItem: async (key: string, value: any) =>
      console.log(
        `unable to set localStorage item with key ${key} and value ${value} as the global localStorage object was not initialized `
      ),
    getItem: async (key: string) => {
      console.log(
        `unable to get localStorage item with key ${key} as the global localStorage object was not initialized `
      );
      return '' as any;
    },
    removeItem: async (key: string) =>
      console.log(
        `unable to remove localStorage item with key ${key} as the global localStorage object was not initialized `
      ),
  },
};

export const initLocalStorage = (
  localStorage: (typeof storage)['localStorage'] | SyncStorage
) => (storage.localStorage = localStorage);
