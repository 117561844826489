import { always } from 'ramda';
import { passwordLengthParser } from './shared';

export const signupErrorMapping: Record<string, (error: string) => string> = {
  'username unavailable': always(
    'A user already exists matching some of the information provided.  If you started a signup recently but did not complete it, please wait 5 minutes and try again. Otherwise, try using the "Forgot Password" option'
  ),
  'invalid CreateUserRequest': passwordLengthParser,
  'invalid FullEnrollmentRequest': passwordLengthParser,
};
