import React, { useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';
import name from 'corner-store-shared/src/assets/CornerStoreName.png';
import symbol from 'corner-store-shared/src/assets/CornerStoreSymbol.png';
import styles from './LaunchScreen.module.scss';
import { useInitShoppingCart } from 'corner-store-shared/src/hooks/shoppingCart/useInitShoppingCart';

/**
 A Component
 */
const LaunchScreen = () => {
  const showLaunchScreen = useBoolean(true);

  useInitShoppingCart();

  useEffect(() => {
    setTimeout(() => {
      showLaunchScreen.setFalse();
    }, 5000);
  });

  return showLaunchScreen.value ? (
    <div className={styles.container}>
      <img src={symbol} className={styles.symbol} />
      <img src={name} className={styles.name} />
      <div className={styles.tagline}>Your local, online and in-store</div>
    </div>
  ) : null;
};

export default LaunchScreen;
