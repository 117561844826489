import styles from './DetailedOptionButton.module.css';
import cx from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
type DetailedOptionButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  label: string;
  detail: string;
  onClick: () => void;
  showChevron?: boolean;
};

/** A button component comprising a label and 'detail' subtext */
const DetailedOptionButton = ({
  className,
  label,
  detail,
  onClick,
  showChevron,
  ...rest
}: DetailedOptionButtonProps) => (
  <button
    onClick={onClick}
    {...rest}
    className={cx(styles.detailedOptionButton, className)}>
    <div>
      <div className={styles.label}>{label}</div>
      <div className={styles.detail}>{detail}</div>
    </div>
    {showChevron && <FaChevronRight size={20} style={{ marginLeft: 10 }} />}
  </button>
);

export default DetailedOptionButton;
