import { PropsWithChildren } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const keyFields = ['sys', ['id']];

const httpLink = createHttpLink({
  uri: 'https://graphql.contentful.com/content/v1/spaces/2kh6p7m0mytd/environments/master',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${'OzR9zpveJ9GVZosyZ66lrJ1k3cNTKrnC7psTrktjxcs'}`,
    },
  };
});

const client = new ApolloClient({
  connectToDevTools: true,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Category: { keyFields },
      Discount: { keyFields },
      Product: {
        keyFields,
        fields: {
          discount: {
            merge: (existing, incoming) => incoming ?? existing,
          },
        },
      },
      CategoryLinkingCollections: {
        keyFields: false,
        merge: (existing, incoming) => incoming,
      },
      Sys: {
        keyFields: false,
      },
    },
  }),
});

const Provider = ({ children }: PropsWithChildren<object>) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default Provider;
