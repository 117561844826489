import { atom } from 'recoil';

export const authState = atom({
  key: 'authState',
  default: {
    isAuthenticated: false,
    token: undefined as string | undefined,
    user: '',
    showAuthScreen: false,
  },
});
