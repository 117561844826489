import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMemo } from 'react';
import { makeQueryConfig } from '../../../utils/reactQuery/reactQuery';

const tokenIsExpiredState = atom({
  key: 'tokenIsExpiredState',
  default: false,
});

export const useTokenExpiryHandler = () => {
  const setTokenIsExpired = useSetRecoilState(tokenIsExpiredState);

  const queryClientConfig = useMemo(
    () =>
      makeQueryConfig((error: any) => {
        if (error.toString && error.toString().includes('token is expired')) {
          setTokenIsExpired(true);
          return true; // error is handled
        }
        return false;
      }),
    []
  );

  return queryClientConfig;
};

export const useTokenIsExpired = () => useRecoilValue(tokenIsExpiredState);

export const useSetTokenRefreshed = () => {
  const setTokenIsExpired = useSetRecoilState(tokenIsExpiredState);

  return () => setTokenIsExpired(false);
};
