import { useMutation } from 'react-query';

import { useMicaShopClient } from '../useMicaShopClient';

/**
 * A hook for creating a user
 */
export const useCreateUser = () => {
  const client = useMicaShopClient();
  return useMutation(client.createUserByDemographic);
};
