import { useMutation } from 'react-query';
import { useMicaShopClient } from '../useMicaShopClient';
import { Realm } from 'mica-proto/src/micashared/common/enums/realm/v1/realm';
import { toast } from 'react-toastify';

type Args = {
  email?: string;
  username?: string;
};

/**
 * A hook for changing the user's password
 */
export const useResetPassword = () => {
  const client = useMicaShopClient();

  return useMutation(
    ({ email, username }: Args) =>
      client.resetPassword(
        email
          ? { email, realm: Realm.REALM_USER }
          : { username, realm: Realm.REALM_USER }
      ),
    {
      onError: () => {
        toast.error(
          'Unable to find matching user, are you sure you registered?'
        );
      },
    }
  );
};
