import React, { ReactNode, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TokenIsExpiredModal } from 'mica-ui-kit/src/components/auth/TokenIsExpiredModal/TokenIsExpiredModal';
import { useTokenExpiryHandler } from 'mica-ui-kit/src/hooks/auth/useTokenExpiryHandler/useTokenExpiryHandler';
import SigninForm from '../../auth/SignInOrUp/Signin/SigninForm';
import { MicaShopClientContext } from 'corner-store-shared/src/hooks/useMicaShopClient';
import { createClient } from 'corner-store-shared/src/client/client';
import { pathFromEnvOrOrigin } from 'mica-utilities';

interface Props {
  children: ReactNode;
}

export const MicaShopClientProvider = ({ children }: Props) => {
  const queryClientConfig = useTokenExpiryHandler();

  const [queryClient, client] = useMemo(
    () => [
      new QueryClient(queryClientConfig),
      createClient(pathFromEnvOrOrigin(import.meta.env.VITE_MICA_SHOP_HOST!)),
    ],
    [queryClientConfig]
  );

  return (
    <QueryClientProvider client={queryClient}>
      <MicaShopClientContext.Provider value={client}>
        {children}
        <TokenIsExpiredModal>
          <SigninForm />
        </TokenIsExpiredModal>
      </MicaShopClientContext.Provider>
    </QueryClientProvider>
  );
};
