import DetailedOptionButton from '../../../layout/DetailedOptionButton/DetailedOptionButton';
import React from 'react';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';

type SignupOptionsProps = {
  onToggleSignin: () => void;
  onSelectBankSignup: () => void;
  onSelectClassicSignup: () => void;
};

/** The modal screen that gives the user the option to choose between
 * classic signup and bank signup (linking code */
const SignupOptions = ({
  onToggleSignin,
  onSelectBankSignup,
  onSelectClassicSignup,
}: SignupOptionsProps) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 10,
    }}>
    <DetailedOptionButton
      label={'Bank Signup'}
      detail={'Quick sign up using a 1-time code from a participating bank'}
      onClick={onSelectBankSignup}
      style={{ marginBottom: 10 }}
    />
    <DetailedOptionButton
      label={'Classic Signup'}
      detail={
        'Sign up the old-fashioned way, by supplying your contact details'
      }
      onClick={onSelectClassicSignup}
    />
    <div className="mt-20 mb-10">Already a user?</div>
    <Button type={'button'} className="mb-20" onClick={onToggleSignin}>
      Sign In
    </Button>
  </div>
);

export default SignupOptions;
