import { TextInput } from 'mica-ui-kit/src/components/inputs/text/TextInput/TextInput';
import { useState } from 'react';
import { FetchUserDataFromBankLinkingCodeResponse } from 'micareference-proto/src/demo/micashop/service/v1/micashop_service';
import { Button } from '../../../Button/Button';
import { useCreateUserByDemographic } from 'corner-store-shared/src/hooks/user/useCreateUserByDemographic';
import { useAuth } from 'corner-store-shared/src/hooks/useAuth';
import { toast } from 'react-toastify';

type BankUsernamePasswordProps = {
  fetchUserDataResponse: FetchUserDataFromBankLinkingCodeResponse;
};

/** The section of Bank-code signup where the user provides username/password and
 * the signup actually fires  */
const BankUsernamePassword = ({
  fetchUserDataResponse,
}: BankUsernamePasswordProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { mutate: createUser, isLoading } = useCreateUserByDemographic();

  const { login, loading } = useAuth();

  const handleSignup = () =>
    createUser(
      {
        userDemographic: fetchUserDataResponse.demographics,
        fromLinkKey: fetchUserDataResponse.fromLinkKey,
        username,
        password,
      },
      {
        onSuccess: async () => {
          await login(username, 'password01');
        },
        onError: (e: any) => {
          const message =
            e.message === 'username unavailable'
              ? 'A user already exists with this email'
              : 'Please try again';

          toast.error(message);
        },
      }
    );

  const isValid = password.length > 3;

  return (
    <>
      <div>Select a username and password to sign up</div>
      <TextInput label={'Username'} value={username} onChange={setUsername} />
      <TextInput
        type="password"
        label={'Password'}
        value={password}
        onChange={setPassword}
        onEnterPressed={handleSignup}
      />
      <div
        style={{
          marginTop: 30,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Button onClick={handleSignup} disabled={!isValid} loading={isLoading}>
          Sign Up
        </Button>
      </div>
    </>
  );
};

export default BankUsernamePassword;
