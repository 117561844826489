import React from 'react';
import { Link } from 'react-router-dom';
import { useTopLevelCategories } from 'corner-store-shared/src/hooks/category/useTopLevelCategories';
import User from '../../User/User';
import { SideMenu } from '../SideMenu/SideMenu';
import styles from './NavOptions.module.scss';

/**
 The side menu, user menu, and nav links in our header
 */
const NavOptions = () => {
  const navigationList = useTopLevelCategories();

  return (
    <div className={styles.container}>
      <SideMenu />
      <div className={styles.largeScreenRow}>
        <Link to={'/promotions'} className={styles.headerLink}>
          Promotions
        </Link>
        {navigationList.map((item) => (
          <Link
            to={'/categories/' + item.id}
            key={item.name}
            className={styles.headerLink}>
            {item.name}
          </Link>
        ))}
      </div>
      <User />
    </div>
  );
};

export default NavOptions;
