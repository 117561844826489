// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: micashared/common/enums/country/v1/country.proto

/* eslint-disable */

export const protobufPackage = "micashared.common.enums.country.v1";

/** ISO 3166-2:US */
export enum Country {
  COUNTRY_UNSPECIFIED = 0,
  /** COUNTRY_US - United States */
  COUNTRY_US = 1,
  /** COUNTRY_JM - Jamacia */
  COUNTRY_JM = 2,
}
