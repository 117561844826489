// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: mica/discount/discountprovideraccount/v1/discount_provider_account.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { BankAccountDetail, BankAccountDetailSearch } from "../../../../micashared/common/v1/bank_account_detail";
import { Error } from "../../../../micashared/common/v1/error";

export const protobufPackage = "mica.discount.discountprovideraccount.v1";

export enum DiscountProviderAccountStatus {
  DISCOUNT_PROVIDER_ACCOUNT_STATUS_UNSPECIFIED = 0,
  DISCOUNT_PROVIDER_ACCOUNT_STATUS_ACTIVE = 1,
  DISCOUNT_PROVIDER_ACCOUNT_STATUS_SUSPENDED = 2,
  DISCOUNT_PROVIDER_ACCOUNT_STATUS_CLOSED = 3,
}

/** One of the Discount Provider's accounts that Mica can draw down from */
export interface DiscountProviderAccount {
  discountProviderAccountKey: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  discountProviderAccountRef: string;
  discountProviderAccountStatus: DiscountProviderAccountStatus;
  name: string;
  operatingAccount: BankAccountDetail | undefined;
  revenueAccount: BankAccountDetail | undefined;
}

export interface CreateDiscountProviderAccountRequest {
  discountProviderAccountRef: string;
  name: string;
  discountProviderAccountStatus: DiscountProviderAccountStatus;
  operatingAccount: BankAccountDetail | undefined;
  revenueAccount: BankAccountDetail | undefined;
}

export interface CreateDiscountProviderAccountResponse {
  status: CreateDiscountProviderAccountResponse_Status;
  error: Error | undefined;
  discountProviderAccountKey: string;
  version: number;
}

export enum CreateDiscountProviderAccountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface GetDiscountProviderAccountRequest {
  discountProviderAccountRef?: string | undefined;
  discountProviderAccountKey?: string | undefined;
}

export interface GetDiscountProviderAccountResponse {
  status: GetDiscountProviderAccountResponse_Status;
  error: Error | undefined;
  discountProviderAccount: DiscountProviderAccount | undefined;
}

export enum GetDiscountProviderAccountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateDiscountProviderAccountRequest {
  discountProviderAccountRef?: string | undefined;
  discountProviderAccountKey?: string | undefined;
  version: number;
  discountProviderAccountStatus: DiscountProviderAccountStatus;
  name: string;
  operatingAccount: BankAccountDetail | undefined;
  revenueAccount: BankAccountDetail | undefined;
}

export interface UpdateDiscountProviderAccountResponse {
  status: UpdateDiscountProviderAccountResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateDiscountProviderAccountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateDiscountProviderAccountStatusRequest {
  discountProviderAccountRef?: string | undefined;
  discountProviderAccountKey?: string | undefined;
  discountProviderAccountStatus: DiscountProviderAccountStatus;
}

export interface UpdateDiscountProviderAccountStatusResponse {
  status: UpdateDiscountProviderAccountStatusResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateDiscountProviderAccountStatusResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_INVALID_STATE_TRANSITION = 4,
}

export interface RemoveDiscountProviderAccountRequest {
  discountProviderAccountRef?: string | undefined;
  discountProviderAccountKey?: string | undefined;
}

export interface RemoveDiscountProviderAccountResponse {
  status: RemoveDiscountProviderAccountResponse_Status;
  error: Error | undefined;
}

export enum RemoveDiscountProviderAccountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface SearchDiscountProviderAccountRequest {
  discountProviderAccountStatus: DiscountProviderAccountStatus;
  name: string;
  operatingAccount: BankAccountDetailSearch | undefined;
  revenueAccount: BankAccountDetailSearch | undefined;
}

export interface SearchDiscountProviderAccountResponse {
  status: SearchDiscountProviderAccountResponse_Status;
  error: Error | undefined;
  discountProviderAccounts: DiscountProviderAccount[];
}

export enum SearchDiscountProviderAccountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

function createBaseDiscountProviderAccount(): DiscountProviderAccount {
  return {
    discountProviderAccountKey: "",
    version: 0,
    created: undefined,
    updated: undefined,
    discountProviderAccountRef: "",
    discountProviderAccountStatus: 0,
    name: "",
    operatingAccount: undefined,
    revenueAccount: undefined,
  };
}

export const DiscountProviderAccount = {
  encode(message: DiscountProviderAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountProviderAccountKey !== "") {
      writer.uint32(10).string(message.discountProviderAccountKey);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(34).fork()).ldelim();
    }
    if (message.discountProviderAccountRef !== "") {
      writer.uint32(50).string(message.discountProviderAccountRef);
    }
    if (message.discountProviderAccountStatus !== 0) {
      writer.uint32(40).int32(message.discountProviderAccountStatus);
    }
    if (message.name !== "") {
      writer.uint32(58).string(message.name);
    }
    if (message.operatingAccount !== undefined) {
      BankAccountDetail.encode(message.operatingAccount, writer.uint32(66).fork()).ldelim();
    }
    if (message.revenueAccount !== undefined) {
      BankAccountDetail.encode(message.revenueAccount, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiscountProviderAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiscountProviderAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountProviderAccountKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.discountProviderAccountStatus = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.operatingAccount = BankAccountDetail.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.revenueAccount = BankAccountDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DiscountProviderAccount>, I>>(base?: I): DiscountProviderAccount {
    return DiscountProviderAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DiscountProviderAccount>, I>>(object: I): DiscountProviderAccount {
    const message = createBaseDiscountProviderAccount();
    message.discountProviderAccountKey = object.discountProviderAccountKey ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? "";
    message.discountProviderAccountStatus = object.discountProviderAccountStatus ?? 0;
    message.name = object.name ?? "";
    message.operatingAccount = (object.operatingAccount !== undefined && object.operatingAccount !== null)
      ? BankAccountDetail.fromPartial(object.operatingAccount)
      : undefined;
    message.revenueAccount = (object.revenueAccount !== undefined && object.revenueAccount !== null)
      ? BankAccountDetail.fromPartial(object.revenueAccount)
      : undefined;
    return message;
  },
};

function createBaseCreateDiscountProviderAccountRequest(): CreateDiscountProviderAccountRequest {
  return {
    discountProviderAccountRef: "",
    name: "",
    discountProviderAccountStatus: 0,
    operatingAccount: undefined,
    revenueAccount: undefined,
  };
}

export const CreateDiscountProviderAccountRequest = {
  encode(message: CreateDiscountProviderAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountProviderAccountRef !== "") {
      writer.uint32(10).string(message.discountProviderAccountRef);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.discountProviderAccountStatus !== 0) {
      writer.uint32(24).int32(message.discountProviderAccountStatus);
    }
    if (message.operatingAccount !== undefined) {
      BankAccountDetail.encode(message.operatingAccount, writer.uint32(34).fork()).ldelim();
    }
    if (message.revenueAccount !== undefined) {
      BankAccountDetail.encode(message.revenueAccount, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateDiscountProviderAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDiscountProviderAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.discountProviderAccountStatus = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.operatingAccount = BankAccountDetail.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.revenueAccount = BankAccountDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateDiscountProviderAccountRequest>, I>>(
    base?: I,
  ): CreateDiscountProviderAccountRequest {
    return CreateDiscountProviderAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateDiscountProviderAccountRequest>, I>>(
    object: I,
  ): CreateDiscountProviderAccountRequest {
    const message = createBaseCreateDiscountProviderAccountRequest();
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? "";
    message.name = object.name ?? "";
    message.discountProviderAccountStatus = object.discountProviderAccountStatus ?? 0;
    message.operatingAccount = (object.operatingAccount !== undefined && object.operatingAccount !== null)
      ? BankAccountDetail.fromPartial(object.operatingAccount)
      : undefined;
    message.revenueAccount = (object.revenueAccount !== undefined && object.revenueAccount !== null)
      ? BankAccountDetail.fromPartial(object.revenueAccount)
      : undefined;
    return message;
  },
};

function createBaseCreateDiscountProviderAccountResponse(): CreateDiscountProviderAccountResponse {
  return { status: 0, error: undefined, discountProviderAccountKey: "", version: 0 };
}

export const CreateDiscountProviderAccountResponse = {
  encode(message: CreateDiscountProviderAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.discountProviderAccountKey !== "") {
      writer.uint32(26).string(message.discountProviderAccountKey);
    }
    if (message.version !== 0) {
      writer.uint32(32).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateDiscountProviderAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDiscountProviderAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountProviderAccountKey = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateDiscountProviderAccountResponse>, I>>(
    base?: I,
  ): CreateDiscountProviderAccountResponse {
    return CreateDiscountProviderAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateDiscountProviderAccountResponse>, I>>(
    object: I,
  ): CreateDiscountProviderAccountResponse {
    const message = createBaseCreateDiscountProviderAccountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discountProviderAccountKey = object.discountProviderAccountKey ?? "";
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseGetDiscountProviderAccountRequest(): GetDiscountProviderAccountRequest {
  return { discountProviderAccountRef: undefined, discountProviderAccountKey: undefined };
}

export const GetDiscountProviderAccountRequest = {
  encode(message: GetDiscountProviderAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountProviderAccountRef !== undefined) {
      writer.uint32(10).string(message.discountProviderAccountRef);
    }
    if (message.discountProviderAccountKey !== undefined) {
      writer.uint32(18).string(message.discountProviderAccountKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDiscountProviderAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiscountProviderAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountProviderAccountKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDiscountProviderAccountRequest>, I>>(
    base?: I,
  ): GetDiscountProviderAccountRequest {
    return GetDiscountProviderAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDiscountProviderAccountRequest>, I>>(
    object: I,
  ): GetDiscountProviderAccountRequest {
    const message = createBaseGetDiscountProviderAccountRequest();
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? undefined;
    message.discountProviderAccountKey = object.discountProviderAccountKey ?? undefined;
    return message;
  },
};

function createBaseGetDiscountProviderAccountResponse(): GetDiscountProviderAccountResponse {
  return { status: 0, error: undefined, discountProviderAccount: undefined };
}

export const GetDiscountProviderAccountResponse = {
  encode(message: GetDiscountProviderAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.discountProviderAccount !== undefined) {
      DiscountProviderAccount.encode(message.discountProviderAccount, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDiscountProviderAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiscountProviderAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountProviderAccount = DiscountProviderAccount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDiscountProviderAccountResponse>, I>>(
    base?: I,
  ): GetDiscountProviderAccountResponse {
    return GetDiscountProviderAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDiscountProviderAccountResponse>, I>>(
    object: I,
  ): GetDiscountProviderAccountResponse {
    const message = createBaseGetDiscountProviderAccountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discountProviderAccount =
      (object.discountProviderAccount !== undefined && object.discountProviderAccount !== null)
        ? DiscountProviderAccount.fromPartial(object.discountProviderAccount)
        : undefined;
    return message;
  },
};

function createBaseUpdateDiscountProviderAccountRequest(): UpdateDiscountProviderAccountRequest {
  return {
    discountProviderAccountRef: undefined,
    discountProviderAccountKey: undefined,
    version: 0,
    discountProviderAccountStatus: 0,
    name: "",
    operatingAccount: undefined,
    revenueAccount: undefined,
  };
}

export const UpdateDiscountProviderAccountRequest = {
  encode(message: UpdateDiscountProviderAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountProviderAccountRef !== undefined) {
      writer.uint32(10).string(message.discountProviderAccountRef);
    }
    if (message.discountProviderAccountKey !== undefined) {
      writer.uint32(50).string(message.discountProviderAccountKey);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.discountProviderAccountStatus !== 0) {
      writer.uint32(24).int32(message.discountProviderAccountStatus);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.operatingAccount !== undefined) {
      BankAccountDetail.encode(message.operatingAccount, writer.uint32(42).fork()).ldelim();
    }
    if (message.revenueAccount !== undefined) {
      BankAccountDetail.encode(message.revenueAccount, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountProviderAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountProviderAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.discountProviderAccountKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.discountProviderAccountStatus = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.operatingAccount = BankAccountDetail.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.revenueAccount = BankAccountDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountProviderAccountRequest>, I>>(
    base?: I,
  ): UpdateDiscountProviderAccountRequest {
    return UpdateDiscountProviderAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateDiscountProviderAccountRequest>, I>>(
    object: I,
  ): UpdateDiscountProviderAccountRequest {
    const message = createBaseUpdateDiscountProviderAccountRequest();
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? undefined;
    message.discountProviderAccountKey = object.discountProviderAccountKey ?? undefined;
    message.version = object.version ?? 0;
    message.discountProviderAccountStatus = object.discountProviderAccountStatus ?? 0;
    message.name = object.name ?? "";
    message.operatingAccount = (object.operatingAccount !== undefined && object.operatingAccount !== null)
      ? BankAccountDetail.fromPartial(object.operatingAccount)
      : undefined;
    message.revenueAccount = (object.revenueAccount !== undefined && object.revenueAccount !== null)
      ? BankAccountDetail.fromPartial(object.revenueAccount)
      : undefined;
    return message;
  },
};

function createBaseUpdateDiscountProviderAccountResponse(): UpdateDiscountProviderAccountResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateDiscountProviderAccountResponse = {
  encode(message: UpdateDiscountProviderAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountProviderAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountProviderAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountProviderAccountResponse>, I>>(
    base?: I,
  ): UpdateDiscountProviderAccountResponse {
    return UpdateDiscountProviderAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateDiscountProviderAccountResponse>, I>>(
    object: I,
  ): UpdateDiscountProviderAccountResponse {
    const message = createBaseUpdateDiscountProviderAccountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseUpdateDiscountProviderAccountStatusRequest(): UpdateDiscountProviderAccountStatusRequest {
  return {
    discountProviderAccountRef: undefined,
    discountProviderAccountKey: undefined,
    discountProviderAccountStatus: 0,
  };
}

export const UpdateDiscountProviderAccountStatusRequest = {
  encode(message: UpdateDiscountProviderAccountStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountProviderAccountRef !== undefined) {
      writer.uint32(10).string(message.discountProviderAccountRef);
    }
    if (message.discountProviderAccountKey !== undefined) {
      writer.uint32(26).string(message.discountProviderAccountKey);
    }
    if (message.discountProviderAccountStatus !== 0) {
      writer.uint32(16).int32(message.discountProviderAccountStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountProviderAccountStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountProviderAccountStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountProviderAccountKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.discountProviderAccountStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountProviderAccountStatusRequest>, I>>(
    base?: I,
  ): UpdateDiscountProviderAccountStatusRequest {
    return UpdateDiscountProviderAccountStatusRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateDiscountProviderAccountStatusRequest>, I>>(
    object: I,
  ): UpdateDiscountProviderAccountStatusRequest {
    const message = createBaseUpdateDiscountProviderAccountStatusRequest();
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? undefined;
    message.discountProviderAccountKey = object.discountProviderAccountKey ?? undefined;
    message.discountProviderAccountStatus = object.discountProviderAccountStatus ?? 0;
    return message;
  },
};

function createBaseUpdateDiscountProviderAccountStatusResponse(): UpdateDiscountProviderAccountStatusResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateDiscountProviderAccountStatusResponse = {
  encode(message: UpdateDiscountProviderAccountStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountProviderAccountStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountProviderAccountStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountProviderAccountStatusResponse>, I>>(
    base?: I,
  ): UpdateDiscountProviderAccountStatusResponse {
    return UpdateDiscountProviderAccountStatusResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateDiscountProviderAccountStatusResponse>, I>>(
    object: I,
  ): UpdateDiscountProviderAccountStatusResponse {
    const message = createBaseUpdateDiscountProviderAccountStatusResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseRemoveDiscountProviderAccountRequest(): RemoveDiscountProviderAccountRequest {
  return { discountProviderAccountRef: undefined, discountProviderAccountKey: undefined };
}

export const RemoveDiscountProviderAccountRequest = {
  encode(message: RemoveDiscountProviderAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountProviderAccountRef !== undefined) {
      writer.uint32(10).string(message.discountProviderAccountRef);
    }
    if (message.discountProviderAccountKey !== undefined) {
      writer.uint32(18).string(message.discountProviderAccountKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDiscountProviderAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDiscountProviderAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountProviderAccountKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveDiscountProviderAccountRequest>, I>>(
    base?: I,
  ): RemoveDiscountProviderAccountRequest {
    return RemoveDiscountProviderAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveDiscountProviderAccountRequest>, I>>(
    object: I,
  ): RemoveDiscountProviderAccountRequest {
    const message = createBaseRemoveDiscountProviderAccountRequest();
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? undefined;
    message.discountProviderAccountKey = object.discountProviderAccountKey ?? undefined;
    return message;
  },
};

function createBaseRemoveDiscountProviderAccountResponse(): RemoveDiscountProviderAccountResponse {
  return { status: 0, error: undefined };
}

export const RemoveDiscountProviderAccountResponse = {
  encode(message: RemoveDiscountProviderAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDiscountProviderAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDiscountProviderAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveDiscountProviderAccountResponse>, I>>(
    base?: I,
  ): RemoveDiscountProviderAccountResponse {
    return RemoveDiscountProviderAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveDiscountProviderAccountResponse>, I>>(
    object: I,
  ): RemoveDiscountProviderAccountResponse {
    const message = createBaseRemoveDiscountProviderAccountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseSearchDiscountProviderAccountRequest(): SearchDiscountProviderAccountRequest {
  return { discountProviderAccountStatus: 0, name: "", operatingAccount: undefined, revenueAccount: undefined };
}

export const SearchDiscountProviderAccountRequest = {
  encode(message: SearchDiscountProviderAccountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountProviderAccountStatus !== 0) {
      writer.uint32(8).int32(message.discountProviderAccountStatus);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.operatingAccount !== undefined) {
      BankAccountDetailSearch.encode(message.operatingAccount, writer.uint32(26).fork()).ldelim();
    }
    if (message.revenueAccount !== undefined) {
      BankAccountDetailSearch.encode(message.revenueAccount, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchDiscountProviderAccountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchDiscountProviderAccountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.discountProviderAccountStatus = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.operatingAccount = BankAccountDetailSearch.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.revenueAccount = BankAccountDetailSearch.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchDiscountProviderAccountRequest>, I>>(
    base?: I,
  ): SearchDiscountProviderAccountRequest {
    return SearchDiscountProviderAccountRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchDiscountProviderAccountRequest>, I>>(
    object: I,
  ): SearchDiscountProviderAccountRequest {
    const message = createBaseSearchDiscountProviderAccountRequest();
    message.discountProviderAccountStatus = object.discountProviderAccountStatus ?? 0;
    message.name = object.name ?? "";
    message.operatingAccount = (object.operatingAccount !== undefined && object.operatingAccount !== null)
      ? BankAccountDetailSearch.fromPartial(object.operatingAccount)
      : undefined;
    message.revenueAccount = (object.revenueAccount !== undefined && object.revenueAccount !== null)
      ? BankAccountDetailSearch.fromPartial(object.revenueAccount)
      : undefined;
    return message;
  },
};

function createBaseSearchDiscountProviderAccountResponse(): SearchDiscountProviderAccountResponse {
  return { status: 0, error: undefined, discountProviderAccounts: [] };
}

export const SearchDiscountProviderAccountResponse = {
  encode(message: SearchDiscountProviderAccountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.discountProviderAccounts) {
      DiscountProviderAccount.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchDiscountProviderAccountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchDiscountProviderAccountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountProviderAccounts.push(DiscountProviderAccount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchDiscountProviderAccountResponse>, I>>(
    base?: I,
  ): SearchDiscountProviderAccountResponse {
    return SearchDiscountProviderAccountResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchDiscountProviderAccountResponse>, I>>(
    object: I,
  ): SearchDiscountProviderAccountResponse {
    const message = createBaseSearchDiscountProviderAccountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discountProviderAccounts =
      object.discountProviderAccounts?.map((e) => DiscountProviderAccount.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
