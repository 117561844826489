import {
  AuthenticateRequest,
  AuthenticateResponse,
} from 'micareference-proto/src/demo/authenticate/v1/authenticate';
import {
  MicaShopService,
  MicaShopServiceClientImpl,
} from 'micareference-proto/src/demo/micashop/service/v1/micashop_service';
import { MakeGrpcClientArgs, makeGrpcClient } from 'mica-utilities/src/grpc';

export const auth = {
  token: '',
};

const createMicaShopClient = (args: MakeGrpcClientArgs): MicaShopService => {
  const shopClient = makeGrpcClient(MicaShopServiceClientImpl)(args);

  return {
    ...shopClient,

    authenticate: async (
      request: AuthenticateRequest
    ): Promise<AuthenticateResponse> => {
      const response = await shopClient.authenticate(request);
      if (response.token) {
        auth.token = response.token;
      }
      return response;
    },
  } as MicaShopService;
};

export const createClient = (
  host: string,
  transport?: MakeGrpcClientArgs['transport']
) =>
  createMicaShopClient({
    ...(transport ? { transport } : {}),
    host,
    metadata: () =>
      auth.token
        ? {
            Authorization: `Bearer ${auth.token}`,
          }
        : {},
  });
