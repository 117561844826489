import { Region } from 'mica-proto/src/micashared/common/enums/region/v1/region';
import { intersection, invertObj, uniq } from 'ramda';
import {
  getName,
  registerLocale,
  getSupportedLanguages,
} from 'i18n-iso-countries';
import { iso31662 } from 'iso-3166';
import * as en from 'i18n-iso-countries/langs/en.json';
registerLocale(en);

const registerBrowserLocales = async () => {
  const supported = getSupportedLanguages();
  const inBrowser = uniq(navigator?.languages.map((x) => x.split('-')[0]));

  const toRegister = intersection(supported, inBrowser);

  for (const language of toRegister) {
    import(
      `../../../../../../node_modules/i18n-iso-countries/langs/${language}.json`
    ).then((locale) => {
      registerLocale(locale);
    });
  }
};
registerBrowserLocales();

const cleanEnumLabel = (label: string, prefix: string) =>
  label.replace(prefix, '').replace(/_/g, '-');

export const readableValueForEnum =
  (enumMap: any, prefix: string) => (value: number) =>
    cleanEnumLabel(invertObj(enumMap)[value], prefix);

export const readableRegion = (value: number) =>
  iso31662.find(
    (x) => x.code === readableValueForEnum(Region, 'REGION_')(value)
  )?.name ?? '';

// readableValueForEnum(Region, 'REGION_US_');

export const enumAsSelectOptions = (
  enumObj: Record<string, any>,
  prefix = ''
) =>
  Object.entries(enumObj)
    .filter(([, value]) => typeof value === 'number')
    .map(([key, value]) => ({
      label: cleanEnumLabel(key, prefix),
      value: value,
    }));

export const enumAsSelectOptionsFiltered = (
  enumObj: Record<string, any>,
  prefix = ''
) =>
  Object.entries(enumObj)
    .filter(
      ([key, value]) => typeof value === 'number' && key.startsWith(prefix)
    )
    .map(([key, value]) => ({
      label: cleanEnumLabel(key, prefix),
      value: value,
    }));

export const enumAsRegionSelectOptions = (
  enumObj: Record<string, any>,
  prefix = '',
  country = 'US'
) => {
  // TODO: add language options - no current npm package for iso 3166-2 provides this
  const subdivisions = iso31662.filter(
    (x) => x.parent === country.toUpperCase()
  );
  return enumAsSelectOptionsFiltered(enumObj, `${prefix}${country}`).map(
    (opt) => ({
      label:
        subdivisions.find((s) => s.code === `${country}${opt.label}`)?.name ??
        '',
      value: opt.value,
    })
  );
};

export const enumAsCountrySelectOptions = (
  enumObj: Record<string, any>,
  prefix = '',
  locale = 'en'
) => {
  return enumAsSelectOptionsFiltered(enumObj, prefix).map((opt) => ({
    label: getName(opt.label, locale),
    value: opt.value,
  }));
};
