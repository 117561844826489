import { useSetRecoilState } from 'recoil';
import {
  shoppingCartLocalStorageKey,
  shoppingCartState,
} from '../../state/shoppingCartState';
import { storage } from '../../utils/localStorage';
import { useEffect } from 'react';

export const useInitShoppingCart = () => {
  const setShoppingCart = useSetRecoilState(shoppingCartState);

  const initShoppingCart = async () => {
    const savedCart = await storage.localStorage.getItem(
      shoppingCartLocalStorageKey
    );
    if (savedCart) {
      setShoppingCart(JSON.parse(savedCart));
    }
  };

  useEffect(() => {
    initShoppingCart();
  }, []);
};
