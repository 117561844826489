import React, { PropsWithChildren } from 'react';

import cx from 'classnames';

import styles from './CenteredContent.module.scss';

type CenteredContentProps = {
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  contentClassName?: string;
  contentStyle?: React.CSSProperties;
};

/**
 A Container component to keep our content in the right place
 */
const CenteredContent = ({
  children,
  containerClassName,
  containerStyle,
  contentClassName,
  contentStyle,
}: PropsWithChildren<CenteredContentProps>) => (
  <div
    className={cx(styles.container, containerClassName)}
    style={containerStyle}>
    <div className={cx(styles.content, contentClassName)} style={contentStyle}>
      {children}
    </div>
  </div>
);

export default CenteredContent;
