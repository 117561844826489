import { useMutation } from 'react-query';
import { useMicaShopClient } from '../useMicaShopClient';

/**
 * A hook for creating a user with demographic info
 */
export const useCreateUserByDemographic = () => {
  const client = useMicaShopClient();
  return useMutation(client.createUserByDemographic);
};
