import * as React from 'react';

import { Link, useLocation } from 'react-router-dom';

import logo from 'corner-store-shared/src/assets/CornerStoreLogo.png';
import symbol from 'corner-store-shared/src/assets/CornerStoreSymbol.png';
import CenteredContent from '../CenteredContent/CenteredContent';
import { ShoppingCartButton } from '../ShoppingCartButton/ShoppingCartButton';
import HeaderSearch from './HeaderSearch/HeaderSearch';
import styles from './Nav.module.scss';
import NavOptions from './NavOptions/NavOptions';

export const Nav = () => {
  const { pathname } = useLocation();

  return pathname === '/pos' ? null : (
    <nav className={styles.container}>
      <div className={styles.navContent}>
        <CenteredContent>
          <NavOptions />
        </CenteredContent>
      </div>
      <CenteredContent
        containerClassName={styles.lowerBarContainer}
        contentClassName={styles.lowerBar}>
        <Link to={'/'} className={styles.logoLink}>
          <img src={logo} className={styles.logo} alt={'logo'} />
          <img src={symbol} className={styles.symbol} alt={'logo'} />
        </Link>
        <HeaderSearch />
        <ShoppingCartButton />
      </CenteredContent>
    </nav>
  );
};
