// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: demo/micashop/uuek/v1/uuek.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { Error } from "../../../common/v1/error";

export const protobufPackage = "demo.micashop.uuek.v1";

export interface UpdateUUEKRequest {
  uuekId: string;
  name: string;
}

export interface UpdateUUEKResponse {
  status: UpdateUUEKResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateUUEKResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface RemoveUUEKRequest {
  uuekId: string;
}

export interface RemoveUUEKResponse {
  status: RemoveUUEKResponse_Status;
  error: Error | undefined;
}

export enum RemoveUUEKResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface SearchUUEKRequest {
}

export interface UUEK {
  uuekId: string;
  uuek: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  expirationDate: Date | undefined;
  name: string;
}

export interface SearchUUEKResponse {
  status: SearchUUEKResponse_Status;
  error: Error | undefined;
  uueks: UUEK[];
}

export enum SearchUUEKResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

function createBaseUpdateUUEKRequest(): UpdateUUEKRequest {
  return { uuekId: "", name: "" };
}

export const UpdateUUEKRequest = {
  encode(message: UpdateUUEKRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuekId !== "") {
      writer.uint32(10).string(message.uuekId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUUEKRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUUEKRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuekId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateUUEKRequest>, I>>(base?: I): UpdateUUEKRequest {
    return UpdateUUEKRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUUEKRequest>, I>>(object: I): UpdateUUEKRequest {
    const message = createBaseUpdateUUEKRequest();
    message.uuekId = object.uuekId ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseUpdateUUEKResponse(): UpdateUUEKResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateUUEKResponse = {
  encode(message: UpdateUUEKResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateUUEKResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateUUEKResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateUUEKResponse>, I>>(base?: I): UpdateUUEKResponse {
    return UpdateUUEKResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateUUEKResponse>, I>>(object: I): UpdateUUEKResponse {
    const message = createBaseUpdateUUEKResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseRemoveUUEKRequest(): RemoveUUEKRequest {
  return { uuekId: "" };
}

export const RemoveUUEKRequest = {
  encode(message: RemoveUUEKRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuekId !== "") {
      writer.uint32(10).string(message.uuekId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveUUEKRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveUUEKRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuekId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveUUEKRequest>, I>>(base?: I): RemoveUUEKRequest {
    return RemoveUUEKRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveUUEKRequest>, I>>(object: I): RemoveUUEKRequest {
    const message = createBaseRemoveUUEKRequest();
    message.uuekId = object.uuekId ?? "";
    return message;
  },
};

function createBaseRemoveUUEKResponse(): RemoveUUEKResponse {
  return { status: 0, error: undefined };
}

export const RemoveUUEKResponse = {
  encode(message: RemoveUUEKResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveUUEKResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveUUEKResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveUUEKResponse>, I>>(base?: I): RemoveUUEKResponse {
    return RemoveUUEKResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoveUUEKResponse>, I>>(object: I): RemoveUUEKResponse {
    const message = createBaseRemoveUUEKResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseSearchUUEKRequest(): SearchUUEKRequest {
  return {};
}

export const SearchUUEKRequest = {
  encode(_: SearchUUEKRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchUUEKRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchUUEKRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchUUEKRequest>, I>>(base?: I): SearchUUEKRequest {
    return SearchUUEKRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchUUEKRequest>, I>>(_: I): SearchUUEKRequest {
    const message = createBaseSearchUUEKRequest();
    return message;
  },
};

function createBaseUUEK(): UUEK {
  return {
    uuekId: "",
    uuek: "",
    version: 0,
    created: undefined,
    updated: undefined,
    expirationDate: undefined,
    name: "",
  };
}

export const UUEK = {
  encode(message: UUEK, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuekId !== "") {
      writer.uint32(10).string(message.uuekId);
    }
    if (message.uuek !== "") {
      writer.uint32(26).string(message.uuek);
    }
    if (message.version !== 0) {
      writer.uint32(32).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(42).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(50).fork()).ldelim();
    }
    if (message.expirationDate !== undefined) {
      Timestamp.encode(toTimestamp(message.expirationDate), writer.uint32(66).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(82).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UUEK {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUUEK();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuekId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.uuek = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.expirationDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UUEK>, I>>(base?: I): UUEK {
    return UUEK.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UUEK>, I>>(object: I): UUEK {
    const message = createBaseUUEK();
    message.uuekId = object.uuekId ?? "";
    message.uuek = object.uuek ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.expirationDate = object.expirationDate ?? undefined;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseSearchUUEKResponse(): SearchUUEKResponse {
  return { status: 0, error: undefined, uueks: [] };
}

export const SearchUUEKResponse = {
  encode(message: SearchUUEKResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.uueks) {
      UUEK.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchUUEKResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchUUEKResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.uueks.push(UUEK.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchUUEKResponse>, I>>(base?: I): SearchUUEKResponse {
    return SearchUUEKResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SearchUUEKResponse>, I>>(object: I): SearchUUEKResponse {
    const message = createBaseSearchUUEKResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.uueks = object.uueks?.map((e) => UUEK.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
