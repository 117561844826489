// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: micashared/common/v1/bank_account_detail.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { CustodialBank } from "../enums/custodialbank/v1/custodial_bank";

export const protobufPackage = "micashared.common.v1";

export interface ABAAccountNumber {
  /** The name of the bank. */
  name: string;
  /** The 9 digit ABA routing number. */
  routingNumber: string;
  /** The account number. */
  accountNumber: string;
}

export interface BankAccountDetail {
  /** the custodial bank where this transaction will be settled */
  custodialBank: CustodialBank;
  /** The bank at which this account is held at */
  bank: string;
  /** The account reference for this account */
  accountRef: string;
}

export interface BankAccountDetailSearch {
  /** the custodial bank where this transaction will be settled */
  custodialBank: CustodialBank;
  /** The bank at which this account is held at */
  bank: string;
  /** The account reference for this account */
  accountRef: string;
}

export interface ABAAccountEntry {
  key: string;
  version: number;
  enabled: boolean;
  accountDetail: ABAAccountNumber | undefined;
}

function createBaseABAAccountNumber(): ABAAccountNumber {
  return { name: "", routingNumber: "", accountNumber: "" };
}

export const ABAAccountNumber = {
  encode(message: ABAAccountNumber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.routingNumber !== "") {
      writer.uint32(18).string(message.routingNumber);
    }
    if (message.accountNumber !== "") {
      writer.uint32(26).string(message.accountNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ABAAccountNumber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseABAAccountNumber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.routingNumber = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ABAAccountNumber>, I>>(base?: I): ABAAccountNumber {
    return ABAAccountNumber.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ABAAccountNumber>, I>>(object: I): ABAAccountNumber {
    const message = createBaseABAAccountNumber();
    message.name = object.name ?? "";
    message.routingNumber = object.routingNumber ?? "";
    message.accountNumber = object.accountNumber ?? "";
    return message;
  },
};

function createBaseBankAccountDetail(): BankAccountDetail {
  return { custodialBank: 0, bank: "", accountRef: "" };
}

export const BankAccountDetail = {
  encode(message: BankAccountDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.custodialBank !== 0) {
      writer.uint32(48).int32(message.custodialBank);
    }
    if (message.bank !== "") {
      writer.uint32(66).string(message.bank);
    }
    if (message.accountRef !== "") {
      writer.uint32(58).string(message.accountRef);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BankAccountDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6:
          if (tag !== 48) {
            break;
          }

          message.custodialBank = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.bank = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.accountRef = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BankAccountDetail>, I>>(base?: I): BankAccountDetail {
    return BankAccountDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BankAccountDetail>, I>>(object: I): BankAccountDetail {
    const message = createBaseBankAccountDetail();
    message.custodialBank = object.custodialBank ?? 0;
    message.bank = object.bank ?? "";
    message.accountRef = object.accountRef ?? "";
    return message;
  },
};

function createBaseBankAccountDetailSearch(): BankAccountDetailSearch {
  return { custodialBank: 0, bank: "", accountRef: "" };
}

export const BankAccountDetailSearch = {
  encode(message: BankAccountDetailSearch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.custodialBank !== 0) {
      writer.uint32(8).int32(message.custodialBank);
    }
    if (message.bank !== "") {
      writer.uint32(18).string(message.bank);
    }
    if (message.accountRef !== "") {
      writer.uint32(26).string(message.accountRef);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BankAccountDetailSearch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBankAccountDetailSearch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.custodialBank = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.bank = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.accountRef = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<BankAccountDetailSearch>, I>>(base?: I): BankAccountDetailSearch {
    return BankAccountDetailSearch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BankAccountDetailSearch>, I>>(object: I): BankAccountDetailSearch {
    const message = createBaseBankAccountDetailSearch();
    message.custodialBank = object.custodialBank ?? 0;
    message.bank = object.bank ?? "";
    message.accountRef = object.accountRef ?? "";
    return message;
  },
};

function createBaseABAAccountEntry(): ABAAccountEntry {
  return { key: "", version: 0, enabled: false, accountDetail: undefined };
}

export const ABAAccountEntry = {
  encode(message: ABAAccountEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.enabled !== false) {
      writer.uint32(24).bool(message.enabled);
    }
    if (message.accountDetail !== undefined) {
      ABAAccountNumber.encode(message.accountDetail, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ABAAccountEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseABAAccountEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.accountDetail = ABAAccountNumber.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ABAAccountEntry>, I>>(base?: I): ABAAccountEntry {
    return ABAAccountEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ABAAccountEntry>, I>>(object: I): ABAAccountEntry {
    const message = createBaseABAAccountEntry();
    message.key = object.key ?? "";
    message.version = object.version ?? 0;
    message.enabled = object.enabled ?? false;
    message.accountDetail = (object.accountDetail !== undefined && object.accountDetail !== null)
      ? ABAAccountNumber.fromPartial(object.accountDetail)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
