import React, { useEffect, useState } from 'react';

import { TextInput } from 'mica-ui-kit/src/components/inputs/text/TextInput/TextInput';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';
import { toast } from 'react-toastify';

import { useForgotUsername } from 'corner-store-shared/src/hooks/user/useForgotUsername';

type SigninProps = {
  onToggleSignin: () => void;
};

export const ForgotUsername = ({ onToggleSignin }: SigninProps) => {
  const [email, setEmail] = useState('');

  const { mutate: forgotUsername, isLoading, data } = useForgotUsername();

  const handleKeyPress = (e: any) =>
    e.key === 'Enter' && handleForgotUsername();

  const handleForgotUsername = async () => forgotUsername(email);

  useEffect(() => {
    data?.status === 1 &&
      toast.success(`An email has been sent to you with your username`);
  }, [data]);

  return (
    <>
      <div>Enter your email to be sent your username, if one exists.</div>
      <TextInput
        className="mb-15 w-full"
        onChange={setEmail}
        onKeyDown={handleKeyPress}
        value={email}
        label={'Email'}
      />
      <Button
        className="w-full mb-20"
        loading={isLoading}
        onClick={handleForgotUsername}>
        Submit
      </Button>
      <Button className="mb-20" onClick={onToggleSignin} type={'button'}>
        Return to Sign In
      </Button>
    </>
  );
};
