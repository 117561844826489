import * as React from 'react';
import { useState } from 'react';
import cx from 'classnames';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import logo from 'corner-store-shared/src/assets/CornerStoreLogo.png';
import { useTopLevelCategories } from 'corner-store-shared/src/hooks/category/useTopLevelCategories';
import styles from './SideMenu.module.scss';

export const SideMenu = () => {
  const [active, setActive] = useState(false);
  const navigationList = useTopLevelCategories();

  const navigate = useNavigate();

  return (
    <div className={styles.menu}>
      <button
        className={styles.menuButton}
        onClick={() => setActive((currentlyActive) => !currentlyActive)}>
        <AiOutlineMenu />
      </button>
      <div
        className={cx(styles.sideMenuWrapper, {
          [styles.sideMenuActive]: active,
        })}>
        <div
          className={styles.sideMenuBackdrop}
          onClick={() => setActive(false)}
        />
        <div className={styles.sideMenu} onClick={() => setActive(false)}>
          <div className={styles.sideMenuTitle}>
            <img src={logo} className={styles.logo} alt={'logo'} />
            <button
              className={styles.closeIcon}
              onClick={() => setActive(false)}>
              <AiOutlineClose />
            </button>
          </div>
          <div className={styles.sideMenuContent}>
            <div
              className={styles.section}
              onClick={() => navigate('/promotions')}>
              <p className={'font-bold mb-4'}>Promotions</p>
            </div>
            {navigationList.map((item) => (
              <div
                key={item.name}
                className={styles.section}
                onClick={() => navigate('categories/' + item.id)}>
                <p className={'font-bold mb-4'}>{item.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
