import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ReactElement, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import.meta.env.PROD &&
  Sentry.init({
    dsn: 'https://0d9e8f9a512a4441b47400acc0583c60@o4504487674314752.ingest.sentry.io/4504488195522560',
    environment: window.location.host,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],
    tracesSampleRate: 0.2,
  });

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export { Sentry, sentryCreateBrowserRouter };
