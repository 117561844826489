// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: demo/common/v1/address.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Country } from "../enums/country/v1/country";
import { Region } from "../enums/region/v1/region";

export const protobufPackage = "demo.common.v1";

export interface Address {
  streetLines: string[];
  /** typically city */
  locality: string;
  /**
   * this would be state in the US (ISO 3166-2:US), prefecture in Japan (ISO 3166-2:JP) or
   * territory in Australia (ISO 3166-2:AU)
   */
  region: Region;
  /** postal code, in the US this would be zip code */
  postalCode: string;
  /** https://en.wikipedia.org/wiki/ISO_3166-2 */
  country: Country;
}

function createBaseAddress(): Address {
  return { streetLines: [], locality: "", region: 0, postalCode: "", country: 0 };
}

export const Address = {
  encode(message: Address, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.streetLines) {
      writer.uint32(10).string(v!);
    }
    if (message.locality !== "") {
      writer.uint32(18).string(message.locality);
    }
    if (message.region !== 0) {
      writer.uint32(24).int32(message.region);
    }
    if (message.postalCode !== "") {
      writer.uint32(34).string(message.postalCode);
    }
    if (message.country !== 0) {
      writer.uint32(40).int32(message.country);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Address {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.streetLines.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.locality = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.region = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.postalCode = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.country = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Address>, I>>(base?: I): Address {
    return Address.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Address>, I>>(object: I): Address {
    const message = createBaseAddress();
    message.streetLines = object.streetLines?.map((e) => e) || [];
    message.locality = object.locality ?? "";
    message.region = object.region ?? 0;
    message.postalCode = object.postalCode ?? "";
    message.country = object.country ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
