import { useQuery } from 'react-query';
import { useAuth } from '../useAuth';
import { useMicaShopClient } from '../useMicaShopClient';

/**
 * A hook for getting the current logged in user
 */
export const useUser = () => {
  const client = useMicaShopClient();
  const { isAuthenticated, token } = useAuth();

  const userResponse = useQuery(['getUser', token], () => client.getUser({}), {
    enabled: isAuthenticated,
  });

  return {
    user: userResponse.data?.user,
    ...userResponse,
  };
};
