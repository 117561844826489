import React, { useState } from 'react';

import { TextInput } from 'mica-ui-kit/src/components/inputs/text/TextInput/TextInput';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';
import { toast } from 'react-toastify';

import { useAuth } from 'corner-store-shared/src/hooks/useAuth';

export const SigninForm = () => {
  const [username, setUsername] = useState(import.meta.env.VITE_USERNAME || '');
  const [password, setPassword] = useState(import.meta.env.VITE_PASSWORD || '');

  const { login, loading } = useAuth();

  const handleSignin = async () => {
    const response = await login(username, password);

    if (response.error) {
      toast.error('Sign in failed, please try again');
    }
  };

  return (
    <form className={'w-full flex col align-ctr'} name={'SignInForm'}>
      <TextInput
        // autoFocus
        autoCorrect={'off'}
        autoCapitalize={'off'}
        className="mb-15 w-full"
        name={'username'}
        onChange={setUsername}
        value={username}
        label={'Username'}
      />
      <TextInput
        className="mb-15 w-full"
        name={'password'}
        onChange={setPassword}
        onEnterPressed={handleSignin}
        value={password}
        label={'Password'}
        type="password"
      />
      <Button
        id={'SignInButton'}
        className="w-full mb-20"
        type={'button'}
        loading={loading}
        onClick={handleSignin}>
        Sign In
      </Button>
    </form>
  );
};

export default SigninForm;
