import React, { Suspense } from 'react';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'mica-ui-kit/src/styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import App from './App';
import { MicaShopClientProvider } from './components/providers/MicaShopClientProvider/MicaShopClientProvider';
import ApolloProvider from './components/providers/ApolloProvider';
import { ErrorBoundaryUi } from 'mica-ui-kit/src/components/errors/ErrorBoundaryUi/ErrorBoundaryUi';
import { Sentry } from './utils/init';
import 'react-tooltip/dist/react-tooltip.css';
import { LoadingIndicator } from 'mica-ui-kit/src/components/loading/LoadingIndicator/LoadingIndicator';
import { initLocalStorage } from 'corner-store-shared/src/utils/localStorage';

initLocalStorage(localStorage);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryUi />} showDialog>
      <RecoilRoot>
        <ApolloProvider>
          <MicaShopClientProvider>
            <Suspense fallback={<LoadingIndicator />}>
              <App />
            </Suspense>
          </MicaShopClientProvider>
        </ApolloProvider>
      </RecoilRoot>
      <ToastContainer autoClose={10000} hideProgressBar />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
