// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: demo/micashop/value/v1/value.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { ApprovalType } from "../../../common/enums/approvaltype/v1/approval_type";
import { Unit } from "../../../common/enums/unit/v1/unit";
import { Error } from "../../../common/v1/error";

export const protobufPackage = "demo.micashop.value.v1";

export enum LineItemStatus {
  LINE_ITEM_STATUS_UNSPECIFIED = 0,
  LINE_ITEM_STATUS_NOT_ASSESSED = 1,
  LINE_ITEM_STATUS_ELIGIBLE = 2,
  LINE_ITEM_STATUS_INELIGIBLE = 3,
  LINE_ITEM_STATUS_AGE_RESTRICTED = 4,
}

export interface LineItem {
  /** unique number in a list of items that can be used to refer to a specific item */
  sequence: number;
  /** a header label that is shown on a receipt to group line items */
  lineItemGroup: string;
  productCode: string;
  description: string;
  /** an integer or decimal number */
  quantity: string;
  unit: Unit;
  unitAmount: string;
  unitTaxAmount: string;
  /**
   * The total amount for this line item excluding tax.
   * Amount expressed as: ([0-9]*[.])?[0-9]+
   */
  lineAmount: string;
  /**
   * The amount of tax for this line item.
   * Amount expressed as: ([0-9]*[.])?[0-9]+
   */
  lineTaxAmount: string;
}

export interface LineItemSequenceAndStatus {
  /** unique number in a list of items that can be used to refer to a specific item */
  sequence: number;
  status: LineItemStatus;
}

/** Type that represents the details of the value request (either to obtain or return). */
export interface ValueRequest {
  /** The UUEK of the instrument is either one specific to the partner or one provisioned by a Service Provider. */
  uuek: string;
  /**
   * Total amount for this transaction.
   *
   * When Items are present then: `total_amount` = sum(`items.amount + items.tax_amount`) + surcharge_amount
   * Amount expressed as: ([0-9]*[.])?[0-9]+
   */
  totalAmount: string;
  surchargeAmount: string;
  orderNumber: string;
  /** List of items bought or returned. */
  lineItems: LineItem[];
}

/** Type that represents the details of the value request (either to obtain or return). */
export interface ObtainValueRequest {
  approvalType: ApprovalType;
  value: ValueRequest | undefined;
}

export interface ObtainValueResponse {
  status: ObtainValueResponse_Status;
  error:
    | Error
    | undefined;
  /** Primary key of created transaction assigned by Mica. */
  transactionId: Uint8Array;
  /** Correlation ID of the request assigned by Mica. */
  cid: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  approvedAmount: string;
  /** summary of discounts that were applied */
  discountedAmount: string;
  appliedDiscounts: number;
  /**
   * status of each line item based on processing by Mica. The list will be in the same order as the request and will
   * will typically have a status of LINE_ITEM_STATUS_ELIGIBLE.
   */
  lineItemSequenceAndStatuses: LineItemSequenceAndStatus[];
}

export enum ObtainValueResponse_Status {
  STATUS_UNSPECIFIED = 0,
  /** STATUS_FULL_APPROVAL - An approval for the full amount */
  STATUS_FULL_APPROVAL = 1,
  /** STATUS_ERROR - An error occurred when processing the request */
  STATUS_ERROR = 2,
  /** STATUS_PARTIAL_APPROVAL - An approval was given for a partial amount */
  STATUS_PARTIAL_APPROVAL = 3,
  /** STATUS_INSUFFICIENT_VALUE - User instrument doesn't have sufficient value to cover the transaction */
  STATUS_INSUFFICIENT_VALUE = 4,
  /** STATUS_INELIGIBLE_PRODUCTS - When all products are either ineligible or age restricted */
  STATUS_INELIGIBLE_PRODUCTS = 5,
  /** STATUS_NOT_FOUND - Instrument/account not found */
  STATUS_NOT_FOUND = 6,
  /** STATUS_INSTRUMENT_CLOSED - User instrument is closed */
  STATUS_INSTRUMENT_CLOSED = 7,
  /** STATUS_TRANSACTION_RISK_FRAUD - Request triggered a transactional risk or fraud check resulting in a decline */
  STATUS_TRANSACTION_RISK_FRAUD = 8,
  /** STATUS_BAD_ACTOR_RISK_FRAUD - Request triggered a bad actor risk or fraud check resulting in a decline */
  STATUS_BAD_ACTOR_RISK_FRAUD = 16,
  /** STATUS_USER_RISK_FRAUD - Request triggered a user-based risk or fraud check resulting in a decline */
  STATUS_USER_RISK_FRAUD = 17,
  /** STATUS_INSTRUMENT_RISK_FRAUD - Request triggered a instrument-based risk or fraud check resulting in a decline */
  STATUS_INSTRUMENT_RISK_FRAUD = 18,
  /** STATUS_USER_CALL_SERVICE_PROVIDER - The user needs to call the Service Provider to resolve an issue */
  STATUS_USER_CALL_SERVICE_PROVIDER = 9,
  /** STATUS_UNSUPPORTED - The operation isn't supported by the Service Provider */
  STATUS_UNSUPPORTED = 10,
  /** STATUS_SERVICE_PROVIDER_UNAVAILABLE - The Fi is unavailable at this time */
  STATUS_SERVICE_PROVIDER_UNAVAILABLE = 11,
  /** STATUS_INVALID_UUEK - The UUEK sent is invalid or not found */
  STATUS_INVALID_UUEK = 12,
  /** STATUS_PIN_REQUIRED - A valid PIN is required for this transaction */
  STATUS_PIN_REQUIRED = 13,
  /** STATUS_MISSING_LINE_ITEMS - returned when processing an EBT transaction but no line items have been given */
  STATUS_MISSING_LINE_ITEMS = 14,
  /** STATUS_PARTIAL_APPROVAL_INVALID - when partial approval is requested but not supported */
  STATUS_PARTIAL_APPROVAL_INVALID = 15,
  /** STATUS_LIMIT_EXCEEDED - Request resulted in one or more limits being exceeded at the Service Provider */
  STATUS_LIMIT_EXCEEDED = 19,
  /** STATUS_DUPLICATE - This request has already been processed and this is a duplicate request */
  STATUS_DUPLICATE = 20,
  /** STATUS_ORGANIZATION_NOT_CONFIGURED - The referenced Organization of this request isn't fully configured to process transactions */
  STATUS_ORGANIZATION_NOT_CONFIGURED = 21,
  /** STATUS_LINE_ITEM_TOTAL_MISMATCH - The total amount of the line items does not match the total amount of the transaction */
  STATUS_LINE_ITEM_TOTAL_MISMATCH = 22,
}

export interface ReturnValueRequest {
  value:
    | ValueRequest
    | undefined;
  /**
   * A unique identifier at the Partner that represents the initial transaction in the set of transactions. This
   * would typically refer to the first Obtain transaction and passed in for Return transactions. The
   * Service Provider can then use this field to match up Returns with the original Obtain.
   */
  initialPartnerTransactionRef: string;
}

export interface ReturnValueResponse {
  status: ReturnValueResponse_Status;
  error:
    | Error
    | undefined;
  /** Primary key of created transaction assigned by Mica. */
  transactionId: Uint8Array;
  /** Correlation ID of the request assigned by Mica. */
  cid: string;
  /** Amount expressed as: ([0-9]*[.])?[0-9]+ */
  approvedAmount: string;
}

export enum ReturnValueResponse_Status {
  STATUS_UNSPECIFIED = 0,
  /** STATUS_FULL_APPROVAL - An approval for the full amount */
  STATUS_FULL_APPROVAL = 1,
  /** STATUS_ERROR - An error occurred when processing the request */
  STATUS_ERROR = 2,
  /** STATUS_NOT_FOUND - Instrument/account not found */
  STATUS_NOT_FOUND = 6,
  /** STATUS_INSTRUMENT_CLOSED - User instrument is closed */
  STATUS_INSTRUMENT_CLOSED = 7,
  /** STATUS_TRANSACTION_RISK_FRAUD - Request triggered a transactional risk or fraud check resulting in a decline */
  STATUS_TRANSACTION_RISK_FRAUD = 8,
  /** STATUS_BAD_ACTOR_RISK_FRAUD - Request triggered a bad actor risk or fraud check resulting in a decline */
  STATUS_BAD_ACTOR_RISK_FRAUD = 16,
  /** STATUS_USER_RISK_FRAUD - Request triggered a user-based risk or fraud check resulting in a decline */
  STATUS_USER_RISK_FRAUD = 17,
  /** STATUS_INSTRUMENT_RISK_FRAUD - Request triggered a instrument-based risk or fraud check resulting in a decline */
  STATUS_INSTRUMENT_RISK_FRAUD = 18,
  /** STATUS_USER_CALL_SERVICE_PROVIDER - The user needs to call the Service Provider to resolve an issue */
  STATUS_USER_CALL_SERVICE_PROVIDER = 9,
  /** STATUS_UNSUPPORTED - The operation isn't supported by the Service Provider */
  STATUS_UNSUPPORTED = 10,
  /** STATUS_SERVICE_PROVIDER_UNAVAILABLE - The Fi is unavailable at this time */
  STATUS_SERVICE_PROVIDER_UNAVAILABLE = 11,
  /** STATUS_INVALID_UUEK - The UUEK sent is invalid or not found */
  STATUS_INVALID_UUEK = 12,
  /** STATUS_PIN_REQUIRED - A valid PIN is required for this transaction */
  STATUS_PIN_REQUIRED = 13,
  /** STATUS_MISSING_LINE_ITEMS - returned when processing an EBT transaction but no line items have been given */
  STATUS_MISSING_LINE_ITEMS = 14,
  /** STATUS_PARTIAL_APPROVAL_INVALID - when partial approval is requested but not supported */
  STATUS_PARTIAL_APPROVAL_INVALID = 15,
  /** STATUS_LIMIT_EXCEEDED - Request resulted in one or more limits being exceeded at the Service Provider */
  STATUS_LIMIT_EXCEEDED = 19,
  /** STATUS_ORGANIZATION_NOT_CONFIGURED - The referenced Organization of this request isn't fully configured to process transactions */
  STATUS_ORGANIZATION_NOT_CONFIGURED = 20,
  /** STATUS_LINE_ITEM_TOTAL_MISMATCH - The total amount of the line items does not match the total amount of the transaction */
  STATUS_LINE_ITEM_TOTAL_MISMATCH = 21,
}

function createBaseLineItem(): LineItem {
  return {
    sequence: 0,
    lineItemGroup: "",
    productCode: "",
    description: "",
    quantity: "",
    unit: 0,
    unitAmount: "",
    unitTaxAmount: "",
    lineAmount: "",
    lineTaxAmount: "",
  };
}

export const LineItem = {
  encode(message: LineItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sequence !== 0) {
      writer.uint32(8).int32(message.sequence);
    }
    if (message.lineItemGroup !== "") {
      writer.uint32(18).string(message.lineItemGroup);
    }
    if (message.productCode !== "") {
      writer.uint32(26).string(message.productCode);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.quantity !== "") {
      writer.uint32(42).string(message.quantity);
    }
    if (message.unit !== 0) {
      writer.uint32(48).int32(message.unit);
    }
    if (message.unitAmount !== "") {
      writer.uint32(58).string(message.unitAmount);
    }
    if (message.unitTaxAmount !== "") {
      writer.uint32(66).string(message.unitTaxAmount);
    }
    if (message.lineAmount !== "") {
      writer.uint32(74).string(message.lineAmount);
    }
    if (message.lineTaxAmount !== "") {
      writer.uint32(82).string(message.lineTaxAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sequence = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lineItemGroup = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.quantity = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.unit = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.unitAmount = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.unitTaxAmount = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.lineAmount = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lineTaxAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LineItem>, I>>(base?: I): LineItem {
    return LineItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LineItem>, I>>(object: I): LineItem {
    const message = createBaseLineItem();
    message.sequence = object.sequence ?? 0;
    message.lineItemGroup = object.lineItemGroup ?? "";
    message.productCode = object.productCode ?? "";
    message.description = object.description ?? "";
    message.quantity = object.quantity ?? "";
    message.unit = object.unit ?? 0;
    message.unitAmount = object.unitAmount ?? "";
    message.unitTaxAmount = object.unitTaxAmount ?? "";
    message.lineAmount = object.lineAmount ?? "";
    message.lineTaxAmount = object.lineTaxAmount ?? "";
    return message;
  },
};

function createBaseLineItemSequenceAndStatus(): LineItemSequenceAndStatus {
  return { sequence: 0, status: 0 };
}

export const LineItemSequenceAndStatus = {
  encode(message: LineItemSequenceAndStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sequence !== 0) {
      writer.uint32(8).int32(message.sequence);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItemSequenceAndStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItemSequenceAndStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sequence = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LineItemSequenceAndStatus>, I>>(base?: I): LineItemSequenceAndStatus {
    return LineItemSequenceAndStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LineItemSequenceAndStatus>, I>>(object: I): LineItemSequenceAndStatus {
    const message = createBaseLineItemSequenceAndStatus();
    message.sequence = object.sequence ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseValueRequest(): ValueRequest {
  return { uuek: "", totalAmount: "", surchargeAmount: "", orderNumber: "", lineItems: [] };
}

export const ValueRequest = {
  encode(message: ValueRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.uuek !== "") {
      writer.uint32(10).string(message.uuek);
    }
    if (message.totalAmount !== "") {
      writer.uint32(74).string(message.totalAmount);
    }
    if (message.surchargeAmount !== "") {
      writer.uint32(58).string(message.surchargeAmount);
    }
    if (message.orderNumber !== "") {
      writer.uint32(82).string(message.orderNumber);
    }
    for (const v of message.lineItems) {
      LineItem.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ValueRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.uuek = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.totalAmount = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.surchargeAmount = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.orderNumber = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.lineItems.push(LineItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ValueRequest>, I>>(base?: I): ValueRequest {
    return ValueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ValueRequest>, I>>(object: I): ValueRequest {
    const message = createBaseValueRequest();
    message.uuek = object.uuek ?? "";
    message.totalAmount = object.totalAmount ?? "";
    message.surchargeAmount = object.surchargeAmount ?? "";
    message.orderNumber = object.orderNumber ?? "";
    message.lineItems = object.lineItems?.map((e) => LineItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseObtainValueRequest(): ObtainValueRequest {
  return { approvalType: 0, value: undefined };
}

export const ObtainValueRequest = {
  encode(message: ObtainValueRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.approvalType !== 0) {
      writer.uint32(8).int32(message.approvalType);
    }
    if (message.value !== undefined) {
      ValueRequest.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObtainValueRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObtainValueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.approvalType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = ValueRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ObtainValueRequest>, I>>(base?: I): ObtainValueRequest {
    return ObtainValueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObtainValueRequest>, I>>(object: I): ObtainValueRequest {
    const message = createBaseObtainValueRequest();
    message.approvalType = object.approvalType ?? 0;
    message.value = (object.value !== undefined && object.value !== null)
      ? ValueRequest.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseObtainValueResponse(): ObtainValueResponse {
  return {
    status: 0,
    error: undefined,
    transactionId: new Uint8Array(0),
    cid: "",
    approvedAmount: "",
    discountedAmount: "",
    appliedDiscounts: 0,
    lineItemSequenceAndStatuses: [],
  };
}

export const ObtainValueResponse = {
  encode(message: ObtainValueResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.transactionId.length !== 0) {
      writer.uint32(26).bytes(message.transactionId);
    }
    if (message.cid !== "") {
      writer.uint32(34).string(message.cid);
    }
    if (message.approvedAmount !== "") {
      writer.uint32(42).string(message.approvedAmount);
    }
    if (message.discountedAmount !== "") {
      writer.uint32(58).string(message.discountedAmount);
    }
    if (message.appliedDiscounts !== 0) {
      writer.uint32(64).int32(message.appliedDiscounts);
    }
    for (const v of message.lineItemSequenceAndStatuses) {
      LineItemSequenceAndStatus.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ObtainValueResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseObtainValueResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transactionId = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.approvedAmount = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.discountedAmount = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.appliedDiscounts = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lineItemSequenceAndStatuses.push(LineItemSequenceAndStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ObtainValueResponse>, I>>(base?: I): ObtainValueResponse {
    return ObtainValueResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ObtainValueResponse>, I>>(object: I): ObtainValueResponse {
    const message = createBaseObtainValueResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.transactionId = object.transactionId ?? new Uint8Array(0);
    message.cid = object.cid ?? "";
    message.approvedAmount = object.approvedAmount ?? "";
    message.discountedAmount = object.discountedAmount ?? "";
    message.appliedDiscounts = object.appliedDiscounts ?? 0;
    message.lineItemSequenceAndStatuses =
      object.lineItemSequenceAndStatuses?.map((e) => LineItemSequenceAndStatus.fromPartial(e)) || [];
    return message;
  },
};

function createBaseReturnValueRequest(): ReturnValueRequest {
  return { value: undefined, initialPartnerTransactionRef: "" };
}

export const ReturnValueRequest = {
  encode(message: ReturnValueRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== undefined) {
      ValueRequest.encode(message.value, writer.uint32(10).fork()).ldelim();
    }
    if (message.initialPartnerTransactionRef !== "") {
      writer.uint32(26).string(message.initialPartnerTransactionRef);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReturnValueRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReturnValueRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = ValueRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.initialPartnerTransactionRef = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ReturnValueRequest>, I>>(base?: I): ReturnValueRequest {
    return ReturnValueRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReturnValueRequest>, I>>(object: I): ReturnValueRequest {
    const message = createBaseReturnValueRequest();
    message.value = (object.value !== undefined && object.value !== null)
      ? ValueRequest.fromPartial(object.value)
      : undefined;
    message.initialPartnerTransactionRef = object.initialPartnerTransactionRef ?? "";
    return message;
  },
};

function createBaseReturnValueResponse(): ReturnValueResponse {
  return { status: 0, error: undefined, transactionId: new Uint8Array(0), cid: "", approvedAmount: "" };
}

export const ReturnValueResponse = {
  encode(message: ReturnValueResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.transactionId.length !== 0) {
      writer.uint32(26).bytes(message.transactionId);
    }
    if (message.cid !== "") {
      writer.uint32(34).string(message.cid);
    }
    if (message.approvedAmount !== "") {
      writer.uint32(42).string(message.approvedAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReturnValueResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReturnValueResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.transactionId = reader.bytes();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.approvedAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ReturnValueResponse>, I>>(base?: I): ReturnValueResponse {
    return ReturnValueResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReturnValueResponse>, I>>(object: I): ReturnValueResponse {
    const message = createBaseReturnValueResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.transactionId = object.transactionId ?? new Uint8Array(0);
    message.cid = object.cid ?? "";
    message.approvedAmount = object.approvedAmount ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
