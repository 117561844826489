// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: micashared/common/enums/region/v1/region.proto

/* eslint-disable */

export const protobufPackage = "micashared.common.enums.region.v1";

/** ISO 3166-2 */
export enum Region {
  REGION_UNSPECIFIED = 0,
  /** REGION_US_AL - Alabama (state) */
  REGION_US_AL = 1,
  /** REGION_US_AK - Alaska (state) */
  REGION_US_AK = 2,
  /** REGION_US_AZ - Arizona (state) */
  REGION_US_AZ = 3,
  /** REGION_US_AR - Arkansas (state) */
  REGION_US_AR = 4,
  /** REGION_US_CA - California (state) */
  REGION_US_CA = 5,
  /** REGION_US_CO - Colorado (state) */
  REGION_US_CO = 6,
  /** REGION_US_CT - Connecticut (state) */
  REGION_US_CT = 7,
  /** REGION_US_DE - Delaware (state) */
  REGION_US_DE = 8,
  /** REGION_US_FL - Florida (state) */
  REGION_US_FL = 9,
  /** REGION_US_GA - Georgia (state) */
  REGION_US_GA = 10,
  /** REGION_US_HI - Hawaii (state) */
  REGION_US_HI = 11,
  /** REGION_US_ID - Idaho (state) */
  REGION_US_ID = 12,
  /** REGION_US_IL - Illinois (state) */
  REGION_US_IL = 13,
  /** REGION_US_IN - Indiana (state) */
  REGION_US_IN = 14,
  /** REGION_US_IA - Iowa (state) */
  REGION_US_IA = 15,
  /** REGION_US_KS - Kansas (state) */
  REGION_US_KS = 16,
  /** REGION_US_KY - Kentucky (state) */
  REGION_US_KY = 17,
  /** REGION_US_LA - Louisiana (state) */
  REGION_US_LA = 18,
  /** REGION_US_ME - Maine (state) */
  REGION_US_ME = 19,
  /** REGION_US_MD - Maryland (state) */
  REGION_US_MD = 20,
  /** REGION_US_MA - Massachusetts (state) */
  REGION_US_MA = 21,
  /** REGION_US_MI - Michigan (state) */
  REGION_US_MI = 22,
  /** REGION_US_MN - Minnesota (state) */
  REGION_US_MN = 23,
  /** REGION_US_MS - Mississippi (state) */
  REGION_US_MS = 24,
  /** REGION_US_MO - Missouri (state) */
  REGION_US_MO = 25,
  /** REGION_US_MT - Montana (state) */
  REGION_US_MT = 26,
  /** REGION_US_NE - Nebraska (state) */
  REGION_US_NE = 27,
  /** REGION_US_NV - Nevada (state) */
  REGION_US_NV = 28,
  /** REGION_US_NH - New Hampshire (state) */
  REGION_US_NH = 29,
  /** REGION_US_NJ - New Jersey (state) */
  REGION_US_NJ = 30,
  /** REGION_US_NM - New Mexico (state) */
  REGION_US_NM = 31,
  /** REGION_US_NY - New York (state) */
  REGION_US_NY = 32,
  /** REGION_US_NC - North Carolina (state) */
  REGION_US_NC = 33,
  /** REGION_US_ND - North Dakota (state) */
  REGION_US_ND = 34,
  /** REGION_US_OH - Ohio (state) */
  REGION_US_OH = 35,
  /** REGION_US_OK - Oklahoma (state) */
  REGION_US_OK = 36,
  /** REGION_US_OR - Oregon (state) */
  REGION_US_OR = 37,
  /** REGION_US_PA - Pennsylvania (state) */
  REGION_US_PA = 38,
  /** REGION_US_RI - Rhode Island (state) */
  REGION_US_RI = 39,
  /** REGION_US_SC - South Carolina (state) */
  REGION_US_SC = 40,
  /** REGION_US_SD - South Dakota (state) */
  REGION_US_SD = 41,
  /** REGION_US_TN - Tennessee (state) */
  REGION_US_TN = 42,
  /** REGION_US_TX - Texas (state) */
  REGION_US_TX = 43,
  /** REGION_US_UT - Utah (state) */
  REGION_US_UT = 44,
  /** REGION_US_VT - Vermont (state) */
  REGION_US_VT = 45,
  /** REGION_US_VA - Virginia (state) */
  REGION_US_VA = 46,
  /** REGION_US_WA - Washington (state) */
  REGION_US_WA = 47,
  /** REGION_US_WV - West Virginia (state) */
  REGION_US_WV = 48,
  /** REGION_US_WI - Wisconsin (state) */
  REGION_US_WI = 49,
  /** REGION_US_WY - Wyoming (state) */
  REGION_US_WY = 50,
  /** REGION_US_DC - District of Columbia (district) */
  REGION_US_DC = 51,
  /** REGION_US_AS - American Samoa */
  REGION_US_AS = 52,
  /** REGION_US_GU - Guam */
  REGION_US_GU = 53,
  /** REGION_US_MP - Northern Mariana Islands */
  REGION_US_MP = 54,
  /** REGION_US_PR - Puerto Rico */
  REGION_US_PR = 55,
  /** REGION_US_UM - United States Minor Outlying Islands */
  REGION_US_UM = 56,
  /** REGION_US_VI - U.S. Virgin Islands */
  REGION_US_VI = 57,
  /** REGION_JM_01 - Kingston */
  REGION_JM_01 = 58,
  /** REGION_JM_02 - Saint Andrew */
  REGION_JM_02 = 59,
  /** REGION_JM_03 - Saint Thomas */
  REGION_JM_03 = 60,
  /** REGION_JM_04 - Portland */
  REGION_JM_04 = 61,
  /** REGION_JM_05 - Saint Mary */
  REGION_JM_05 = 62,
  /** REGION_JM_06 - Saint Ann */
  REGION_JM_06 = 63,
  /** REGION_JM_07 - Trelawny */
  REGION_JM_07 = 64,
  /** REGION_JM_08 - Saint James */
  REGION_JM_08 = 65,
  /** REGION_JM_09 - Hanover */
  REGION_JM_09 = 66,
  /** REGION_JM_10 - Westmoreland */
  REGION_JM_10 = 67,
  /** REGION_JM_11 - Saint Elizabeth */
  REGION_JM_11 = 68,
  /** REGION_JM_12 - Manchester */
  REGION_JM_12 = 69,
  /** REGION_JM_13 - Clarendon */
  REGION_JM_13 = 70,
  /** REGION_JM_14 - Saint Catherine */
  REGION_JM_14 = 71,
}
