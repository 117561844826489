import { useState } from 'react';
import { Realm } from 'mica-proto/src/micashared/common/enums/realm/v1/realm';
import { useRecoilState } from 'recoil';
import { authState } from '../state/authState';
import { useSetTokenRefreshed } from 'mica-ui-kit/src/hooks/auth/useTokenExpiryHandler/useTokenExpiryHandler';
import { auth as authObject } from '../client/client';
import { useMicaShopClient } from './useMicaShopClient';

export const useAuth = () => {
  const [auth, setAuth] = useRecoilState(authState);
  const [loading, setLoading] = useState(false);
  const client = useMicaShopClient();

  // const setTokenIsRefreshed = useSetTokenRefreshed();

  const login = async (username: string, password: string) => {
    setLoading(true);

    try {
      const response = await client.authenticate({
        username,
        password,
        realm: Realm.REALM_USER,
      });

      if (response?.token) {
        setAuth((a) => ({
          ...a,
          isAuthenticated: true,
          token: response.token,
          user: username,
          showAuthScreen: false,
        }));
        // setTokenIsRefreshed();
      }
      setLoading(false);
      return response;
    } catch (error) {
      console.error(error);
      setLoading(false);
      return { error };
    }
  };

  const logout = () => {
    setAuth({
      isAuthenticated: false,
      token: undefined,
      user: '',
      showAuthScreen: false,
    });
    authObject.token = '';
  };

  const showAuth = () =>
    setAuth((a) => ({
      ...a,
      showAuthScreen: true,
    }));

  const dismissAuth = () =>
    setAuth((a) => ({
      ...a,
      showAuthScreen: false,
    }));

  return {
    ...auth,
    dismissAuth,
    loading,
    login,
    logout,
    showAuth,
  };
};
