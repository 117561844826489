import * as React from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { shoppingCartQuantityState } from 'corner-store-shared/src/state/shoppingCartState';
import styles from './ShoppingCartButton.module.scss';

export const ShoppingCartButton = () => {
  const shoppingCartQuantity = useRecoilValue(shoppingCartQuantityState);

  return (
    <Link id={'ShoppingCartButton'} to={'/cart'}>
      <div className={styles.container}>
        {!!shoppingCartQuantity && (
          <div
            id={'ShoppingCartCount'}
            data-testid={'ShoppingCartCount'}
            className={styles.quantity}>
            {shoppingCartQuantity}
          </div>
        )}
        <AiOutlineShoppingCart size={25} />
      </div>
    </Link>
  );
};
