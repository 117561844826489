import * as React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import { Nav } from '../components/Nav/Nav';
import { ScrollToTop } from '../components/ScrollToTop';
import styles from './Container.module.scss';
import CenteredContent from '../components/CenteredContent/CenteredContent';

export const Container = () => {
  const { pathname } = useLocation();

  return (
    <div className={styles.container}>
      <ScrollToTop />
      {pathname !== '/productList' && <Nav />}
      <CenteredContent contentClassName={styles.content}>
        <Outlet />
      </CenteredContent>
    </div>
  );
};
