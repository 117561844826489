import React from 'react';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import { TextInput } from 'mica-ui-kit/src/components/inputs/text/TextInput/TextInput';
import { AddressForm } from 'mica-ui-kit/src/components/layout/forms/AddressForm/AddressForm';
import { Button } from '../../Button/Button';
import { UserDemographic } from 'micareference-proto/src/demo/common/v1/user';

type UserFormProps = {
  buttonLabel: string;
  isLoading?: boolean;
  onButtonClicked: (data: UserDemographic) => void;
  userDemographic?: UserDemographic;
};

/**
 A Modal containing a form to update the users name and address (UpdateUser)
 */
const UserForm = ({
  buttonLabel,
  isLoading,
  onButtonClicked,
  userDemographic,
}: UserFormProps) => {
  const { displayData, updateProperty } = useCreateUpdateForm(
    userDemographic ?? ({} as UserDemographic)
  );

  const handleButtonClicked = () => onButtonClicked(displayData);

  return (
    <>
      <TextInput
        onChange={updateProperty('firstName')}
        value={displayData.firstName}
        label={'First Name'}
      />
      <TextInput
        className={'mt-5'}
        onChange={updateProperty('lastName')}
        value={displayData.lastName}
        label={'Last Name'}
      />
      <TextInput
        className={'mt-5'}
        onChange={updateProperty('phone')}
        value={displayData.phone}
        label={'Phone'}
      />
      <TextInput
        className={'mt-5 mb-20'}
        onChange={updateProperty('email')}
        value={displayData.email}
        label={'Email'}
      />
      <AddressForm
        address={displayData.address}
        onChange={updateProperty('address')}
      />
      <div className={'mt-4 flex w-full justify-center'}>
        <Button loading={isLoading} onClick={handleButtonClicked}>
          {buttonLabel}
        </Button>
      </div>
    </>
  );
};

export default UserForm;
