import * as React from 'react';
import { SignInOrUp } from '../../components/auth/SignInOrUp/SignInOrUp';
import { useAuth } from 'corner-store-shared/src/hooks/useAuth';
import styles from './Authentication.module.scss';
import { Modal } from 'mica-ui-kit/src/components/layout/Modal/Modal';

export const Authentication = () => {
  const { showAuthScreen, dismissAuth } = useAuth();

  return (
    <Modal
      active={showAuthScreen}
      onDismiss={dismissAuth}
      title={'Authentication'}>
      <div className={styles.container}>
        <SignInOrUp />
      </div>
    </Modal>
  );
};
