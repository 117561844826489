import React, { PropsWithChildren } from 'react';
import { useTokenIsExpired } from '../../../hooks/auth/useTokenExpiryHandler/useTokenExpiryHandler';
import { Modal } from '../../layout';

/**
 The modal we display in our reference apps when a user's token has expired (server errors with token expired)
 */
export const TokenIsExpiredModal = ({ children }: PropsWithChildren<any>) => {
  const tokenIsExpired = useTokenIsExpired();

  return (
    <Modal active={tokenIsExpired}>
      <div>
        <div className={'p-10'}>
          Your session has expired, please sign in again.
        </div>
        {children}
      </div>
    </Modal>
  );
};
