import { createContextHook } from 'mica-shared-react/src/utility/context/createContextHook';
import { createContext } from 'react';
import { MicaShopService } from 'micareference-proto/src/demo/micashop/service/v1/micashop_service';

export const MicaShopClientContext = createContext<MicaShopService | undefined>(
  undefined
);

export const useMicaShopClient = createContextHook(
  MicaShopClientContext,
  'MicaShopClientContext',
  'useMicaShopClient'
);
