// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v4.24.4
// source: micashared/common/enums/realm/v1/realm.proto

/* eslint-disable */

export const protobufPackage = "micashared.common.enums.realm.v1";

export enum Realm {
  REALM_UNSPECIFIED = 0,
  REALM_ADMIN = 1,
  REALM_SERVICE = 2,
  REALM_USER = 3,
}
