import React, { useEffect, useState } from 'react';

import { TextInput } from 'mica-ui-kit/src/components/inputs/text/TextInput/TextInput';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';
import { toast } from 'react-toastify';
import { useResetPassword } from 'corner-store-shared/src/hooks/user/useResetPassword';

type SigninProps = {
  onToggleSignin: () => void;
};

export const ForgotPassword = ({ onToggleSignin }: SigninProps) => {
  const [email, setEmail] = useState('');

  const { mutate: forgotPassword, isLoading, data } = useResetPassword();

  const handleKeyPress = (e: any) =>
    e.key === 'Enter' && handleForgotPassword();

  const handleForgotPassword = async () =>
    forgotPassword(email.includes('@') ? { email } : { username: email });

  useEffect(() => {
    data?.status === 1 &&
      toast.success(`An email has been sent to you with a new password`);
  }, [data]);

  return (
    <>
      <div>Enter your username or email to be sent a new password</div>
      <TextInput
        className="mb-15 w-full"
        onChange={setEmail}
        onKeyDown={handleKeyPress}
        value={email}
        label={'Email or Username'}
      />
      <Button
        className="w-full mb-20"
        loading={isLoading}
        onClick={handleForgotPassword}>
        Submit
      </Button>
      <Button className="mb-20" onClick={onToggleSignin}>
        Return to Sign In
      </Button>
    </>
  );
};
