import React, { useEffect, useState } from 'react';
import { isBetween } from 'mica-utilities/src/numbers/numbers';
import { Country } from 'mica-proto/src/micashared/common/enums/country/v1/country';
import { Region } from 'mica-proto/src/micashared/common/enums/region/v1/region';
import { UserDemographic } from 'mica-proto/src/micashared/common/v1/user';
import { TextInput } from 'mica-ui-kit/src/components/inputs/text/TextInput/TextInput';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';
import { AddressForm } from 'mica-ui-kit/src/components/layout/forms/AddressForm/AddressForm';
import { allPass, assocPath } from 'ramda';
import { useAuth } from 'corner-store-shared/src/hooks/useAuth';
import { useCreateUser } from 'corner-store-shared/src/hooks/user/useCreateUser';
import styles from './Signup.module.css';
import { toast } from 'react-toastify';
import { asE164Phone } from 'mica-utilities/src/formatters/phone/phone';
import { parsedError } from 'mica-utilities/src/formatters/error/error';

const capitalize = (func: (text: string) => void) => (text: string) =>
  func(text.charAt(0).toUpperCase() + text.substring(1));

type SignUpProps = {
  onToggleSignin: () => void;
};

const defaultCreds = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  address: {
    streetLines: [''],
    locality: '',
    region: '' as Region | string,
    postalCode: '',
    country: Country.COUNTRY_US,
  },
  username: '',
  password: '',
};

const minLengthInfo = (minLength: number, value: string) =>
  isBetween(0, minLength, value.length)
    ? `Must be at least ${minLength} characters`
    : '';

const credsAreValid = allPass([
  (creds) => creds.firstName.length > 2,
  (creds) => creds.lastName.length > 1,
  (creds) => !!creds.email,
  (creds) => !!creds.address.streetLines[0],
  (creds) => !!creds.address.locality,
  (creds) => creds.address.region !== Region.REGION_UNSPECIFIED,
  (creds) => !!creds.address.postalCode,
  (creds) => creds.username.length > 2,
  (creds) => creds.password.length > 5,
]);

const Signup = ({ onToggleSignin }: SignUpProps) => {
  const [creds, setCreds] = useState<Record<string, any>>(defaultCreds);

  const { login, loading } = useAuth();
  const { mutate: createUser, isLoading } = useCreateUser();

  const handleSetCreds = (path: (string | number)[]) => (value: string) =>
    setCreds(assocPath(path, value));

  const handlePasswordKeyPress = (e: any) =>
    e.key === 'Enter' && handleSignUp();

  const handleSignUp = async () => {
    const { username, password, ...rest } = creds;

    await createUser(
      {
        userDemographic: {
          ...rest,
          phone: asE164Phone(rest.phone),
        },
        username,
        password,
      },
      {
        onSuccess: () => {
          login(creds.username, 'password01');
        },
        onError: (error: unknown) => {
          const strError = parsedError(error);
          toast.error(strError);
        },
      }
    );
  };

  const handleCopySignupCreds = () => {
    navigator?.clipboard?.writeText(JSON.stringify(creds)).then(() => {
      toast.success('Copied to clipboard');
    });
  };

  const handlePasteSignupCreds = async () => {
    const clipboardCreds = await navigator.clipboard.readText();
    if (clipboardCreds.match('address')) {
      setCreds(JSON.parse(clipboardCreds));
    } else {
      alert('clipboard data does not match required format');
    }
  };

  // This may be able to be changed to useCreateUpdateForm.ts, but I'm hesitant to change it
  const updateProperty =
    (property: keyof Record<string, any>) => (value: any) =>
      setCreds((u) => ({
        ...u,
        [property]: value,
      }));

  const handleStoreSignupCreds = () =>
    localStorage.setItem('LAST_SIGNUP_CREDS', JSON.stringify(creds));

  const handleRestoreSignupCreds = () => {
    const lastCreds = localStorage.getItem('LAST_SIGNUP_CREDS');
    lastCreds && setCreds(JSON.parse(lastCreds));
  };

  const handleKeyPress = ({ key, ctrlKey }: any) => {
    if (ctrlKey) {
      key === 's' && handleStoreSignupCreds();
      key === 'w' && handleRestoreSignupCreds();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [creds]);

  return (
    <form className={'w-full flex col align-ctr'} name={'SignUpForm'}>
      <strong className="text-align-ctr w-full">
        Sign up by providing the details below
      </strong>
      <div className="w-full flex justify-ctr my-10">
        <button
          type={'button'}
          className={styles.clipboardButton}
          onClick={handlePasteSignupCreds}>
          PASTE FROM CLIPBOARD
        </button>
      </div>
      <TextInput
        error={minLengthInfo(3, creds.firstName)}
        className="mb-5 mt-30 w-full"
        name={'firstName'}
        onChange={capitalize(handleSetCreds(['firstName']))}
        value={creds.firstName}
        label={'First Name*'}
      />
      <TextInput
        error={minLengthInfo(2, creds.lastName)}
        className="my-5 w-full"
        name={'lastName'}
        onChange={capitalize(handleSetCreds(['lastName']))}
        value={creds.lastName}
        label={'Last Name*'}
      />
      <TextInput
        className="my-5 w-full"
        name={'phone'}
        onChange={handleSetCreds(['phone'])}
        value={creds.phone}
        label={'Phone*'}
      />
      <TextInput
        className="my-5 w-full"
        name={'email'}
        onChange={handleSetCreds(['email'])}
        value={creds.email}
        label={'Email*'}
        type="email"
      />
      <AddressForm
        address={creds.address}
        onChange={updateProperty('address')}
      />
      <TextInput
        autoCapitalize={'none'}
        autoCorrect="off"
        className="mb-2 mt-30 w-full"
        name={'username'}
        onChange={handleSetCreds(['username'])}
        value={creds.username}
        label={'UserName*'}
        error={minLengthInfo(3, creds.username)}
      />
      <TextInput
        className="mt-2 mb-20 w-full"
        name={'password'}
        onChange={handleSetCreds(['password'])}
        onKeyPress={handlePasswordKeyPress}
        value={creds.password}
        label={'Password*'}
        type="password"
        error={minLengthInfo(6, creds.password)}
      />
      <div className="w-full flex justify-ctr my-10">
        <button
          type={'button'}
          className={styles.clipboardButton}
          onClick={handleCopySignupCreds}>
          COPY TO CLIPBOARD
        </button>
      </div>
      <Button
        type={'button'}
        className="w-full mb-20"
        id={'SignUpButton'}
        disabled={!credsAreValid(creds)}
        loading={loading || isLoading}
        onClick={handleSignUp}>
        Sign Up
      </Button>
      <div className="mt-20 mb-10">Already a user?</div>
      <Button type={'button'} className="mb-20" onClick={onToggleSignin}>
        Sign In
      </Button>
    </form>
  );
};

export default Signup;
