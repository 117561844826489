import { gql, useQuery } from '@apollo/client';

export interface CmsCategory {
  image: {
    url: string;
  };
  name: string;
  parentCategory?: {
    sys: {
      id: string;
    };
  };
  sys: {
    id: string;
  };
}

export interface Category {
  id: string;
  name: string;
  imageUrl: string;
  parentCategory?: string | null;
}

export const categoryFragment = gql`
  fragment CategoryFragment on Category {
    sys {
      id
    }
    name
    parentCategory {
      sys {
        id
      }
    }
    image {
      url
    }
  }
`;

const categoriesQuery = gql`
  ${categoryFragment}
  query categoryCollection {
    categoryCollection {
      items {
        ...CategoryFragment
      }
    }
  }
`;

export const useCategories = () => {
  const { data, ...rest } = useQuery<{
    categoryCollection: { items: CmsCategory[] };
  }>(categoriesQuery);

  return {
    categories: (data?.categoryCollection?.items || []).map(parseCategory),
    ...rest,
  };
};

export const parseCategory = (category: CmsCategory): Category => ({
  ...category,
  id: category.sys.id,
  parentCategory: category.parentCategory?.sys?.id,
  imageUrl: category.image?.url,
});
