import { Country } from 'mica-proto/src/micashared/common/enums/country/v1/country';
import { Region } from 'mica-proto/src/micashared/common/enums/region/v1/region';
import { Address } from 'mica-proto/src/micashared/common/v1/address';
import { assocPath } from 'ramda';
import React, { useState } from 'react';
import {
  enumAsCountrySelectOptions,
  enumAsRegionSelectOptions,
} from '../../../../utils';
import { Select, TextInput } from '../../../inputs';
import { SelectOption } from '../../../inputs/Select/Select';

type AddressFormProps = {
  address?: Partial<Address>;
  onChange: (address: Partial<Address>) => void;
};

const getRegionOptions = (country: Country) => {
  const countryIso = Country[country];
  if (!countryIso) return [];
  const locale = countryIso.replace('COUNTRY_', '');
  return enumAsRegionSelectOptions(
    Region,
    `REGION_`,
    locale.toUpperCase()
  ).filter((x) => x.label.indexOf('UNSPECIFIED') === -1);
};

const locale = navigator.languages.length > 1 ? navigator.languages[1] : 'en';
const countryOptions = enumAsCountrySelectOptions(
  Country,
  'COUNTRY_',
  locale
).filter((x) => typeof x.label !== 'undefined') as SelectOption[];

/**
 The inputs required for an address in our popover forms
 */
export const AddressForm = ({ address = {}, onChange }: AddressFormProps) => {
  const [availableRegions, setAvailableRegions] = useState(
    getRegionOptions(address?.country ?? Country.COUNTRY_UNSPECIFIED)
  );
  const handleChange = (path: string[]) => (value: any) =>
    onChange(assocPath(path, value, address));

  const handleCountryChange = (path: string[]) => (value: any) => {
    setAvailableRegions(getRegionOptions(value));
    return onChange(assocPath(path, value, address));
  };

  const handleChangeStreetLine1 = (value: string) =>
    onChange({
      ...address,
      streetLines: [value, address.streetLines?.[1] || ''],
    });

  const handleChangeStreetLine2 = (value: string) =>
    onChange({
      ...address,
      streetLines: [address.streetLines?.[0] || '', value],
    });

  return (
    <>
      <TextInput
        name={'streetLine1'}
        autoCapitalize={'words'}
        onChange={handleChangeStreetLine1}
        value={address.streetLines?.[0] || ''}
        label={'Address Line 1'}
      />
      <TextInput
        className={'mt-5'}
        name={'streetLine2'}
        autoCapitalize={'words'}
        onChange={handleChangeStreetLine2}
        value={address.streetLines?.[1] || ''}
        label={'Address Line 2'}
      />
      <TextInput
        className={'mt-5'}
        name={'city'}
        autoCapitalize={'words'}
        onChange={handleChange(['locality'])}
        value={address.locality || ''}
        label={'Locality'}
      />
      <div
        className={'flex mt-5'}
        style={{ maxWidth: 300, justifyContent: 'space-between' }}>
        <Select
          className={'mr-10'}
          name={'region'}
          label={'Region'}
          value={address.region || ''}
          onChange={handleChange(['region'])}
          options={availableRegions}
          style={{ width: 150 }}
        />
        <TextInput
          className={'ml-10'}
          name={'postalCode'}
          onChange={handleChange(['postalCode'])}
          style={{ width: 130 }}
          value={address.postalCode || ''}
          label={'Postal Code'}
        />
      </div>
      <Select
        options={countryOptions}
        value={address?.country || ''}
        className="mb-2 mt-10"
        label={'Country'}
        name={'country'}
        style={{
          width: '300px',
        }}
        onChange={handleCountryChange(['country'])}
      />
    </>
  );
};
