import { useMutation, useQuery } from 'react-query';
import { useMicaShopClient } from '../useMicaShopClient';

/**
 * A hook for getting the user demographics using a zero trust code
 */
export const useFetchUserDataFromBankLinkingCode = () => {
  const client = useMicaShopClient();

  // const [bankLinkingCode, setBankLinkingCode] = useState('');
  //
  // return {
  //   bankLinkingCode,
  //   setBankLinkingCode,
  //   ...useQuery(
  //     ['fetchUserDataFromBankLinkingCode', bankLinkingCode],
  //     () =>
  //       client.fetchUserDataFromBankLinkingCode({
  //         linkingCode: bankLinkingCode,
  //       }),
  //     {
  //       enabled: bankLinkingCode.length === 8,
  //     }
  //   ),
  // };
  return useMutation(client.fetchUserDataFromBankLinkingCode);
};
