import { atom, useRecoilState } from 'recoil';
import { useUser } from '../../user/useUser';
import { Country } from 'mica-proto/src/micashared/common/enums/country/v1/country';
import { Region } from 'mica-proto/src/micashared/common/enums/region/v1/region';
import { useEffect } from 'react';

export type DeliveryDetails = {
  firstName: string;
  lastName: string;
  email: string;
  address?: {
    streetLines: string[];
    locality: string;
    region: Region;
    postalCode: string;
    country: Country;
  };
  phone: string;
};
export const manualDeliveryDetailsState = atom<DeliveryDetails | null>({
  key: 'manualDeliveryDetailsState',
  default: null,
});

export const deliveryFeeState = atom({
  key: 'deliveryFeeState',
  default: 0,
});

export const useDeliveryDetails = () => {
  const [deliveryDetails, setDeliveryDetails] = useRecoilState(
    manualDeliveryDetailsState
  );

  const [deliveryFee, setDeliveryFee] = useRecoilState(deliveryFeeState);

  const { user } = useUser();

  const details = deliveryDetails ?? user?.userDemographic;

  useEffect(() => {
    details && setDeliveryFee(15);
  }, [!!details]);

  return {
    deliveryDetails: details,
    deliveryFee,
    resetDeliveryDetails: () => setDeliveryDetails(null),
    resetDeliveryFee: () => setDeliveryFee(0),
    setDeliveryDetails,
  };
};
