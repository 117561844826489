import { UserDemographic } from 'micareference-proto/src/demo/common/v1/user';
import UserForm from '../../../User/UserForm/UserForm';

type BankSignupUserFormProps = {
  onContinue: (userDemographic: UserDemographic) => void;
  userDemographic: UserDemographic;
};

/** A Component */
const BankSignupUserForm = ({
  onContinue,
  userDemographic,
}: BankSignupUserFormProps) => {
  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        Please confirm the contact details provided by your bank:
      </div>
      <UserForm
        buttonLabel={'Continue'}
        onButtonClicked={onContinue}
        userDemographic={userDemographic}
      />
    </div>
  );
};

export default BankSignupUserForm;
