import { useMutation } from 'react-query';

import { useMicaShopClient } from '../useMicaShopClient';
import { Realm } from 'mica-proto/src/micashared/common/enums/realm/v1/realm';
import { toast } from 'react-toastify';

/**
 * A hook for getting the username sent to you
 */
export const useForgotUsername = () => {
  const client = useMicaShopClient();

  return useMutation(
    (email: string) =>
      client.forgotUsername({ email, realm: Realm.REALM_USER }),
    {
      onError: () => {
        toast.error(
          'Unable to find matching user, are you sure you registered?'
        );
      },
    }
  );
};
